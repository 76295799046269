import { useEffect, useRef, useState } from "react";
import { ChevronDown, Geo, InfoCircleFill, X } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import { day, place } from "../App";
import {
  CalendarSVG,
  GlobeSVG,
  LocationSVG,
  MapPinSVG,
  TravelSVG,
} from "../svgs";
import styles from "./DropDown.module.scss";

type option = {
  name: string;
  id: string;
  details?: string;
};

interface IDropDown {
  disableExPickup?: boolean;
  options: option[] | day[] | place[];
  type: "location" | "point" | "lang" | "date" | "excursion";
  setCallback: (arg1: string) => void;
  setDaySelected?: (arg0: number) => void;
  errored: boolean;
  initLang?: string;
  initLocation?: string;
  initPoint?: string;
  initExcursion?: string;
  initDate?: Date | null;
  variation?: "top" | "bottom" | "normal";
}

export default function DropDown({
  disableExPickup,
  options,
  type,
  setCallback,
  setDaySelected,
  errored,
  initLang,
  initLocation,
  initPoint,
  initExcursion,
  initDate,
  variation = "normal",
}: IDropDown) {
  const lowerize = (e: string | undefined) => {
    if (e) {
      return e;
    } else return "";
  };

  const value =
    type === "lang"
      ? initLang === ""
        ? window.verbals["wizard.language"] || "Language"
        : lowerize(initLang)
      : type === "location"
      ? initLocation === ""
        ? window.verbals["wizard.pickupLocation"] || "Pick up Location"
        : lowerize(
            (options as option[]).find((loc) => loc.id === initLocation)?.name
          ) || ""
      : type === "excursion"
      ? initExcursion === ""
        ? window.verbals["wizard.pickUpExcursion"] || "Pick up Excursion"
        : lowerize(
            (options as option[]).find((ex) => ex.id === initExcursion)?.name
          ) || ""
      : type === "point"
      ? initPoint === ""
        ? window.verbals["wizard.pickupHotel"] || "Pick up Hotel"
        : lowerize(
            (options as option[]).find((loc) => loc.id === initPoint)?.name
          ) || ""
      : initDate
      ? initDate.toLocaleDateString()
      : window.verbals["wizard.dates"] || "Dates";

  const [isOpen, setIsOpen] = useState(false);

  const myRef = useRef<HTMLDivElement>(null);

  function manageDisabled(date: any) {
    let current = new Date();
    return date.view === "month"
      ? (options as day[]).findIndex(
          (day) =>
            day.date === date.date.getDate() &&
            day.month === date.date.getMonth() + 1
        ) === -1 ||
          (date.date.getDate() < current.getDate() + 2 &&
            date.date.getMonth() === current.getMonth())
      : date.view === "year"
      ? (options as day[]).findIndex(
          (day) => day.month === date.date.getMonth() + 1
        ) === -1
      : false;
  }

  function sort(
    options: option[],
    type: "location" | "point" | "lang" | "date" | "excursion"
  ) {
    if (type !== "excursion") {
      return options;
    } else {
      return options.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  return (
    <div
      className={`${styles.main}  ${
        variation === "top" ? styles.top : ""
      } dropdownHelper`}
      ref={myRef}
    >
      <button
        className={`${styles.selectHeader} ${errored ? styles.errored : ""} ${
          type === "lang" && variation === "top" ? styles.big : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
        disabled={
          options.length <= 0 ||
          (type === "excursion" && disableExPickup === true)
        }
        data-invalid={errored}
      >
        <div className={styles.headerButton}>
          {type === "location" ? (
            <LocationSVG />
          ) : type === "lang" ? (
            <GlobeSVG />
          ) : type === "point" ? (
            <MapPinSVG />
          ) : type === "excursion" ? (
            <TravelSVG width="22" />
          ) : (
            <CalendarSVG />
          )}
          <span className={styles.valueContainer}>{value}</span>
        </div>
        <ChevronDown
          color={variation === "normal" ? "#136FA3" : "363738"}
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>
      {errored && (
        <div className={styles.error}>
          {window.verbals["wizard.fieldRequired"] || "This field is required"}
        </div>
      )}
      {isOpen && (
        <div
          className={`${styles.optionsWrapper} ${isOpen ? styles.open : ""} ${
            type === "date" ? styles.padded : ""
          } ${type === "date" ? styles.dateWrapper : ""} ${
            type === "date" || type === "lang" ? styles.mobileFull : ""
          }`}
        >
          {(type === "date" || type === "lang" || true) && (
            <div className={styles.headerClose}>
              <button onClick={() => setIsOpen(false)}>
                <X size={36} color="#000" className="ctrsSvg" />
              </button>
            </div>
          )}
          {type !== "date" ? (
            sort(options as option[], type).map((option) => (
              <button
                className={styles.option}
                key={(option as option).id}
                onClick={() => {
                  setCallback(
                    type === "lang" ? option.name : (option as option).id
                  );
                  setIsOpen(false);
                }}
              >
                {type !== "lang" && (
                  <span className={styles.optionIcon}>
                    <Geo color="#136FA3" className="ctrsSvg" />
                  </span>
                )}
                {lowerize(option.name)}
                {type === "point" && (
                  <>
                    <span
                      className={styles.infoIcon}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <InfoCircleFill size={20} className="ctrsSvg" />
                      <div className={styles.pickupSummary}>
                        <div>
                          <span>
                            {window.verbals["wizard.price"] || "Price"}:&nbsp;
                          </span>
                          <span>{(option as place).price}€</span>
                        </div>
                        <div>
                          <span>
                            {window.verbals["wizard.pickupTime"] ||
                              "Pickup Time"}
                            :&nbsp;
                          </span>
                          <span>
                            {(option as place).pickupTime.substring(
                              0,
                              (option as place).pickupTime.indexOf(
                                ":",
                                (option as place).pickupTime.indexOf(":") + 1
                              )
                            )}
                          </span>
                        </div>
                        {/* used to show accomodation <div>
                          <span>
                            {window.verbals["wizard.accomodation"] ||
                              "Accomodation"}
                            :&nbsp;
                          </span>
                          <span>
                            {(option as place).availableAccommodations}
                          </span>
                        </div> */}
                      </div>
                    </span>
                  </>
                )}
              </button>
            ))
          ) : (
            <>
              <div className={styles.legend}>
                <div>
                  <span
                    className={`${styles.legendIcon} ${styles.availableIcon}`}
                  ></span>
                  {window.verbals["wizard.tourAvailable"] || "Available Tour"}
                </div>
                <div>
                  <span
                    className={`${styles.legendIcon} ${styles.unavailableIcon}`}
                  ></span>
                  {window.verbals["wizard.tourUnavailable"] ||
                    "Unavailable Tour"}
                </div>
                <div>
                  <span
                    className={`${styles.legendIcon} ${styles.selectedIcon}`}
                  ></span>
                  {window.verbals["wizard.selectedTour"] || "Selected Tour"}
                </div>
              </div>
              <Calendar
                locale="en"
                prev2Label={null}
                next2Label={null}
                value={initDate}
                tileDisabled={(date) => manageDisabled(date)}
                showNeighboringMonth={false}
                minDate={new Date()}
                maxDate={new Date(`${new Date().getFullYear()}-12-31`)}
                onChange={(date) => {
                  if (typeof setDaySelected !== "undefined") {
                    setDaySelected(
                      (options as day[]).findIndex(
                        (day) =>
                          day.date === (date as Date).getDate() &&
                          day.month === (date as Date).getMonth() + 1
                      )
                    );
                    setIsOpen(false);
                  }
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
