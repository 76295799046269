import { useEffect, useRef, useState } from "react";
import { ChevronDown, DashCircle, PlusCircle } from "react-bootstrap-icons";
import { PersonSVG } from "../svgs";
import styles from "./PersonPickerAll.module.scss";

interface IPersonPickerAll {
  title: string;
  adultCost: number;
  childrenCost: number;
  infantsCost: number;
  maleAdultAmount: number;
  femaleAdultAmount: number;
  maleChildrenAmount: number;
  femaleChildrenAmount: number;
  maleInfantsAmount: number;
  femaleInfantsAmount: number;

  updateFunction: (
    maleAdultAmount: number,
    femaleAdultAmount: number,
    maleChildrenAmount: number,
    femaleChildrenAmount: number,
    maleInfantsAmount: number,
    femaleInfantsAmount: number
  ) => void;
}

const svgProps = { size: 24, color: "#136FA3" };

export default function PersonPickerAll({
  title,
  adultCost,
  childrenCost,
  infantsCost,
  maleAdultAmount,
  femaleAdultAmount,
  maleChildrenAmount,
  femaleChildrenAmount,
  maleInfantsAmount,
  femaleInfantsAmount,
  updateFunction,
}: IPersonPickerAll) {
  const myRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [maleAdultState, setMaleAdultState] = useState(maleAdultAmount);
  const [femaleAdultState, setFemaleAdultState] = useState(femaleAdultAmount);

  const [maleChildrenState, setMaleChildrenState] =
    useState(maleChildrenAmount);
  const [femaleChildrenState, setFemaleChildrenState] =
    useState(femaleChildrenAmount);

  const [maleInfantState, setMaleInfantState] = useState(maleInfantsAmount);
  const [femaleInfantState, setFemaleInfantState] =
    useState(femaleInfantsAmount);

  function confirm() {
    updateFunction(
      maleAdultState,
      femaleAdultState,
      maleChildrenState,
      femaleChildrenState,
      maleInfantState,
      femaleInfantState
    );
    setIsOpen(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      setMaleAdultState(maleAdultAmount);
      setFemaleAdultState(femaleAdultAmount);
      setMaleChildrenState(maleChildrenAmount);
      setFemaleChildrenState(femaleChildrenAmount);
      setMaleInfantState(maleInfantsAmount);
      setFemaleInfantState(femaleInfantsAmount);
    }
  }, [
    femaleAdultAmount,
    femaleChildrenAmount,
    femaleInfantsAmount,
    isOpen,
    maleAdultAmount,
    maleChildrenAmount,
    maleInfantsAmount,
  ]);

  return (
    <div className={`${styles.main} dropdownHelper`} ref={myRef}>
      <button
        className={styles.selectHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.headerButton}>
          <PersonSVG width="20" />
          <span>{title}</span>
        </div>
        <ChevronDown
          color="#363738"
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>

      <div className={`${styles.optionsWrapper} ${isOpen ? styles.open : ""} `}>
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div className={styles.personHeader}>
              {window.verbals["wizard.adults"] || "Adults(18+)"}
            </div>
            <div className={styles.personHeaderCost}>
              {window.verbals["wizard.costPerAdult"] || "Cost per Adult"}:&nbsp;
              {adultCost}€
            </div>

            <div className={styles.personSelector}>
              <span>{window.verbals["wizard.male"] || "Male"}</span>
              <div className={styles.amountWrapper}>
                <button
                  onClick={() =>
                    setMaleAdultState(
                      maleAdultState > 1 ? maleAdultState - 1 : 0
                    )
                  }
                >
                  <DashCircle {...svgProps} className="ctrsSvg" />
                </button>
                <span className={styles.amount}>{maleAdultState}</span>
                <button onClick={() => setMaleAdultState(maleAdultState + 1)}>
                  <PlusCircle {...svgProps} className="ctrsSvg" />
                </button>
              </div>
            </div>
            <div className={styles.personSelector}>
              <span>{window.verbals["wizard.female"] || "Female"}</span>
              <div className={styles.amountWrapper}>
                <button
                  onClick={() =>
                    setFemaleAdultState(
                      femaleAdultState > 1 ? femaleAdultState - 1 : 0
                    )
                  }
                >
                  <DashCircle {...svgProps} className="ctrsSvg" />
                </button>
                <span className={styles.amount}>{femaleAdultState}</span>
                <button
                  onClick={() => setFemaleAdultState(femaleAdultState + 1)}
                >
                  <PlusCircle {...svgProps} className="ctrsSvg" />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.personHeader}>
              {window.verbals["wizard.children"] || "Children (2-17)"}
            </div>
            <div className={styles.personHeaderCost}>
              {window.verbals["wizard.costPerChild"] || "Cost per Child:"}&nbsp;
              {childrenCost}€
            </div>

            <div className={styles.personSelector}>
              <span>{window.verbals["wizard.male"] || "Male"}</span>
              <div className={styles.amountWrapper}>
                <button
                  onClick={() =>
                    setMaleChildrenState(
                      maleChildrenState > 1 ? maleChildrenState - 1 : 0
                    )
                  }
                >
                  <DashCircle {...svgProps} className="ctrsSvg" />
                </button>
                <span className={styles.amount}>{maleChildrenState}</span>
                <button
                  onClick={() => setMaleChildrenState(maleChildrenState + 1)}
                >
                  <PlusCircle {...svgProps} className="ctrsSvg" />
                </button>
              </div>
            </div>
            <div className={styles.personSelector}>
              <span>{window.verbals["wizard.female"] || "Female"}</span>
              <div className={styles.amountWrapper}>
                <button
                  onClick={() =>
                    setFemaleChildrenState(
                      femaleChildrenState > 1 ? femaleChildrenState - 1 : 0
                    )
                  }
                >
                  <DashCircle {...svgProps} className="ctrsSvg" />
                </button>
                <span className={styles.amount}>{femaleChildrenState}</span>
                <button
                  onClick={() =>
                    setFemaleChildrenState(femaleChildrenState + 1)
                  }
                >
                  <PlusCircle {...svgProps} className="ctrsSvg" />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.personHeader}>
              {window.verbals["wizard.Infants"] || "Infants (0-1)"}
            </div>
            <div className={styles.personHeaderCost}>
              {window.verbals["wizard.costPerInfant"] || "Cost per Infant"}
              &nbsp;{infantsCost}€
            </div>

            <div className={styles.personSelector}>
              <span> {window.verbals["wizard.male"] || "Male"}</span>
              <div className={styles.amountWrapper}>
                <button
                  onClick={() =>
                    setMaleInfantState(
                      maleInfantState > 1 ? maleInfantState - 1 : 0
                    )
                  }
                >
                  <DashCircle {...svgProps} className="ctrsSvg" />
                </button>
                <span className={styles.amount}>{maleInfantState}</span>
                <button onClick={() => setMaleInfantState(maleInfantState + 1)}>
                  <PlusCircle {...svgProps} className="ctrsSvg" />
                </button>
              </div>
            </div>
            <div className={styles.personSelector}>
              <span> {window.verbals["wizard.female"] || "Female"}</span>
              <div className={styles.amountWrapper}>
                <button
                  onClick={() =>
                    setFemaleInfantState(
                      femaleInfantState > 1 ? femaleInfantState - 1 : 0
                    )
                  }
                >
                  <DashCircle {...svgProps} className="ctrsSvg" />
                </button>
                <span className={styles.amount}>{femaleInfantState}</span>
                <button
                  onClick={() => setFemaleInfantState(femaleInfantState + 1)}
                >
                  <PlusCircle {...svgProps} className="ctrsSvg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <button
            disabled={
              femaleAdultState === femaleAdultAmount &&
              maleAdultAmount === maleAdultState &&
              femaleChildrenState === femaleChildrenAmount &&
              maleChildrenState === maleChildrenAmount &&
              maleInfantState === maleInfantsAmount &&
              femaleInfantState === femaleInfantsAmount
            }
            onClick={() => confirm()}
          >
            {window.verbals["wizard.confirm"] || "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
}
