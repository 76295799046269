export const countries = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];

export const countriesExtended = [
  {
    name: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    numeric: "004",
    subdivisions: [
      { code: "AF-BDS", name: "Badakhshān" },
      { code: "AF-BGL", name: "Baghlān" },
      { code: "AF-BAL", name: "Balkh" },
      { code: "AF-BDG", name: "Bādghīs" },
      { code: "AF-BAM", name: "Bāmyān" },
      { code: "AF-DAY", name: "Dāykundī" },
      { code: "AF-FRA", name: "Farāh" },
      { code: "AF-FYB", name: "Fāryāb" },
      { code: "AF-GHA", name: "Ghaznī" },
      { code: "AF-GHO", name: "Ghōr" },
      { code: "AF-HEL", name: "Helmand" },
      { code: "AF-HER", name: "Herāt" },
      { code: "AF-JOW", name: "Jowzjān" },
      { code: "AF-KAN", name: "Kandahār" },
      { code: "AF-KHO", name: "Khōst" },
      { code: "AF-KNR", name: "Kunaṟ" },
      { code: "AF-KDZ", name: "Kunduz" },
      { code: "AF-KAB", name: "Kābul" },
      { code: "AF-KAP", name: "Kāpīsā" },
      { code: "AF-LAG", name: "Laghmān" },
      { code: "AF-LOG", name: "Lōgar" },
      { code: "AF-NAN", name: "Nangarhār" },
      { code: "AF-NIM", name: "Nīmrōz" },
      { code: "AF-NUR", name: "Nūristān" },
      { code: "AF-PIA", name: "Paktiyā" },
      { code: "AF-PKA", name: "Paktīkā" },
      { code: "AF-PAN", name: "Panjshayr" },
      { code: "AF-PAR", name: "Parwān" },
      { code: "AF-SAM", name: "Samangān" },
      { code: "AF-SAR", name: "Sar-e Pul" },
      { code: "AF-TAK", name: "Takhār" },
      { code: "AF-URU", name: "Uruzgān" },
      { code: "AF-WAR", name: "Wardak" },
      { code: "AF-ZAB", name: "Zābul" },
    ],
  },
  {
    name: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    numeric: "008",
    subdivisions: [
      { code: "AL-01", name: "Berat" },
      { code: "AL-09", name: "Dibër" },
      { code: "AL-02", name: "Durrës" },
      { code: "AL-03", name: "Elbasan" },
      { code: "AL-04", name: "Fier" },
      { code: "AL-05", name: "Gjirokastër" },
      { code: "AL-06", name: "Korçë" },
      { code: "AL-07", name: "Kukës" },
      { code: "AL-08", name: "Lezhë" },
      { code: "AL-10", name: "Shkodër" },
      { code: "AL-11", name: "Tiranë" },
      { code: "AL-12", name: "Vlorë" },
    ],
  },
  {
    name: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    numeric: "012",
    subdivisions: [
      { code: "DZ-01", name: "Adrar" },
      { code: "DZ-16", name: "Alger" },
      { code: "DZ-23", name: "Annaba" },
      { code: "DZ-44", name: "Aïn Defla" },
      { code: "DZ-46", name: "Aïn Témouchent" },
      { code: "DZ-05", name: "Batna" },
      { code: "DZ-07", name: "Biskra" },
      { code: "DZ-09", name: "Blida" },
      { code: "DZ-34", name: "Bordj Bou Arréridj" },
      { code: "DZ-10", name: "Bouira" },
      { code: "DZ-35", name: "Boumerdès" },
      { code: "DZ-08", name: "Béchar" },
      { code: "DZ-06", name: "Béjaïa" },
      { code: "DZ-02", name: "Chlef" },
      { code: "DZ-25", name: "Constantine" },
      { code: "DZ-17", name: "Djelfa" },
      { code: "DZ-32", name: "El Bayadh" },
      { code: "DZ-39", name: "El Oued" },
      { code: "DZ-36", name: "El Tarf" },
      { code: "DZ-47", name: "Ghardaïa" },
      { code: "DZ-24", name: "Guelma" },
      { code: "DZ-33", name: "Illizi" },
      { code: "DZ-18", name: "Jijel" },
      { code: "DZ-40", name: "Khenchela" },
      { code: "DZ-03", name: "Laghouat" },
      { code: "DZ-28", name: "M'sila" },
      { code: "DZ-29", name: "Mascara" },
      { code: "DZ-43", name: "Mila" },
      { code: "DZ-27", name: "Mostaganem" },
      { code: "DZ-26", name: "Médéa" },
      { code: "DZ-45", name: "Naama" },
      { code: "DZ-31", name: "Oran" },
      { code: "DZ-30", name: "Ouargla" },
      { code: "DZ-04", name: "Oum el Bouaghi" },
      { code: "DZ-48", name: "Relizane" },
      { code: "DZ-20", name: "Saïda" },
      { code: "DZ-22", name: "Sidi Bel Abbès" },
      { code: "DZ-21", name: "Skikda" },
      { code: "DZ-41", name: "Souk Ahras" },
      { code: "DZ-19", name: "Sétif" },
      { code: "DZ-11", name: "Tamanrasset" },
      { code: "DZ-14", name: "Tiaret" },
      { code: "DZ-37", name: "Tindouf" },
      { code: "DZ-42", name: "Tipaza" },
      { code: "DZ-38", name: "Tissemsilt" },
      { code: "DZ-15", name: "Tizi Ouzou" },
      { code: "DZ-13", name: "Tlemcen" },
      { code: "DZ-12", name: "Tébessa" },
    ],
  },
  {
    name: "American Samoa",
    alpha2: "AS",
    alpha3: "ASM",
    numeric: "016",
    subdivisions: [],
  },
  {
    name: "Andorra",
    alpha2: "AD",
    alpha3: "AND",
    numeric: "020",
    subdivisions: [
      { code: "AD-07", name: "Andorra la Vella" },
      { code: "AD-02", name: "Canillo" },
      { code: "AD-03", name: "Encamp" },
      { code: "AD-08", name: "Escaldes-Engordany" },
      { code: "AD-04", name: "La Massana" },
      { code: "AD-05", name: "Ordino" },
      { code: "AD-06", name: "Sant Julià de Lòria" },
    ],
  },
  {
    name: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    numeric: "024",
    subdivisions: [
      { code: "AO-BGO", name: "Bengo" },
      { code: "AO-BGU", name: "Benguela" },
      { code: "AO-BIE", name: "Bié" },
      { code: "AO-CAB", name: "Cabinda" },
      { code: "AO-CNN", name: "Cunene" },
      { code: "AO-HUA", name: "Huambo" },
      { code: "AO-HUI", name: "Huíla" },
      { code: "AO-CCU", name: "Kuando Kubango" },
      { code: "AO-CNO", name: "Kwanza Norte" },
      { code: "AO-CUS", name: "Kwanza Sul" },
      { code: "AO-LUA", name: "Luanda" },
      { code: "AO-LNO", name: "Lunda Norte" },
      { code: "AO-LSU", name: "Lunda Sul" },
      { code: "AO-MAL", name: "Malange" },
      { code: "AO-MOX", name: "Moxico" },
      { code: "AO-NAM", name: "Namibe" },
      { code: "AO-UIG", name: "Uíge" },
      { code: "AO-ZAI", name: "Zaire" },
    ],
  },
  {
    name: "Anguilla",
    alpha2: "AI",
    alpha3: "AIA",
    numeric: "660",
    subdivisions: [],
  },
  {
    name: "Antarctica",
    alpha2: "AQ",
    alpha3: "ATA",
    numeric: "010",
    subdivisions: [],
  },
  {
    name: "Antigua and Barbuda",
    alpha2: "AG",
    alpha3: "ATG",
    numeric: "028",
    subdivisions: [
      { code: "AG-10", name: "Barbuda" },
      { code: "AG-11", name: "Redonda" },
      { code: "AG-03", name: "Saint George" },
      { code: "AG-04", name: "Saint John" },
      { code: "AG-05", name: "Saint Mary" },
      { code: "AG-06", name: "Saint Paul" },
      { code: "AG-07", name: "Saint Peter" },
      { code: "AG-08", name: "Saint Philip" },
    ],
  },
  {
    name: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    numeric: "032",
    subdivisions: [
      { code: "AR-B", name: "Buenos Aires" },
      { code: "AR-K", name: "Catamarca" },
      { code: "AR-H", name: "Chaco" },
      { code: "AR-U", name: "Chubut" },
      { code: "AR-C", name: "Ciudad Autónoma de Buenos Aires" },
      { code: "AR-W", name: "Corrientes" },
      { code: "AR-X", name: "Córdoba" },
      { code: "AR-E", name: "Entre Ríos" },
      { code: "AR-P", name: "Formosa" },
      { code: "AR-Y", name: "Jujuy" },
      { code: "AR-L", name: "La Pampa" },
      { code: "AR-F", name: "La Rioja" },
      { code: "AR-M", name: "Mendoza" },
      { code: "AR-N", name: "Misiones" },
      { code: "AR-Q", name: "Neuquén" },
      { code: "AR-R", name: "Río Negro" },
      { code: "AR-A", name: "Salta" },
      { code: "AR-J", name: "San Juan" },
      { code: "AR-D", name: "San Luis" },
      { code: "AR-Z", name: "Santa Cruz" },
      { code: "AR-S", name: "Santa Fe" },
      { code: "AR-G", name: "Santiago del Estero" },
      { code: "AR-V", name: "Tierra del Fuego" },
      { code: "AR-T", name: "Tucumán" },
    ],
  },
  {
    name: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    numeric: "051",
    subdivisions: [
      { code: "AM-AG", name: "Aragac̣otn" },
      { code: "AM-AR", name: "Ararat" },
      { code: "AM-AV", name: "Armavir" },
      { code: "AM-ER", name: "Erevan" },
      { code: "AM-GR", name: "Geġark'unik'" },
      { code: "AM-KT", name: "Kotayk'" },
      { code: "AM-LO", name: "Loṙi" },
      { code: "AM-SU", name: "Syunik'" },
      { code: "AM-TV", name: "Tavuš" },
      { code: "AM-VD", name: "Vayoć Jor" },
      { code: "AM-SH", name: "Širak" },
    ],
  },
  {
    name: "Aruba",
    alpha2: "AW",
    alpha3: "ABW",
    numeric: "533",
    subdivisions: [],
  },
  {
    name: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    numeric: "036",
    subdivisions: [
      { code: "AU-ACT", name: "Australian Capital Territory" },
      { code: "AU-NSW", name: "New South Wales" },
      { code: "AU-NT", name: "Northern Territory" },
      { code: "AU-QLD", name: "Queensland" },
      { code: "AU-SA", name: "South Australia" },
      { code: "AU-TAS", name: "Tasmania" },
      { code: "AU-VIC", name: "Victoria" },
      { code: "AU-WA", name: "Western Australia" },
    ],
  },
  {
    name: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    numeric: "040",
    subdivisions: [
      { code: "AT-1", name: "Burgenland" },
      { code: "AT-2", name: "Kärnten" },
      { code: "AT-3", name: "Niederösterreich" },
      { code: "AT-4", name: "Oberösterreich" },
      { code: "AT-5", name: "Salzburg" },
      { code: "AT-6", name: "Steiermark" },
      { code: "AT-7", name: "Tirol" },
      { code: "AT-8", name: "Vorarlberg" },
      { code: "AT-9", name: "Wien" },
    ],
  },
  {
    name: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    numeric: "031",
    subdivisions: [
      { code: "AZ-ABS", name: "Abşeron" },
      { code: "AZ-AST", name: "Astara" },
      { code: "AZ-AGC", name: "Ağcabədi" },
      { code: "AZ-AGM", name: "Ağdam" },
      { code: "AZ-AGS", name: "Ağdaş" },
      { code: "AZ-AGA", name: "Ağstafa" },
      { code: "AZ-AGU", name: "Ağsu" },
      { code: "AZ-BAB", name: "Babək" },
      { code: "AZ-BA", name: "Bakı" },
      { code: "AZ-BAL", name: "Balakən" },
      { code: "AZ-BEY", name: "Beyləqan" },
      { code: "AZ-BIL", name: "Biləsuvar" },
      { code: "AZ-BAR", name: "Bərdə" },
      { code: "AZ-CUL", name: "Culfa" },
      { code: "AZ-CAB", name: "Cəbrayıl" },
      { code: "AZ-CAL", name: "Cəlilabad" },
      { code: "AZ-DAS", name: "Daşkəsən" },
      { code: "AZ-FUZ", name: "Füzuli" },
      { code: "AZ-GOR", name: "Goranboy" },
      { code: "AZ-GYG", name: "Göygöl" },
      { code: "AZ-GOY", name: "Göyçay" },
      { code: "AZ-GAD", name: "Gədəbəy" },
      { code: "AZ-GA", name: "Gəncə" },
      { code: "AZ-HAC", name: "Hacıqabul" },
      { code: "AZ-KUR", name: "Kürdəmir" },
      { code: "AZ-KAN", name: "Kǝngǝrli" },
      { code: "AZ-KAL", name: "Kəlbəcər" },
      { code: "AZ-LAC", name: "Laçın" },
      { code: "AZ-LER", name: "Lerik" },
      { code: "AZ-LA", name: "Lənkəran" },
      { code: "AZ-LAN", name: "Lənkəran" },
      { code: "AZ-MAS", name: "Masallı" },
      { code: "AZ-MI", name: "Mingəçevir" },
      { code: "AZ-NA", name: "Naftalan" },
      { code: "AZ-NX", name: "Naxçıvan" },
      { code: "AZ-NV", name: "Naxçıvan" },
      { code: "AZ-NEF", name: "Neftçala" },
      { code: "AZ-ORD", name: "Ordubad" },
      { code: "AZ-OGU", name: "Oğuz" },
      { code: "AZ-QAX", name: "Qax" },
      { code: "AZ-QAZ", name: "Qazax" },
      { code: "AZ-QOB", name: "Qobustan" },
      { code: "AZ-QBA", name: "Quba" },
      { code: "AZ-QBI", name: "Qubadlı" },
      { code: "AZ-QUS", name: "Qusar" },
      { code: "AZ-QAB", name: "Qəbələ" },
      { code: "AZ-SAT", name: "Saatlı" },
      { code: "AZ-SAB", name: "Sabirabad" },
      { code: "AZ-SAL", name: "Salyan" },
      { code: "AZ-SMX", name: "Samux" },
      { code: "AZ-SIY", name: "Siyəzən" },
      { code: "AZ-SM", name: "Sumqayıt" },
      { code: "AZ-SAD", name: "Sədərək" },
      { code: "AZ-TOV", name: "Tovuz" },
      { code: "AZ-TAR", name: "Tərtər" },
      { code: "AZ-UCA", name: "Ucar" },
      { code: "AZ-XA", name: "Xankəndi" },
      { code: "AZ-XAC", name: "Xaçmaz" },
      { code: "AZ-XCI", name: "Xocalı" },
      { code: "AZ-XVD", name: "Xocavənd" },
      { code: "AZ-XIZ", name: "Xızı" },
      { code: "AZ-YAR", name: "Yardımlı" },
      { code: "AZ-YE", name: "Yevlax" },
      { code: "AZ-YEV", name: "Yevlax" },
      { code: "AZ-ZAQ", name: "Zaqatala" },
      { code: "AZ-ZAN", name: "Zəngilan" },
      { code: "AZ-ZAR", name: "Zərdab" },
      { code: "AZ-IMI", name: "İmişli" },
      { code: "AZ-ISM", name: "İsmayıllı" },
      { code: "AZ-SBN", name: "Şabran" },
      { code: "AZ-SAH", name: "Şahbuz" },
      { code: "AZ-SMI", name: "Şamaxı" },
      { code: "AZ-SR", name: "Şirvan" },
      { code: "AZ-SUS", name: "Şuşa" },
      { code: "AZ-SA", name: "Şəki" },
      { code: "AZ-SAK", name: "Şəki" },
      { code: "AZ-SKR", name: "Şəmkir" },
      { code: "AZ-SAR", name: "Şərur" },
    ],
  },
  {
    name: "Bahamas (the)",
    alpha2: "BS",
    alpha3: "BHS",
    numeric: "044",
    subdivisions: [
      { code: "BS-AK", name: "Acklins" },
      { code: "BS-BY", name: "Berry Islands" },
      { code: "BS-BI", name: "Bimini" },
      { code: "BS-BP", name: "Black Point" },
      { code: "BS-CI", name: "Cat Island" },
      { code: "BS-CO", name: "Central Abaco" },
      { code: "BS-CS", name: "Central Andros" },
      { code: "BS-CE", name: "Central Eleuthera" },
      { code: "BS-FP", name: "City of Freeport" },
      { code: "BS-CK", name: "Crooked Island and Long Cay" },
      { code: "BS-EG", name: "East Grand Bahama" },
      { code: "BS-EX", name: "Exuma" },
      { code: "BS-GC", name: "Grand Cay" },
      { code: "BS-HI", name: "Harbour Island" },
      { code: "BS-HT", name: "Hope Town" },
      { code: "BS-IN", name: "Inagua" },
      { code: "BS-LI", name: "Long Island" },
      { code: "BS-MC", name: "Mangrove Cay" },
      { code: "BS-MG", name: "Mayaguana" },
      { code: "BS-MI", name: "Moore's Island" },
      { code: "BS-NP", name: "New Providence" },
      { code: "BS-NO", name: "North Abaco" },
      { code: "BS-NS", name: "North Andros" },
      { code: "BS-NE", name: "North Eleuthera" },
      { code: "BS-RI", name: "Ragged Island" },
      { code: "BS-RC", name: "Rum Cay" },
      { code: "BS-SS", name: "San Salvador" },
      { code: "BS-SO", name: "South Abaco" },
      { code: "BS-SA", name: "South Andros" },
      { code: "BS-SE", name: "South Eleuthera" },
      { code: "BS-SW", name: "Spanish Wells" },
      { code: "BS-WG", name: "West Grand Bahama" },
    ],
  },
  {
    name: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    numeric: "048",
    subdivisions: [
      { code: "BH-14", name: "Al Janūbīyah" },
      { code: "BH-15", name: "Al Muḩarraq" },
      { code: "BH-13", name: "Al ‘Āşimah" },
      { code: "BH-17", name: "Ash Shamālīyah" },
    ],
  },
  {
    name: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    numeric: "050",
    subdivisions: [
      { code: "BD-05", name: "Bagerhat" },
      { code: "BD-01", name: "Bandarban" },
      { code: "BD-02", name: "Barguna" },
      { code: "BD-A", name: "Barisal" },
      { code: "BD-06", name: "Barisal" },
      { code: "BD-07", name: "Bhola" },
      { code: "BD-03", name: "Bogra" },
      { code: "BD-04", name: "Brahmanbaria" },
      { code: "BD-09", name: "Chandpur" },
      { code: "BD-45", name: "Chapai Nawabganj" },
      { code: "BD-B", name: "Chittagong" },
      { code: "BD-10", name: "Chittagong" },
      { code: "BD-12", name: "Chuadanga" },
      { code: "BD-08", name: "Comilla" },
      { code: "BD-11", name: "Cox's Bazar" },
      { code: "BD-C", name: "Dhaka" },
      { code: "BD-13", name: "Dhaka" },
      { code: "BD-14", name: "Dinajpur" },
      { code: "BD-15", name: "Faridpur" },
      { code: "BD-16", name: "Feni" },
      { code: "BD-19", name: "Gaibandha" },
      { code: "BD-18", name: "Gazipur" },
      { code: "BD-17", name: "Gopalganj" },
      { code: "BD-20", name: "Habiganj" },
      { code: "BD-21", name: "Jamalpur" },
      { code: "BD-22", name: "Jessore" },
      { code: "BD-25", name: "Jhalakathi" },
      { code: "BD-23", name: "Jhenaidah" },
      { code: "BD-24", name: "Joypurhat" },
      { code: "BD-29", name: "Khagrachhari" },
      { code: "BD-D", name: "Khulna" },
      { code: "BD-27", name: "Khulna" },
      { code: "BD-26", name: "Kishoreganj" },
      { code: "BD-28", name: "Kurigram" },
      { code: "BD-30", name: "Kushtia" },
      { code: "BD-31", name: "Lakshmipur" },
      { code: "BD-32", name: "Lalmonirhat" },
      { code: "BD-36", name: "Madaripur" },
      { code: "BD-37", name: "Magura" },
      { code: "BD-33", name: "Manikganj" },
      { code: "BD-39", name: "Meherpur" },
      { code: "BD-38", name: "Moulvibazar" },
      { code: "BD-35", name: "Munshiganj" },
      { code: "BD-H", name: "Mymensingh" },
      { code: "BD-34", name: "Mymensingh" },
      { code: "BD-48", name: "Naogaon" },
      { code: "BD-43", name: "Narail" },
      { code: "BD-40", name: "Narayanganj" },
      { code: "BD-42", name: "Narsingdi" },
      { code: "BD-44", name: "Natore" },
      { code: "BD-41", name: "Netrakona" },
      { code: "BD-46", name: "Nilphamari" },
      { code: "BD-47", name: "Noakhali" },
      { code: "BD-49", name: "Pabna" },
      { code: "BD-52", name: "Panchagarh" },
      { code: "BD-51", name: "Patuakhali" },
      { code: "BD-50", name: "Pirojpur" },
      { code: "BD-53", name: "Rajbari" },
      { code: "BD-E", name: "Rajshahi" },
      { code: "BD-54", name: "Rajshahi" },
      { code: "BD-56", name: "Rangamati" },
      { code: "BD-F", name: "Rangpur" },
      { code: "BD-55", name: "Rangpur" },
      { code: "BD-58", name: "Satkhira" },
      { code: "BD-62", name: "Shariatpur" },
      { code: "BD-57", name: "Sherpur" },
      { code: "BD-59", name: "Sirajganj" },
      { code: "BD-61", name: "Sunamganj" },
      { code: "BD-G", name: "Sylhet" },
      { code: "BD-60", name: "Sylhet" },
      { code: "BD-63", name: "Tangail" },
      { code: "BD-64", name: "Thakurgaon" },
    ],
  },
  {
    name: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    numeric: "052",
    subdivisions: [
      { code: "BB-01", name: "Christ Church" },
      { code: "BB-02", name: "Saint Andrew" },
      { code: "BB-03", name: "Saint George" },
      { code: "BB-04", name: "Saint James" },
      { code: "BB-05", name: "Saint John" },
      { code: "BB-06", name: "Saint Joseph" },
      { code: "BB-07", name: "Saint Lucy" },
      { code: "BB-08", name: "Saint Michael" },
      { code: "BB-09", name: "Saint Peter" },
      { code: "BB-10", name: "Saint Philip" },
      { code: "BB-11", name: "Saint Thomas" },
    ],
  },
  {
    name: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    numeric: "112",
    subdivisions: [
      { code: "BY-BR", name: "Bresckaja voblasć" },
      { code: "BY-HO", name: "Gomel'skaja oblast'" },
      { code: "BY-HM", name: "Gorod Minsk" },
      { code: "BY-HR", name: "Grodnenskaja oblast'" },
      { code: "BY-MA", name: "Mahilioŭskaja voblasć" },
      { code: "BY-MI", name: "Minskaja oblast'" },
      { code: "BY-VI", name: "Viciebskaja voblasć" },
    ],
  },
  {
    name: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    numeric: "056",
    subdivisions: [
      { code: "BE-VAN", name: "Antwerpen" },
      { code: "BE-WBR", name: "Brabant wallon" },
      { code: "BE-BRU", name: "Brussels Hoofdstedelijk Gewest" },
      { code: "BE-WHT", name: "Hainaut" },
      { code: "BE-VLI", name: "Limburg" },
      { code: "BE-WLG", name: "Liège" },
      { code: "BE-WLX", name: "Luxembourg" },
      { code: "BE-WNA", name: "Namur" },
      { code: "BE-VOV", name: "Oost-Vlaanderen" },
      { code: "BE-VLG", name: "Vlaams Gewest" },
      { code: "BE-VBR", name: "Vlaams-Brabant" },
      { code: "BE-VWV", name: "West-Vlaanderen" },
      { code: "BE-WAL", name: "wallonne, Région" },
    ],
  },
  {
    name: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    numeric: "084",
    subdivisions: [
      { code: "BZ-BZ", name: "Belize" },
      { code: "BZ-CY", name: "Cayo" },
      { code: "BZ-CZL", name: "Corozal" },
      { code: "BZ-OW", name: "Orange Walk" },
      { code: "BZ-SC", name: "Stann Creek" },
      { code: "BZ-TOL", name: "Toledo" },
    ],
  },
  {
    name: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    numeric: "204",
    subdivisions: [
      { code: "BJ-AL", name: "Alibori" },
      { code: "BJ-AK", name: "Atacora" },
      { code: "BJ-AQ", name: "Atlantique" },
      { code: "BJ-BO", name: "Borgou" },
      { code: "BJ-CO", name: "Collines" },
      { code: "BJ-KO", name: "Couffo" },
      { code: "BJ-DO", name: "Donga" },
      { code: "BJ-LI", name: "Littoral" },
      { code: "BJ-MO", name: "Mono" },
      { code: "BJ-OU", name: "Ouémé" },
      { code: "BJ-PL", name: "Plateau" },
      { code: "BJ-ZO", name: "Zou" },
    ],
  },
  {
    name: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    numeric: "060",
    subdivisions: [],
  },
  {
    name: "Åland Islands",
    alpha2: "AX",
    alpha3: "ALA",
    numeric: "248",
    subdivisions: [],
  },
  {
    name: "Bhutan",
    alpha2: "BT",
    alpha3: "BTN",
    numeric: "064",
    subdivisions: [
      { code: "BT-33", name: "Bumthang" },
      { code: "BT-12", name: "Chhukha" },
      { code: "BT-22", name: "Dagana" },
      { code: "BT-GA", name: "Gasa" },
      { code: "BT-13", name: "Haa" },
      { code: "BT-44", name: "Lhuentse" },
      { code: "BT-42", name: "Monggar" },
      { code: "BT-11", name: "Paro" },
      { code: "BT-43", name: "Pemagatshel" },
      { code: "BT-23", name: "Punakha" },
      { code: "BT-45", name: "Samdrup Jongkhar" },
      { code: "BT-14", name: "Samtse" },
      { code: "BT-31", name: "Sarpang" },
      { code: "BT-15", name: "Thimphu" },
      { code: "BT-TY", name: "Trashi Yangtse" },
      { code: "BT-41", name: "Trashigang" },
      { code: "BT-32", name: "Trongsa" },
      { code: "BT-21", name: "Tsirang" },
      { code: "BT-24", name: "Wangdue Phodrang" },
      { code: "BT-34", name: "Zhemgang" },
    ],
  },
  {
    name: "Bolivia (Plurinational State of)",
    alpha2: "BO",
    alpha3: "BOL",
    numeric: "068",
    subdivisions: [
      { code: "BO-H", name: "Chuquisaca" },
      { code: "BO-C", name: "Cochabamba" },
      { code: "BO-B", name: "El Beni" },
      { code: "BO-L", name: "La Paz" },
      { code: "BO-O", name: "Oruro" },
      { code: "BO-N", name: "Pando" },
      { code: "BO-P", name: "Potosí" },
      { code: "BO-S", name: "Santa Cruz" },
      { code: "BO-T", name: "Tarija" },
    ],
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2: "BQ",
    alpha3: "BES",
    numeric: "535",
    subdivisions: [
      { code: "BQ-BO", name: "Bonaire" },
      { code: "BQ-SA", name: "Saba" },
      { code: "BQ-SE", name: "Sint Eustatius" },
    ],
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    numeric: "070",
    subdivisions: [
      { code: "BA-BRC", name: "Brčko distrikt" },
      { code: "BA-BIH", name: "Federacija Bosne i Hercegovine" },
      { code: "BA-SRP", name: "Republika Srpska" },
    ],
  },
  {
    name: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    numeric: "072",
    subdivisions: [
      { code: "BW-CE", name: "Central" },
      { code: "BW-CH", name: "Chobe" },
      { code: "BW-FR", name: "Francistown" },
      { code: "BW-GA", name: "Gaborone" },
      { code: "BW-GH", name: "Ghanzi" },
      { code: "BW-JW", name: "Jwaneng" },
      { code: "BW-KG", name: "Kgalagadi" },
      { code: "BW-KL", name: "Kgatleng" },
      { code: "BW-KW", name: "Kweneng" },
      { code: "BW-LO", name: "Lobatse" },
      { code: "BW-NE", name: "North East" },
      { code: "BW-NW", name: "North West" },
      { code: "BW-SP", name: "Selibe Phikwe" },
      { code: "BW-SE", name: "South East" },
      { code: "BW-SO", name: "Southern" },
      { code: "BW-ST", name: "Sowa Town" },
    ],
  },
  {
    name: "Bouvet Island",
    alpha2: "BV",
    alpha3: "BVT",
    numeric: "074",
    subdivisions: [],
  },
  {
    name: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    numeric: "076",
    subdivisions: [
      { code: "BR-AC", name: "Acre" },
      { code: "BR-AL", name: "Alagoas" },
      { code: "BR-AP", name: "Amapá" },
      { code: "BR-AM", name: "Amazonas" },
      { code: "BR-BA", name: "Bahia" },
      { code: "BR-CE", name: "Ceará" },
      { code: "BR-DF", name: "Distrito Federal" },
      { code: "BR-ES", name: "Espírito Santo" },
      { code: "BR-GO", name: "Goiás" },
      { code: "BR-MA", name: "Maranhão" },
      { code: "BR-MT", name: "Mato Grosso" },
      { code: "BR-MS", name: "Mato Grosso do Sul" },
      { code: "BR-MG", name: "Minas Gerais" },
      { code: "BR-PR", name: "Paraná" },
      { code: "BR-PB", name: "Paraíba" },
      { code: "BR-PA", name: "Pará" },
      { code: "BR-PE", name: "Pernambuco" },
      { code: "BR-PI", name: "Piauí" },
      { code: "BR-RN", name: "Rio Grande do Norte" },
      { code: "BR-RS", name: "Rio Grande do Sul" },
      { code: "BR-RJ", name: "Rio de Janeiro" },
      { code: "BR-RO", name: "Rondônia" },
      { code: "BR-RR", name: "Roraima" },
      { code: "BR-SC", name: "Santa Catarina" },
      { code: "BR-SE", name: "Sergipe" },
      { code: "BR-SP", name: "São Paulo" },
      { code: "BR-TO", name: "Tocantins" },
    ],
  },
  {
    name: "British Indian Ocean Territory (the)",
    alpha2: "IO",
    alpha3: "IOT",
    numeric: "086",
    subdivisions: [],
  },
  {
    name: "Brunei Darussalam",
    alpha2: "BN",
    alpha3: "BRN",
    numeric: "096",
    subdivisions: [
      { code: "BN-BE", name: "Belait" },
      { code: "BN-BM", name: "Brunei dan Muara" },
      { code: "BN-TE", name: "Temburong" },
      { code: "BN-TU", name: "Tutong" },
    ],
  },
  {
    name: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    numeric: "100",
    subdivisions: [
      { code: "BG-01", name: "Blagoevgrad" },
      { code: "BG-02", name: "Burgas" },
      { code: "BG-08", name: "Dobrich" },
      { code: "BG-07", name: "Gabrovo" },
      { code: "BG-26", name: "Haskovo" },
      { code: "BG-09", name: "Kardzhali" },
      { code: "BG-10", name: "Kyustendil" },
      { code: "BG-11", name: "Lovech" },
      { code: "BG-12", name: "Montana" },
      { code: "BG-13", name: "Pazardzhik" },
      { code: "BG-14", name: "Pernik" },
      { code: "BG-15", name: "Pleven" },
      { code: "BG-16", name: "Plovdiv" },
      { code: "BG-17", name: "Razgrad" },
      { code: "BG-18", name: "Ruse" },
      { code: "BG-27", name: "Shumen" },
      { code: "BG-19", name: "Silistra" },
      { code: "BG-20", name: "Sliven" },
      { code: "BG-21", name: "Smolyan" },
      { code: "BG-23", name: "Sofia" },
      { code: "BG-22", name: "Sofia (stolitsa)" },
      { code: "BG-24", name: "Stara Zagora" },
      { code: "BG-25", name: "Targovishte" },
      { code: "BG-03", name: "Varna" },
      { code: "BG-04", name: "Veliko Tarnovo" },
      { code: "BG-05", name: "Vidin" },
      { code: "BG-06", name: "Vratsa" },
      { code: "BG-28", name: "Yambol" },
    ],
  },
  {
    name: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    numeric: "854",
    subdivisions: [
      { code: "BF-BAL", name: "Balé" },
      { code: "BF-BAM", name: "Bam" },
      { code: "BF-BAN", name: "Banwa" },
      { code: "BF-BAZ", name: "Bazèga" },
      { code: "BF-01", name: "Boucle du Mouhoun" },
      { code: "BF-BGR", name: "Bougouriba" },
      { code: "BF-BLG", name: "Boulgou" },
      { code: "BF-BLK", name: "Boulkiemdé" },
      { code: "BF-02", name: "Cascades" },
      { code: "BF-03", name: "Centre" },
      { code: "BF-04", name: "Centre-Est" },
      { code: "BF-05", name: "Centre-Nord" },
      { code: "BF-06", name: "Centre-Ouest" },
      { code: "BF-07", name: "Centre-Sud" },
      { code: "BF-COM", name: "Comoé" },
      { code: "BF-08", name: "Est" },
      { code: "BF-GAN", name: "Ganzourgou" },
      { code: "BF-GNA", name: "Gnagna" },
      { code: "BF-GOU", name: "Gourma" },
      { code: "BF-09", name: "Hauts-Bassins" },
      { code: "BF-HOU", name: "Houet" },
      { code: "BF-IOB", name: "Ioba" },
      { code: "BF-KAD", name: "Kadiogo" },
      { code: "BF-KMD", name: "Komondjari" },
      { code: "BF-KMP", name: "Kompienga" },
      { code: "BF-KOS", name: "Kossi" },
      { code: "BF-KOP", name: "Koulpélogo" },
      { code: "BF-KOT", name: "Kouritenga" },
      { code: "BF-KOW", name: "Kourwéogo" },
      { code: "BF-KEN", name: "Kénédougou" },
      { code: "BF-LOR", name: "Loroum" },
      { code: "BF-LER", name: "Léraba" },
      { code: "BF-MOU", name: "Mouhoun" },
      { code: "BF-NAO", name: "Nahouri" },
      { code: "BF-NAM", name: "Namentenga" },
      { code: "BF-NAY", name: "Nayala" },
      { code: "BF-10", name: "Nord" },
      { code: "BF-NOU", name: "Noumbiel" },
      { code: "BF-OUB", name: "Oubritenga" },
      { code: "BF-OUD", name: "Oudalan" },
      { code: "BF-PAS", name: "Passoré" },
      { code: "BF-11", name: "Plateau-Central" },
      { code: "BF-PON", name: "Poni" },
      { code: "BF-12", name: "Sahel" },
      { code: "BF-SNG", name: "Sanguié" },
      { code: "BF-SMT", name: "Sanmatenga" },
      { code: "BF-SIS", name: "Sissili" },
      { code: "BF-SOM", name: "Soum" },
      { code: "BF-SOR", name: "Sourou" },
      { code: "BF-13", name: "Sud-Ouest" },
      { code: "BF-SEN", name: "Séno" },
      { code: "BF-TAP", name: "Tapoa" },
      { code: "BF-TUI", name: "Tuy" },
      { code: "BF-YAG", name: "Yagha" },
      { code: "BF-YAT", name: "Yatenga" },
      { code: "BF-ZIR", name: "Ziro" },
      { code: "BF-ZON", name: "Zondoma" },
      { code: "BF-ZOU", name: "Zoundwéogo" },
    ],
  },
  {
    name: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    numeric: "108",
    subdivisions: [
      { code: "BI-BB", name: "Bubanza" },
      { code: "BI-BM", name: "Bujumbura Mairie" },
      { code: "BI-BL", name: "Bujumbura Rural" },
      { code: "BI-BR", name: "Bururi" },
      { code: "BI-CA", name: "Cankuzo" },
      { code: "BI-CI", name: "Cibitoke" },
      { code: "BI-GI", name: "Gitega" },
      { code: "BI-KR", name: "Karuzi" },
      { code: "BI-KY", name: "Kayanza" },
      { code: "BI-KI", name: "Kirundo" },
      { code: "BI-MA", name: "Makamba" },
      { code: "BI-MU", name: "Muramvya" },
      { code: "BI-MY", name: "Muyinga" },
      { code: "BI-MW", name: "Mwaro" },
      { code: "BI-NG", name: "Ngozi" },
      { code: "BI-RM", name: "Rumonge" },
      { code: "BI-RT", name: "Rutana" },
      { code: "BI-RY", name: "Ruyigi" },
    ],
  },
  {
    name: "Cabo Verde",
    alpha2: "CV",
    alpha3: "CPV",
    numeric: "132",
    subdivisions: [
      { code: "CV-BV", name: "Boa Vista" },
      { code: "CV-BR", name: "Brava" },
      { code: "CV-B", name: "Ilhas de Barlavento" },
      { code: "CV-S", name: "Ilhas de Sotavento" },
      { code: "CV-MA", name: "Maio" },
      { code: "CV-MO", name: "Mosteiros" },
      { code: "CV-PA", name: "Paul" },
      { code: "CV-PN", name: "Porto Novo" },
      { code: "CV-PR", name: "Praia" },
      { code: "CV-RB", name: "Ribeira Brava" },
      { code: "CV-RG", name: "Ribeira Grande" },
      { code: "CV-RS", name: "Ribeira Grande de Santiago" },
      { code: "CV-SL", name: "Sal" },
      { code: "CV-CA", name: "Santa Catarina" },
      { code: "CV-CF", name: "Santa Catarina do Fogo" },
      { code: "CV-CR", name: "Santa Cruz" },
      { code: "CV-SD", name: "São Domingos" },
      { code: "CV-SF", name: "São Filipe" },
      { code: "CV-SO", name: "São Lourenço dos Órgãos" },
      { code: "CV-SM", name: "São Miguel" },
      { code: "CV-SS", name: "São Salvador do Mundo" },
      { code: "CV-SV", name: "São Vicente" },
      { code: "CV-TA", name: "Tarrafal" },
      { code: "CV-TS", name: "Tarrafal de São Nicolau" },
    ],
  },
  {
    name: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    numeric: "116",
    subdivisions: [
      { code: "KH-2", name: "Baat Dambang" },
      { code: "KH-1", name: "Banteay Mean Chey" },
      { code: "KH-23", name: "Kaeb" },
      { code: "KH-3", name: "Kampong Chaam" },
      { code: "KH-4", name: "Kampong Chhnang" },
      { code: "KH-5", name: "Kampong Spueu" },
      { code: "KH-6", name: "Kampong Thum" },
      { code: "KH-7", name: "Kampot" },
      { code: "KH-8", name: "Kandaal" },
      { code: "KH-9", name: "Kaoh Kong" },
      { code: "KH-10", name: "Kracheh" },
      { code: "KH-11", name: "Mondol Kiri" },
      { code: "KH-22", name: "Otdar Mean Chey" },
      { code: "KH-24", name: "Pailin" },
      { code: "KH-12", name: "Phnom Penh" },
      { code: "KH-15", name: "Pousaat" },
      { code: "KH-18", name: "Preah Sihanouk" },
      { code: "KH-13", name: "Preah Vihear" },
      { code: "KH-14", name: "Prey Veaeng" },
      { code: "KH-16", name: "Rotanak Kiri" },
      { code: "KH-17", name: "Siem Reab" },
      { code: "KH-19", name: "Stoĕng Trêng" },
      { code: "KH-20", name: "Svaay Rieng" },
      { code: "KH-21", name: "Taakaev" },
      { code: "KH-25", name: "Tbong Khmum" },
    ],
  },
  {
    name: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    numeric: "120",
    subdivisions: [
      { code: "CM-AD", name: "Adamaoua" },
      { code: "CM-CE", name: "Centre" },
      { code: "CM-ES", name: "East" },
      { code: "CM-EN", name: "Extrême-Nord" },
      { code: "CM-LT", name: "Littoral" },
      { code: "CM-NO", name: "Nord" },
      { code: "CM-NW", name: "Nord-Ouest" },
      { code: "CM-OU", name: "Ouest" },
      { code: "CM-SU", name: "South" },
      { code: "CM-SW", name: "South-West" },
    ],
  },
  {
    name: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    numeric: "124",
    subdivisions: [
      { code: "CA-AB", name: "Alberta" },
      { code: "CA-BC", name: "British Columbia" },
      { code: "CA-MB", name: "Manitoba" },
      { code: "CA-NB", name: "New Brunswick" },
      { code: "CA-NL", name: "Newfoundland and Labrador" },
      { code: "CA-NT", name: "Northwest Territories" },
      { code: "CA-NS", name: "Nouvelle-Écosse" },
      { code: "CA-NU", name: "Nunavut" },
      { code: "CA-ON", name: "Ontario" },
      { code: "CA-PE", name: "Prince Edward Island" },
      { code: "CA-QC", name: "Quebec" },
      { code: "CA-SK", name: "Saskatchewan" },
      { code: "CA-YT", name: "Yukon" },
    ],
  },
  {
    name: "Cayman Islands (the)",
    alpha2: "KY",
    alpha3: "CYM",
    numeric: "136",
    subdivisions: [],
  },
  {
    name: "Central African Republic (the)",
    alpha2: "CF",
    alpha3: "CAF",
    numeric: "140",
    subdivisions: [
      { code: "CF-BB", name: "Bamingui-Bangoran" },
      { code: "CF-BGF", name: "Bangui" },
      { code: "CF-BK", name: "Basse-Kotto" },
      { code: "CF-KB", name: "Gribingui" },
      { code: "CF-HM", name: "Haut-Mbomou" },
      { code: "CF-HK", name: "Haute-Kotto" },
      { code: "CF-HS", name: "Haute-Sangha / Mambéré-Kadéï" },
      { code: "CF-KG", name: "Kemö-Gïrïbïngï" },
      { code: "CF-LB", name: "Lobaye" },
      { code: "CF-MB", name: "Mbomou" },
      { code: "CF-NM", name: "Nana-Mambéré" },
      { code: "CF-MP", name: "Ombella-Mpoko" },
      { code: "CF-UK", name: "Ouaka" },
      { code: "CF-AC", name: "Ouham" },
      { code: "CF-OP", name: "Ouham-Pendé" },
      { code: "CF-SE", name: "Sangha" },
      { code: "CF-VK", name: "Vakaga" },
    ],
  },
  {
    name: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    numeric: "148",
    subdivisions: [
      { code: "TD-BA", name: "Al Baţḩah" },
      { code: "TD-LC", name: "Al Buḩayrah" },
      { code: "TD-BG", name: "Bahr el Ghazal" },
      { code: "TD-BO", name: "Borkou" },
      { code: "TD-CB", name: "Chari-Baguirmi" },
      { code: "TD-EE", name: "Ennedi-Est" },
      { code: "TD-EO", name: "Ennedi-Ouest" },
      { code: "TD-GR", name: "Guéra" },
      { code: "TD-HL", name: "Hadjer Lamis" },
      { code: "TD-KA", name: "Kanem" },
      { code: "TD-LO", name: "Logone-Occidental" },
      { code: "TD-LR", name: "Logone-Oriental" },
      { code: "TD-ND", name: "Madīnat Injamīnā" },
      { code: "TD-MA", name: "Mandoul" },
      { code: "TD-ME", name: "Mayo-Kebbi-Est" },
      { code: "TD-MO", name: "Mayo-Kebbi-Ouest" },
      { code: "TD-MC", name: "Moyen-Chari" },
      { code: "TD-OD", name: "Ouaddaï" },
      { code: "TD-SA", name: "Salamat" },
      { code: "TD-SI", name: "Sila" },
      { code: "TD-TA", name: "Tandjilé" },
      { code: "TD-TI", name: "Tibastī" },
      { code: "TD-WF", name: "Wadi Fira" },
    ],
  },
  {
    name: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    numeric: "152",
    subdivisions: [
      { code: "CL-AI", name: "Aisén del General Carlos Ibañez del Campo" },
      { code: "CL-AN", name: "Antofagasta" },
      { code: "CL-AP", name: "Arica y Parinacota" },
      { code: "CL-AT", name: "Atacama" },
      { code: "CL-BI", name: "Biobío" },
      { code: "CL-CO", name: "Coquimbo" },
      { code: "CL-AR", name: "La Araucanía" },
      { code: "CL-LI", name: "Libertador General Bernardo O'Higgins" },
      { code: "CL-LL", name: "Los Lagos" },
      { code: "CL-LR", name: "Los Ríos" },
      { code: "CL-MA", name: "Magallanes" },
      { code: "CL-ML", name: "Maule" },
      { code: "CL-RM", name: "Región Metropolitana de Santiago" },
      { code: "CL-TA", name: "Tarapacá" },
      { code: "CL-VS", name: "Valparaíso" },
      { code: "CL-NB", name: "Ñuble" },
    ],
  },
  {
    name: "China",
    alpha2: "CN",
    alpha3: "CHN",
    numeric: "156",
    subdivisions: [
      { code: "CN-AH", name: "Anhui Sheng" },
      {
        code: "CN-MO",
        name: "Aomen Tebiexingzhengqu (see also separate country code entry under MO)",
      },
      { code: "CN-BJ", name: "Beijing Shi" },
      { code: "CN-CQ", name: "Chongqing Shi" },
      { code: "CN-FJ", name: "Fujian Sheng" },
      { code: "CN-GS", name: "Gansu Sheng" },
      { code: "CN-GD", name: "Guangdong Sheng" },
      { code: "CN-GX", name: "Guangxi Zhuangzu Zizhiqu" },
      { code: "CN-GZ", name: "Guizhou Sheng" },
      { code: "CN-HI", name: "Hainan Sheng" },
      { code: "CN-HE", name: "Hebei Sheng" },
      { code: "CN-HL", name: "Heilongjiang Sheng" },
      { code: "CN-HA", name: "Henan Sheng" },
      {
        code: "CN-HK",
        name: "Hong Kong SAR (see also separate country code entry under HK)",
      },
      { code: "CN-HB", name: "Hubei Sheng" },
      { code: "CN-HN", name: "Hunan Sheng" },
      { code: "CN-JS", name: "Jiangsu Sheng" },
      { code: "CN-JX", name: "Jiangxi Sheng" },
      { code: "CN-JL", name: "Jilin Sheng" },
      { code: "CN-LN", name: "Liaoning Sheng" },
      { code: "CN-NM", name: "Nei Mongol Zizhiqu" },
      { code: "CN-NX", name: "Ningxia Huizi Zizhiqu" },
      { code: "CN-QH", name: "Qinghai Sheng" },
      { code: "CN-SN", name: "Shaanxi Sheng" },
      { code: "CN-SD", name: "Shandong Sheng" },
      { code: "CN-SH", name: "Shanghai Shi" },
      { code: "CN-SX", name: "Shanxi Sheng" },
      { code: "CN-SC", name: "Sichuan Sheng" },
      {
        code: "CN-TW",
        name: "Taiwan Sheng (see also separate country code entry under TW)",
      },
      { code: "CN-TJ", name: "Tianjin Shi" },
      { code: "CN-XJ", name: "Xinjiang Uygur Zizhiqu" },
      { code: "CN-XZ", name: "Xizang Zizhiqu" },
      { code: "CN-YN", name: "Yunnan Sheng" },
      { code: "CN-ZJ", name: "Zhejiang Sheng" },
    ],
  },
  {
    name: "Christmas Island",
    alpha2: "CX",
    alpha3: "CXR",
    numeric: "162",
    subdivisions: [],
  },
  {
    name: "Cocos (Keeling) Islands (the)",
    alpha2: "CC",
    alpha3: "CCK",
    numeric: "166",
    subdivisions: [],
  },
  {
    name: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    numeric: "170",
    subdivisions: [
      { code: "CO-AMA", name: "Amazonas" },
      { code: "CO-ANT", name: "Antioquia" },
      { code: "CO-ARA", name: "Arauca" },
      { code: "CO-ATL", name: "Atlántico" },
      { code: "CO-BOL", name: "Bolívar" },
      { code: "CO-BOY", name: "Boyacá" },
      { code: "CO-CAL", name: "Caldas" },
      { code: "CO-CAQ", name: "Caquetá" },
      { code: "CO-CAS", name: "Casanare" },
      { code: "CO-CAU", name: "Cauca" },
      { code: "CO-CES", name: "Cesar" },
      { code: "CO-CHO", name: "Chocó" },
      { code: "CO-CUN", name: "Cundinamarca" },
      { code: "CO-COR", name: "Córdoba" },
      { code: "CO-DC", name: "Distrito Capital de Bogotá" },
      { code: "CO-GUA", name: "Guainía" },
      { code: "CO-GUV", name: "Guaviare" },
      { code: "CO-HUI", name: "Huila" },
      { code: "CO-LAG", name: "La Guajira" },
      { code: "CO-MAG", name: "Magdalena" },
      { code: "CO-MET", name: "Meta" },
      { code: "CO-NAR", name: "Nariño" },
      { code: "CO-NSA", name: "Norte de Santander" },
      { code: "CO-PUT", name: "Putumayo" },
      { code: "CO-QUI", name: "Quindío" },
      { code: "CO-RIS", name: "Risaralda" },
      { code: "CO-SAP", name: "San Andrés, Providencia y Santa Catalina" },
      { code: "CO-SAN", name: "Santander" },
      { code: "CO-SUC", name: "Sucre" },
      { code: "CO-TOL", name: "Tolima" },
      { code: "CO-VAC", name: "Valle del Cauca" },
      { code: "CO-VAU", name: "Vaupés" },
      { code: "CO-VID", name: "Vichada" },
    ],
  },
  {
    name: "Comoros (the)",
    alpha2: "KM",
    alpha3: "COM",
    numeric: "174",
    subdivisions: [
      { code: "KM-G", name: "Andjazîdja" },
      { code: "KM-A", name: "Andjouân" },
      { code: "KM-M", name: "Mohéli" },
    ],
  },
  {
    name: "Congo (the Democratic Republic of the)",
    alpha2: "CD",
    alpha3: "COD",
    numeric: "180",
    subdivisions: [
      { code: "CD-BU", name: "Bas-Uélé" },
      { code: "CD-HK", name: "Haut-Katanga" },
      { code: "CD-HL", name: "Haut-Lomami" },
      { code: "CD-HU", name: "Haut-Uélé" },
      { code: "CD-IT", name: "Ituri" },
      { code: "CD-KS", name: "Kasaï" },
      { code: "CD-KC", name: "Kasaï Central" },
      { code: "CD-KE", name: "Kasaï Oriental" },
      { code: "CD-KN", name: "Kinshasa" },
      { code: "CD-BC", name: "Kongo Central" },
      { code: "CD-KG", name: "Kwango" },
      { code: "CD-KL", name: "Kwilu" },
      { code: "CD-LO", name: "Lomami" },
      { code: "CD-LU", name: "Lualaba" },
      { code: "CD-MN", name: "Mai-Ndombe" },
      { code: "CD-MA", name: "Maniema" },
      { code: "CD-MO", name: "Mongala" },
      { code: "CD-NK", name: "Nord-Kivu" },
      { code: "CD-NU", name: "Nord-Ubangi" },
      { code: "CD-SA", name: "Sankuru" },
      { code: "CD-SK", name: "Sud-Kivu" },
      { code: "CD-SU", name: "Sud-Ubangi" },
      { code: "CD-TA", name: "Tanganyika" },
      { code: "CD-TO", name: "Tshopo" },
      { code: "CD-TU", name: "Tshuapa" },
      { code: "CD-EQ", name: "Équateur" },
    ],
  },
  {
    name: "Congo (the)",
    alpha2: "CG",
    alpha3: "COG",
    numeric: "178",
    subdivisions: [
      { code: "CG-11", name: "Bouenza" },
      { code: "CG-BZV", name: "Brazzaville" },
      { code: "CG-8", name: "Cuvette" },
      { code: "CG-15", name: "Cuvette-Ouest" },
      { code: "CG-5", name: "Kouilou" },
      { code: "CG-7", name: "Likouala" },
      { code: "CG-2", name: "Lékoumou" },
      { code: "CG-9", name: "Niari" },
      { code: "CG-14", name: "Plateaux" },
      { code: "CG-16", name: "Pointe-Noire" },
      { code: "CG-12", name: "Pool" },
      { code: "CG-13", name: "Sangha" },
    ],
  },
  {
    name: "Cook Islands (the)",
    alpha2: "CK",
    alpha3: "COK",
    numeric: "184",
    subdivisions: [],
  },
  {
    name: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    numeric: "188",
    subdivisions: [
      { code: "CR-A", name: "Alajuela" },
      { code: "CR-C", name: "Cartago" },
      { code: "CR-G", name: "Guanacaste" },
      { code: "CR-H", name: "Heredia" },
      { code: "CR-L", name: "Limón" },
      { code: "CR-P", name: "Puntarenas" },
      { code: "CR-SJ", name: "San José" },
    ],
  },
  {
    name: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    numeric: "191",
    subdivisions: [
      { code: "HR-07", name: "Bjelovarsko-bilogorska županija" },
      { code: "HR-12", name: "Brodsko-posavska županija" },
      { code: "HR-19", name: "Dubrovačko-neretvanska županija" },
      { code: "HR-21", name: "Grad Zagreb" },
      { code: "HR-18", name: "Istarska županija" },
      { code: "HR-04", name: "Karlovačka županija" },
      { code: "HR-06", name: "Koprivničko-križevačka županija" },
      { code: "HR-02", name: "Krapinsko-zagorska županija" },
      { code: "HR-09", name: "Ličko-senjska županija" },
      { code: "HR-20", name: "Međimurska županija" },
      { code: "HR-14", name: "Osječko-baranjska županija" },
      { code: "HR-11", name: "Požeško-slavonska županija" },
      { code: "HR-08", name: "Primorsko-goranska županija" },
      { code: "HR-03", name: "Sisačko-moslavačka županija" },
      { code: "HR-17", name: "Splitsko-dalmatinska županija" },
      { code: "HR-05", name: "Varaždinska županija" },
      { code: "HR-10", name: "Virovitičko-podravska županija" },
      { code: "HR-16", name: "Vukovarsko-srijemska županija" },
      { code: "HR-13", name: "Zadarska županija" },
      { code: "HR-01", name: "Zagrebačka županija" },
      { code: "HR-15", name: "Šibensko-kninska županija" },
    ],
  },
  {
    name: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    numeric: "192",
    subdivisions: [
      { code: "CU-15", name: "Artemisa" },
      { code: "CU-09", name: "Camagüey" },
      { code: "CU-08", name: "Ciego de Ávila" },
      { code: "CU-06", name: "Cienfuegos" },
      { code: "CU-12", name: "Granma" },
      { code: "CU-14", name: "Guantánamo" },
      { code: "CU-11", name: "Holguín" },
      { code: "CU-99", name: "Isla de la Juventud" },
      { code: "CU-03", name: "La Habana" },
      { code: "CU-10", name: "Las Tunas" },
      { code: "CU-04", name: "Matanzas" },
      { code: "CU-16", name: "Mayabeque" },
      { code: "CU-01", name: "Pinar del Río" },
      { code: "CU-07", name: "Sancti Spíritus" },
      { code: "CU-13", name: "Santiago de Cuba" },
      { code: "CU-05", name: "Villa Clara" },
    ],
  },
  {
    name: "Curaçao",
    alpha2: "CW",
    alpha3: "CUW",
    numeric: "531",
    subdivisions: [],
  },
  {
    name: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    numeric: "196",
    subdivisions: [
      { code: "CY-04", name: "Ammochostos" },
      { code: "CY-05", name: "Baf" },
      { code: "CY-06", name: "Girne" },
      { code: "CY-03", name: "Larnaka" },
      { code: "CY-01", name: "Lefkosia" },
      { code: "CY-02", name: "Lemesos" },
    ],
  },
  {
    name: "Czechia",
    alpha2: "CZ",
    alpha3: "CZE",
    numeric: "203",
    subdivisions: [
      { code: "CZ-201", name: "Benešov" },
      { code: "CZ-202", name: "Beroun" },
      { code: "CZ-641", name: "Blansko" },
      { code: "CZ-642", name: "Brno-město" },
      { code: "CZ-643", name: "Brno-venkov" },
      { code: "CZ-801", name: "Bruntál" },
      { code: "CZ-644", name: "Břeclav" },
      { code: "CZ-411", name: "Cheb" },
      { code: "CZ-422", name: "Chomutov" },
      { code: "CZ-531", name: "Chrudim" },
      { code: "CZ-321", name: "Domažlice" },
      { code: "CZ-421", name: "Děčín" },
      { code: "CZ-802", name: "Frýdek-Místek" },
      { code: "CZ-631", name: "Havlíčkův Brod" },
      { code: "CZ-645", name: "Hodonín" },
      { code: "CZ-521", name: "Hradec Králové" },
      { code: "CZ-512", name: "Jablonec nad Nisou" },
      { code: "CZ-711", name: "Jeseník" },
      { code: "CZ-632", name: "Jihlava" },
      { code: "CZ-64", name: "Jihomoravský kraj" },
      { code: "CZ-31", name: "Jihočeský kraj" },
      { code: "CZ-313", name: "Jindřichův Hradec" },
      { code: "CZ-522", name: "Jičín" },
      { code: "CZ-41", name: "Karlovarský kraj" },
      { code: "CZ-412", name: "Karlovy Vary" },
      { code: "CZ-803", name: "Karviná" },
      { code: "CZ-203", name: "Kladno" },
      { code: "CZ-322", name: "Klatovy" },
      { code: "CZ-204", name: "Kolín" },
      { code: "CZ-63", name: "Kraj Vysočina" },
      { code: "CZ-721", name: "Kroměříž" },
      { code: "CZ-52", name: "Královéhradecký kraj" },
      { code: "CZ-205", name: "Kutná Hora" },
      { code: "CZ-513", name: "Liberec" },
      { code: "CZ-51", name: "Liberecký kraj" },
      { code: "CZ-423", name: "Litoměřice" },
      { code: "CZ-424", name: "Louny" },
      { code: "CZ-207", name: "Mladá Boleslav" },
      { code: "CZ-80", name: "Moravskoslezský kraj" },
      { code: "CZ-425", name: "Most" },
      { code: "CZ-206", name: "Mělník" },
      { code: "CZ-804", name: "Nový Jičín" },
      { code: "CZ-208", name: "Nymburk" },
      { code: "CZ-523", name: "Náchod" },
      { code: "CZ-712", name: "Olomouc" },
      { code: "CZ-71", name: "Olomoucký kraj" },
      { code: "CZ-805", name: "Opava" },
      { code: "CZ-806", name: "Ostrava-město" },
      { code: "CZ-532", name: "Pardubice" },
      { code: "CZ-53", name: "Pardubický kraj" },
      { code: "CZ-633", name: "Pelhřimov" },
      { code: "CZ-324", name: "Plzeň-jih" },
      { code: "CZ-323", name: "Plzeň-město" },
      { code: "CZ-325", name: "Plzeň-sever" },
      { code: "CZ-32", name: "Plzeňský kraj" },
      { code: "CZ-315", name: "Prachatice" },
      { code: "CZ-10", name: "Praha, Hlavní město" },
      { code: "CZ-209", name: "Praha-východ" },
      { code: "CZ-20A", name: "Praha-západ" },
      { code: "CZ-713", name: "Prostějov" },
      { code: "CZ-314", name: "Písek" },
      { code: "CZ-714", name: "Přerov" },
      { code: "CZ-20B", name: "Příbram" },
      { code: "CZ-20C", name: "Rakovník" },
      { code: "CZ-326", name: "Rokycany" },
      { code: "CZ-524", name: "Rychnov nad Kněžnou" },
      { code: "CZ-514", name: "Semily" },
      { code: "CZ-413", name: "Sokolov" },
      { code: "CZ-316", name: "Strakonice" },
      { code: "CZ-20", name: "Středočeský kraj" },
      { code: "CZ-533", name: "Svitavy" },
      { code: "CZ-327", name: "Tachov" },
      { code: "CZ-426", name: "Teplice" },
      { code: "CZ-525", name: "Trutnov" },
      { code: "CZ-317", name: "Tábor" },
      { code: "CZ-634", name: "Třebíč" },
      { code: "CZ-722", name: "Uherské Hradiště" },
      { code: "CZ-723", name: "Vsetín" },
      { code: "CZ-646", name: "Vyškov" },
      { code: "CZ-724", name: "Zlín" },
      { code: "CZ-72", name: "Zlínský kraj" },
      { code: "CZ-647", name: "Znojmo" },
      { code: "CZ-42", name: "Ústecký kraj" },
      { code: "CZ-427", name: "Ústí nad Labem" },
      { code: "CZ-534", name: "Ústí nad Orlicí" },
      { code: "CZ-511", name: "Česká Lípa" },
      { code: "CZ-311", name: "České Budějovice" },
      { code: "CZ-312", name: "Český Krumlov" },
      { code: "CZ-715", name: "Šumperk" },
      { code: "CZ-635", name: "Žďár nad Sázavou" },
    ],
  },
  {
    name: "Côte d'Ivoire",
    alpha2: "CI",
    alpha3: "CIV",
    numeric: "384",
    subdivisions: [
      { code: "CI-AB", name: "Abidjan" },
      { code: "CI-BS", name: "Bas-Sassandra" },
      { code: "CI-CM", name: "Comoé" },
      { code: "CI-DN", name: "Denguélé" },
      { code: "CI-GD", name: "Gôh-Djiboua" },
      { code: "CI-LC", name: "Lacs" },
      { code: "CI-LG", name: "Lagunes" },
      { code: "CI-MG", name: "Montagnes" },
      { code: "CI-SM", name: "Sassandra-Marahoué" },
      { code: "CI-SV", name: "Savanes" },
      { code: "CI-VB", name: "Vallée du Bandama" },
      { code: "CI-WR", name: "Woroba" },
      { code: "CI-YM", name: "Yamoussoukro" },
      { code: "CI-ZZ", name: "Zanzan" },
    ],
  },
  {
    name: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    numeric: "208",
    subdivisions: [
      { code: "DK-84", name: "Hovedstaden" },
      { code: "DK-82", name: "Midtjylland" },
      { code: "DK-81", name: "Nordjylland" },
      { code: "DK-85", name: "Sjælland" },
      { code: "DK-83", name: "Syddanmark" },
    ],
  },
  {
    name: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    numeric: "262",
    subdivisions: [
      { code: "DJ-AS", name: "Ali Sabieh" },
      { code: "DJ-AR", name: "Arta" },
      { code: "DJ-OB", name: "Awbūk" },
      { code: "DJ-DI", name: "Dikhil" },
      { code: "DJ-DJ", name: "Djibouti" },
      { code: "DJ-TA", name: "Tadjourah" },
    ],
  },
  {
    name: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    numeric: "212",
    subdivisions: [
      { code: "DM-02", name: "Saint Andrew" },
      { code: "DM-03", name: "Saint David" },
      { code: "DM-04", name: "Saint George" },
      { code: "DM-05", name: "Saint John" },
      { code: "DM-06", name: "Saint Joseph" },
      { code: "DM-07", name: "Saint Luke" },
      { code: "DM-08", name: "Saint Mark" },
      { code: "DM-09", name: "Saint Patrick" },
      { code: "DM-10", name: "Saint Paul" },
      { code: "DM-11", name: "Saint Peter" },
    ],
  },
  {
    name: "Dominican Republic (the)",
    alpha2: "DO",
    alpha3: "DOM",
    numeric: "214",
    subdivisions: [
      { code: "DO-02", name: "Azua" },
      { code: "DO-03", name: "Baoruco" },
      { code: "DO-04", name: "Barahona" },
      { code: "DO-33", name: "Cibao Nordeste" },
      { code: "DO-34", name: "Cibao Noroeste" },
      { code: "DO-35", name: "Cibao Norte" },
      { code: "DO-36", name: "Cibao Sur" },
      { code: "DO-05", name: "Dajabón" },
      { code: "DO-01", name: "Distrito Nacional (Santo Domingo)" },
      { code: "DO-06", name: "Duarte" },
      { code: "DO-08", name: "El Seibo" },
      { code: "DO-37", name: "El Valle" },
      { code: "DO-07", name: "Elías Piña" },
      { code: "DO-38", name: "Enriquillo" },
      { code: "DO-09", name: "Espaillat" },
      { code: "DO-30", name: "Hato Mayor" },
      { code: "DO-19", name: "Hermanas Mirabal" },
      { code: "DO-39", name: "Higuamo" },
      { code: "DO-10", name: "Independencia" },
      { code: "DO-11", name: "La Altagracia" },
      { code: "DO-12", name: "La Romana" },
      { code: "DO-13", name: "La Vega" },
      { code: "DO-14", name: "María Trinidad Sánchez" },
      { code: "DO-28", name: "Monseñor Nouel" },
      { code: "DO-15", name: "Monte Cristi" },
      { code: "DO-29", name: "Monte Plata" },
      { code: "DO-40", name: "Ozama" },
      { code: "DO-16", name: "Pedernales" },
      { code: "DO-17", name: "Peravia" },
      { code: "DO-18", name: "Puerto Plata" },
      { code: "DO-20", name: "Samaná" },
      { code: "DO-21", name: "San Cristóbal" },
      { code: "DO-31", name: "San José de Ocoa" },
      { code: "DO-22", name: "San Juan" },
      { code: "DO-23", name: "San Pedro de Macorís" },
      { code: "DO-25", name: "Santiago" },
      { code: "DO-26", name: "Santiago Rodríguez" },
      { code: "DO-32", name: "Santo Domingo" },
      { code: "DO-24", name: "Sánchez Ramírez" },
      { code: "DO-41", name: "Valdesia" },
      { code: "DO-27", name: "Valverde" },
      { code: "DO-42", name: "Yuma" },
    ],
  },
  {
    name: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    numeric: "218",
    subdivisions: [
      { code: "EC-A", name: "Azuay" },
      { code: "EC-B", name: "Bolívar" },
      { code: "EC-C", name: "Carchi" },
      { code: "EC-F", name: "Cañar" },
      { code: "EC-H", name: "Chimborazo" },
      { code: "EC-X", name: "Cotopaxi" },
      { code: "EC-O", name: "El Oro" },
      { code: "EC-E", name: "Esmeraldas" },
      { code: "EC-W", name: "Galápagos" },
      { code: "EC-G", name: "Guayas" },
      { code: "EC-I", name: "Imbabura" },
      { code: "EC-L", name: "Loja" },
      { code: "EC-R", name: "Los Ríos" },
      { code: "EC-M", name: "Manabí" },
      { code: "EC-S", name: "Morona Santiago" },
      { code: "EC-N", name: "Napo" },
      { code: "EC-D", name: "Orellana" },
      { code: "EC-Y", name: "Pastaza" },
      { code: "EC-P", name: "Pichincha" },
      { code: "EC-SE", name: "Santa Elena" },
      { code: "EC-SD", name: "Santo Domingo de los Tsáchilas" },
      { code: "EC-U", name: "Sucumbíos" },
      { code: "EC-T", name: "Tungurahua" },
      { code: "EC-Z", name: "Zamora Chinchipe" },
    ],
  },
  {
    name: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    numeric: "818",
    subdivisions: [
      { code: "EG-DK", name: "Ad Daqahlīyah" },
      { code: "EG-BA", name: "Al Baḩr al Aḩmar" },
      { code: "EG-BH", name: "Al Buḩayrah" },
      { code: "EG-FYM", name: "Al Fayyūm" },
      { code: "EG-GH", name: "Al Gharbīyah" },
      { code: "EG-ALX", name: "Al Iskandarīyah" },
      { code: "EG-IS", name: "Al Ismā'īlīyah" },
      { code: "EG-GZ", name: "Al Jīzah" },
      { code: "EG-MN", name: "Al Minyā" },
      { code: "EG-MNF", name: "Al Minūfīyah" },
      { code: "EG-KB", name: "Al Qalyūbīyah" },
      { code: "EG-C", name: "Al Qāhirah" },
      { code: "EG-LX", name: "Al Uqşur" },
      { code: "EG-WAD", name: "Al Wādī al Jadīd" },
      { code: "EG-SUZ", name: "As Suways" },
      { code: "EG-SHR", name: "Ash Sharqīyah" },
      { code: "EG-ASN", name: "Aswān" },
      { code: "EG-AST", name: "Asyūţ" },
      { code: "EG-BNS", name: "Banī Suwayf" },
      { code: "EG-PTS", name: "Būr Sa‘īd" },
      { code: "EG-DT", name: "Dumyāţ" },
      { code: "EG-JS", name: "Janūb Sīnā'" },
      { code: "EG-KFS", name: "Kafr ash Shaykh" },
      { code: "EG-MT", name: "Maţrūḩ" },
      { code: "EG-KN", name: "Qinā" },
      { code: "EG-SIN", name: "Shamāl Sīnā'" },
      { code: "EG-SHG", name: "Sūhāj" },
    ],
  },
  {
    name: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    numeric: "222",
    subdivisions: [
      { code: "SV-AH", name: "Ahuachapán" },
      { code: "SV-CA", name: "Cabañas" },
      { code: "SV-CH", name: "Chalatenango" },
      { code: "SV-CU", name: "Cuscatlán" },
      { code: "SV-LI", name: "La Libertad" },
      { code: "SV-PA", name: "La Paz" },
      { code: "SV-UN", name: "La Unión" },
      { code: "SV-MO", name: "Morazán" },
      { code: "SV-SM", name: "San Miguel" },
      { code: "SV-SS", name: "San Salvador" },
      { code: "SV-SV", name: "San Vicente" },
      { code: "SV-SA", name: "Santa Ana" },
      { code: "SV-SO", name: "Sonsonate" },
      { code: "SV-US", name: "Usulután" },
    ],
  },
  {
    name: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    numeric: "226",
    subdivisions: [
      { code: "GQ-AN", name: "Annobon" },
      { code: "GQ-BN", name: "Bioko Nord" },
      { code: "GQ-BS", name: "Bioko Sud" },
      { code: "GQ-CS", name: "Centro Sud" },
      { code: "GQ-KN", name: "Kié-Ntem" },
      { code: "GQ-LI", name: "Litoral" },
      { code: "GQ-C", name: "Região Continental" },
      { code: "GQ-I", name: "Região Insular" },
      { code: "GQ-WN", name: "Wele-Nzas" },
    ],
  },
  {
    name: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    numeric: "232",
    subdivisions: [
      { code: "ER-MA", name: "Al Awsaţ" },
      { code: "ER-DU", name: "Al Janūbī" },
      { code: "ER-AN", name: "Ansabā" },
      { code: "ER-DK", name: "Debubawi K’eyyĭḥ Baḥri" },
      { code: "ER-GB", name: "Gash-Barka" },
      { code: "ER-SK", name: "Semienawi K’eyyĭḥ Baḥri" },
    ],
  },
  {
    name: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    numeric: "233",
    subdivisions: [
      { code: "EE-37", name: "Harjumaa" },
      { code: "EE-39", name: "Hiiumaa" },
      { code: "EE-44", name: "Ida-Virumaa" },
      { code: "EE-51", name: "Järvamaa" },
      { code: "EE-49", name: "Jõgevamaa" },
      { code: "EE-59", name: "Lääne-Virumaa" },
      { code: "EE-57", name: "Läänemaa" },
      { code: "EE-67", name: "Pärnumaa" },
      { code: "EE-65", name: "Põlvamaa" },
      { code: "EE-70", name: "Raplamaa" },
      { code: "EE-74", name: "Saaremaa" },
      { code: "EE-78", name: "Tartumaa" },
      { code: "EE-82", name: "Valgamaa" },
      { code: "EE-84", name: "Viljandimaa" },
      { code: "EE-86", name: "Võrumaa" },
    ],
  },
  {
    name: "Eswatini",
    alpha2: "SZ",
    alpha3: "SWZ",
    numeric: "748",
    subdivisions: [
      { code: "SZ-HH", name: "Hhohho" },
      { code: "SZ-LU", name: "Lubombo" },
      { code: "SZ-MA", name: "Manzini" },
      { code: "SZ-SH", name: "Shiselweni" },
    ],
  },
  {
    name: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    numeric: "231",
    subdivisions: [
      { code: "ET-AA", name: "Addis Ababa" },
      { code: "ET-AF", name: "Afar" },
      { code: "ET-AM", name: "Amara" },
      { code: "ET-BE", name: "Benshangul-Gumaz" },
      { code: "ET-DD", name: "Dire Dawa" },
      { code: "ET-GA", name: "Gambela Peoples" },
      { code: "ET-HA", name: "Harari People" },
      { code: "ET-OR", name: "Oromia" },
      { code: "ET-SO", name: "Somali" },
      { code: "ET-SN", name: "Southern Nations, Nationalities and Peoples" },
      { code: "ET-TI", name: "Tigrai" },
    ],
  },
  {
    name: "Falkland Islands (the) [Malvinas]",
    alpha2: "FK",
    alpha3: "FLK",
    numeric: "238",
    subdivisions: [],
  },
  {
    name: "Faroe Islands (the)",
    alpha2: "FO",
    alpha3: "FRO",
    numeric: "234",
    subdivisions: [],
  },
  {
    name: "Fiji",
    alpha2: "FJ",
    alpha3: "FJI",
    numeric: "242",
    subdivisions: [
      { code: "FJ-01", name: "Ba" },
      { code: "FJ-02", name: "Bua" },
      { code: "FJ-03", name: "Cakaudrove" },
      { code: "FJ-C", name: "Central" },
      { code: "FJ-E", name: "Eastern" },
      { code: "FJ-04", name: "Kadavu" },
      { code: "FJ-05", name: "Lau" },
      { code: "FJ-06", name: "Lomaiviti" },
      { code: "FJ-07", name: "Macuata" },
      { code: "FJ-08", name: "Nadroga and Navosa" },
      { code: "FJ-09", name: "Naitasiri" },
      { code: "FJ-10", name: "Namosi" },
      { code: "FJ-N", name: "Northern" },
      { code: "FJ-11", name: "Ra" },
      { code: "FJ-12", name: "Rewa" },
      { code: "FJ-R", name: "Rotuma" },
      { code: "FJ-13", name: "Serua" },
      { code: "FJ-14", name: "Tailevu" },
      { code: "FJ-W", name: "Western" },
    ],
  },
  {
    name: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    numeric: "246",
    subdivisions: [
      { code: "FI-01", name: "Ahvenanmaan maakunta" },
      { code: "FI-11", name: "Birkaland" },
      { code: "FI-19", name: "Egentliga Finland" },
      { code: "FI-06", name: "Egentliga Tavastland" },
      { code: "FI-02", name: "Etelä-Karjala" },
      { code: "FI-03", name: "Etelä-Pohjanmaa" },
      { code: "FI-04", name: "Etelä-Savo" },
      { code: "FI-05", name: "Kainuu" },
      { code: "FI-07", name: "Keski-Pohjanmaa" },
      { code: "FI-08", name: "Keski-Suomi" },
      { code: "FI-09", name: "Kymenlaakso" },
      { code: "FI-10", name: "Lappi" },
      { code: "FI-13", name: "Norra Karelen" },
      { code: "FI-15", name: "Norra Savolax" },
      { code: "FI-14", name: "Norra Österbotten" },
      { code: "FI-18", name: "Nyland" },
      { code: "FI-12", name: "Pohjanmaa" },
      { code: "FI-16", name: "Päijänne-Tavastland" },
      { code: "FI-17", name: "Satakunda" },
    ],
  },
  {
    name: "France",
    alpha2: "FR",
    alpha3: "FRA",
    numeric: "250",
    subdivisions: [
      { code: "FR-01", name: "Ain" },
      { code: "FR-02", name: "Aisne" },
      { code: "FR-03", name: "Allier" },
      { code: "FR-06", name: "Alpes-Maritimes" },
      { code: "FR-04", name: "Alpes-de-Haute-Provence" },
      { code: "FR-08", name: "Ardennes" },
      { code: "FR-07", name: "Ardèche" },
      { code: "FR-09", name: "Ariège" },
      { code: "FR-10", name: "Aube" },
      { code: "FR-11", name: "Aude" },
      { code: "FR-ARA", name: "Auvergne-Rhône-Alpes" },
      { code: "FR-12", name: "Aveyron" },
      { code: "FR-67", name: "Bas-Rhin" },
      { code: "FR-13", name: "Bouches-du-Rhône" },
      { code: "FR-BFC", name: "Bourgogne-Franche-Comté" },
      { code: "FR-BRE", name: "Bretagne" },
      { code: "FR-14", name: "Calvados" },
      { code: "FR-15", name: "Cantal" },
      { code: "FR-CVL", name: "Centre-Val de Loire" },
      { code: "FR-16", name: "Charente" },
      { code: "FR-17", name: "Charente-Maritime" },
      { code: "FR-18", name: "Cher" },
      { code: "FR-CP", name: "Clipperton" },
      { code: "FR-19", name: "Corrèze" },
      { code: "FR-COR", name: "Corse" },
      { code: "FR-2A", name: "Corse-du-Sud" },
      { code: "FR-23", name: "Creuse" },
      { code: "FR-21", name: "Côte-d'Or" },
      { code: "FR-22", name: "Côtes-d'Armor" },
      { code: "FR-79", name: "Deux-Sèvres" },
      { code: "FR-24", name: "Dordogne" },
      { code: "FR-25", name: "Doubs" },
      { code: "FR-26", name: "Drôme" },
      { code: "FR-91", name: "Essonne" },
      { code: "FR-27", name: "Eure" },
      { code: "FR-28", name: "Eure-et-Loir" },
      { code: "FR-29", name: "Finistère" },
      { code: "FR-30", name: "Gard" },
      { code: "FR-32", name: "Gers" },
      { code: "FR-33", name: "Gironde" },
      { code: "FR-GES", name: "Grand-Est" },
      { code: "FR-GUA", name: "Guadeloupe" },
      {
        code: "FR-GP",
        name: "Guadeloupe (see also separate country code entry under GP)",
      },
      {
        code: "FR-GF",
        name: "Guyane (française) (see also separate country code entry under GF)",
      },
      { code: "FR-68", name: "Haut-Rhin" },
      { code: "FR-2B", name: "Haute-Corse" },
      { code: "FR-31", name: "Haute-Garonne" },
      { code: "FR-43", name: "Haute-Loire" },
      { code: "FR-52", name: "Haute-Marne" },
      { code: "FR-74", name: "Haute-Savoie" },
      { code: "FR-70", name: "Haute-Saône" },
      { code: "FR-87", name: "Haute-Vienne" },
      { code: "FR-05", name: "Hautes-Alpes" },
      { code: "FR-65", name: "Hautes-Pyrénées" },
      { code: "FR-HDF", name: "Hauts-de-France" },
      { code: "FR-92", name: "Hauts-de-Seine" },
      { code: "FR-34", name: "Hérault" },
      { code: "FR-35", name: "Ille-et-Vilaine" },
      { code: "FR-36", name: "Indre" },
      { code: "FR-37", name: "Indre-et-Loire" },
      { code: "FR-38", name: "Isère" },
      { code: "FR-39", name: "Jura" },
      { code: "FR-LRE", name: "La Réunion" },
      {
        code: "FR-RE",
        name: "La Réunion (see also separate country code entry under RE)",
      },
      { code: "FR-40", name: "Landes" },
      { code: "FR-41", name: "Loir-et-Cher" },
      { code: "FR-42", name: "Loire" },
      { code: "FR-44", name: "Loire-Atlantique" },
      { code: "FR-45", name: "Loiret" },
      { code: "FR-46", name: "Lot" },
      { code: "FR-47", name: "Lot-et-Garonne" },
      { code: "FR-48", name: "Lozère" },
      { code: "FR-49", name: "Maine-et-Loire" },
      { code: "FR-50", name: "Manche" },
      { code: "FR-51", name: "Marne" },
      {
        code: "FR-MQ",
        name: "Martinique (see also separate country code entry under MQ)",
      },
      { code: "FR-53", name: "Mayenne" },
      { code: "FR-MAY", name: "Mayotte" },
      {
        code: "FR-YT",
        name: "Mayotte (see also separate country code entry under YT)",
      },
      { code: "FR-54", name: "Meurthe-et-Moselle" },
      { code: "FR-55", name: "Meuse" },
      { code: "FR-56", name: "Morbihan" },
      { code: "FR-57", name: "Moselle" },
      { code: "FR-58", name: "Nièvre" },
      { code: "FR-59", name: "Nord" },
      { code: "FR-NOR", name: "Normandie" },
      { code: "FR-NAQ", name: "Nouvelle-Aquitaine" },
      {
        code: "FR-NC",
        name: "Nouvelle-Calédonie (see also separate country code entry under NC)",
      },
      { code: "FR-OCC", name: "Occitanie" },
      { code: "FR-60", name: "Oise" },
      { code: "FR-61", name: "Orne" },
      { code: "FR-75", name: "Paris" },
      { code: "FR-62", name: "Pas-de-Calais" },
      { code: "FR-PDL", name: "Pays-de-la-Loire" },
      {
        code: "FR-PF",
        name: "Polynésie française (see also separate country code entry under PF)",
      },
      { code: "FR-PAC", name: "Provence-Alpes-Côte-d’Azur" },
      { code: "FR-63", name: "Puy-de-Dôme" },
      { code: "FR-64", name: "Pyrénées-Atlantiques" },
      { code: "FR-66", name: "Pyrénées-Orientales" },
      { code: "FR-69", name: "Rhône" },
      {
        code: "FR-BL",
        name: "Saint-Barthélemy (see also separate country code entry under BL)",
      },
      {
        code: "FR-MF",
        name: "Saint-Martin (see also separate country code entry under MF)",
      },
      {
        code: "FR-PM",
        name: "Saint-Pierre-et-Miquelon (see also separate country code entry under PM)",
      },
      { code: "FR-72", name: "Sarthe" },
      { code: "FR-73", name: "Savoie" },
      { code: "FR-71", name: "Saône-et-Loire" },
      { code: "FR-76", name: "Seine-Maritime" },
      { code: "FR-93", name: "Seine-Saint-Denis" },
      { code: "FR-77", name: "Seine-et-Marne" },
      { code: "FR-80", name: "Somme" },
      { code: "FR-81", name: "Tarn" },
      { code: "FR-82", name: "Tarn-et-Garonne" },
      {
        code: "FR-TF",
        name: "Terres australes françaises (see also separate country code entry under TF)",
      },
      { code: "FR-90", name: "Territoire de Belfort" },
      { code: "FR-95", name: "Val-d'Oise" },
      { code: "FR-94", name: "Val-de-Marne" },
      { code: "FR-83", name: "Var" },
      { code: "FR-84", name: "Vaucluse" },
      { code: "FR-85", name: "Vendée" },
      { code: "FR-86", name: "Vienne" },
      { code: "FR-88", name: "Vosges" },
      {
        code: "FR-WF",
        name: "Wallis-et-Futuna (see also separate country code entry under WF)",
      },
      { code: "FR-89", name: "Yonne" },
      { code: "FR-78", name: "Yvelines" },
      { code: "FR-IDF", name: "Île-de-France" },
    ],
  },
  {
    name: "French Guiana",
    alpha2: "GF",
    alpha3: "GUF",
    numeric: "254",
    subdivisions: [],
  },
  {
    name: "French Polynesia",
    alpha2: "PF",
    alpha3: "PYF",
    numeric: "258",
    subdivisions: [],
  },
  {
    name: "French Southern Territories (the)",
    alpha2: "TF",
    alpha3: "ATF",
    numeric: "260",
    subdivisions: [],
  },
  {
    name: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    numeric: "266",
    subdivisions: [
      { code: "GA-1", name: "Estuaire" },
      { code: "GA-2", name: "Haut-Ogooué" },
      { code: "GA-3", name: "Moyen-Ogooué" },
      { code: "GA-4", name: "Ngounié" },
      { code: "GA-5", name: "Nyanga" },
      { code: "GA-6", name: "Ogooué-Ivindo" },
      { code: "GA-7", name: "Ogooué-Lolo" },
      { code: "GA-8", name: "Ogooué-Maritime" },
      { code: "GA-9", name: "Woleu-Ntem" },
    ],
  },
  {
    name: "Gambia (the)",
    alpha2: "GM",
    alpha3: "GMB",
    numeric: "270",
    subdivisions: [
      { code: "GM-B", name: "Banjul" },
      { code: "GM-M", name: "Central River" },
      { code: "GM-L", name: "Lower River" },
      { code: "GM-N", name: "North Bank" },
      { code: "GM-U", name: "Upper River" },
      { code: "GM-W", name: "Western" },
    ],
  },
  {
    name: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    numeric: "268",
    subdivisions: [
      { code: "GE-AB", name: "Abkhazia" },
      { code: "GE-AJ", name: "Ajaria" },
      { code: "GE-GU", name: "Guria" },
      { code: "GE-IM", name: "Imereti" },
      { code: "GE-KA", name: "K'akheti" },
      { code: "GE-KK", name: "Kvemo Kartli" },
      { code: "GE-MM", name: "Mtskheta-Mtianeti" },
      { code: "GE-RL", name: "Rach'a-Lechkhumi-Kvemo Svaneti" },
      { code: "GE-SZ", name: "Samegrelo-Zemo Svaneti" },
      { code: "GE-SJ", name: "Samtskhe-Javakheti" },
      { code: "GE-SK", name: "Shida Kartli" },
      { code: "GE-TB", name: "Tbilisi" },
    ],
  },
  {
    name: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    numeric: "276",
    subdivisions: [
      { code: "DE-BW", name: "Baden-Württemberg" },
      { code: "DE-BY", name: "Bayern" },
      { code: "DE-BE", name: "Berlin" },
      { code: "DE-BB", name: "Brandenburg" },
      { code: "DE-HB", name: "Bremen" },
      { code: "DE-HH", name: "Hamburg" },
      { code: "DE-HE", name: "Hessen" },
      { code: "DE-MV", name: "Mecklenburg-Vorpommern" },
      { code: "DE-NI", name: "Niedersachsen" },
      { code: "DE-NW", name: "Nordrhein-Westfalen" },
      { code: "DE-RP", name: "Rheinland-Pfalz" },
      { code: "DE-SL", name: "Saarland" },
      { code: "DE-SN", name: "Sachsen" },
      { code: "DE-ST", name: "Sachsen-Anhalt" },
      { code: "DE-SH", name: "Schleswig-Holstein" },
      { code: "DE-TH", name: "Thüringen" },
    ],
  },
  {
    name: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    numeric: "288",
    subdivisions: [
      { code: "GH-AF", name: "Ahafo" },
      { code: "GH-AH", name: "Ashanti" },
      { code: "GH-BO", name: "Bono" },
      { code: "GH-BE", name: "Bono East" },
      { code: "GH-CP", name: "Central" },
      { code: "GH-EP", name: "Eastern" },
      { code: "GH-AA", name: "Greater Accra" },
      { code: "GH-NE", name: "North East" },
      { code: "GH-NP", name: "Northern" },
      { code: "GH-OT", name: "Oti" },
      { code: "GH-SV", name: "Savannah" },
      { code: "GH-UE", name: "Upper East" },
      { code: "GH-UW", name: "Upper West" },
      { code: "GH-TV", name: "Volta" },
      { code: "GH-WP", name: "Western" },
      { code: "GH-WN", name: "Western North" },
    ],
  },
  {
    name: "Gibraltar",
    alpha2: "GI",
    alpha3: "GIB",
    numeric: "292",
    subdivisions: [],
  },
  {
    name: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    numeric: "300",
    subdivisions: [
      { code: "GR-A", name: "Anatolikí Makedonía kai Thráki" },
      { code: "GR-I", name: "Attikí" },
      { code: "GR-G", name: "Dytikí Elláda" },
      { code: "GR-C", name: "Dytikí Makedonía" },
      { code: "GR-F", name: "Ionía Nísia" },
      { code: "GR-B", name: "Kentrikí Makedonía" },
      { code: "GR-M", name: "Kríti" },
      { code: "GR-L", name: "Nótio Aigaío" },
      { code: "GR-J", name: "Pelopónnisos" },
      { code: "GR-H", name: "Stereá Elláda" },
      { code: "GR-E", name: "Thessalía" },
      { code: "GR-K", name: "Vóreio Aigaío" },
      { code: "GR-69", name: "Ágion Óros" },
      { code: "GR-D", name: "Ípeiros" },
    ],
  },
  {
    name: "Greenland",
    alpha2: "GL",
    alpha3: "GRL",
    numeric: "304",
    subdivisions: [
      { code: "GL-AV", name: "Avannaata Kommunia" },
      { code: "GL-KU", name: "Kommune Kujalleq" },
      { code: "GL-QT", name: "Kommune Qeqertalik" },
      { code: "GL-SM", name: "Kommuneqarfik Sermersooq" },
      { code: "GL-QE", name: "Qeqqata Kommunia" },
    ],
  },
  {
    name: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    numeric: "308",
    subdivisions: [
      { code: "GD-01", name: "Saint Andrew" },
      { code: "GD-02", name: "Saint David" },
      { code: "GD-03", name: "Saint George" },
      { code: "GD-04", name: "Saint John" },
      { code: "GD-05", name: "Saint Mark" },
      { code: "GD-06", name: "Saint Patrick" },
      { code: "GD-10", name: "Southern Grenadine Islands" },
    ],
  },
  {
    name: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    numeric: "312",
    subdivisions: [],
  },
  {
    name: "Guam",
    alpha2: "GU",
    alpha3: "GUM",
    numeric: "316",
    subdivisions: [],
  },
  {
    name: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    numeric: "320",
    subdivisions: [
      { code: "GT-AV", name: "Alta Verapaz" },
      { code: "GT-BV", name: "Baja Verapaz" },
      { code: "GT-CM", name: "Chimaltenango" },
      { code: "GT-CQ", name: "Chiquimula" },
      { code: "GT-PR", name: "El Progreso" },
      { code: "GT-ES", name: "Escuintla" },
      { code: "GT-GU", name: "Guatemala" },
      { code: "GT-HU", name: "Huehuetenango" },
      { code: "GT-IZ", name: "Izabal" },
      { code: "GT-JA", name: "Jalapa" },
      { code: "GT-JU", name: "Jutiapa" },
      { code: "GT-PE", name: "Petén" },
      { code: "GT-QZ", name: "Quetzaltenango" },
      { code: "GT-QC", name: "Quiché" },
      { code: "GT-RE", name: "Retalhuleu" },
      { code: "GT-SA", name: "Sacatepéquez" },
      { code: "GT-SM", name: "San Marcos" },
      { code: "GT-SR", name: "Santa Rosa" },
      { code: "GT-SO", name: "Sololá" },
      { code: "GT-SU", name: "Suchitepéquez" },
      { code: "GT-TO", name: "Totonicapán" },
      { code: "GT-ZA", name: "Zacapa" },
    ],
  },
  {
    name: "Guernsey",
    alpha2: "GG",
    alpha3: "GGY",
    numeric: "831",
    subdivisions: [],
  },
  {
    name: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    numeric: "324",
    subdivisions: [
      { code: "GN-BE", name: "Beyla" },
      { code: "GN-BF", name: "Boffa" },
      { code: "GN-B", name: "Boké" },
      { code: "GN-BK", name: "Boké" },
      { code: "GN-C", name: "Conakry" },
      { code: "GN-CO", name: "Coyah" },
      { code: "GN-DB", name: "Dabola" },
      { code: "GN-DL", name: "Dalaba" },
      { code: "GN-DI", name: "Dinguiraye" },
      { code: "GN-DU", name: "Dubréka" },
      { code: "GN-F", name: "Faranah" },
      { code: "GN-FA", name: "Faranah" },
      { code: "GN-FO", name: "Forécariah" },
      { code: "GN-FR", name: "Fria" },
      { code: "GN-GA", name: "Gaoual" },
      { code: "GN-GU", name: "Guékédou" },
      { code: "GN-K", name: "Kankan" },
      { code: "GN-KA", name: "Kankan" },
      { code: "GN-D", name: "Kindia" },
      { code: "GN-KD", name: "Kindia" },
      { code: "GN-KS", name: "Kissidougou" },
      { code: "GN-KB", name: "Koubia" },
      { code: "GN-KN", name: "Koundara" },
      { code: "GN-KO", name: "Kouroussa" },
      { code: "GN-KE", name: "Kérouané" },
      { code: "GN-L", name: "Labé" },
      { code: "GN-LA", name: "Labé" },
      { code: "GN-LO", name: "Lola" },
      { code: "GN-LE", name: "Lélouma" },
      { code: "GN-MC", name: "Macenta" },
      { code: "GN-ML", name: "Mali" },
      { code: "GN-M", name: "Mamou" },
      { code: "GN-MM", name: "Mamou" },
      { code: "GN-MD", name: "Mandiana" },
      { code: "GN-N", name: "Nzérékoré" },
      { code: "GN-NZ", name: "Nzérékoré" },
      { code: "GN-PI", name: "Pita" },
      { code: "GN-SI", name: "Siguiri" },
      { code: "GN-TO", name: "Tougué" },
      { code: "GN-TE", name: "Télimélé" },
      { code: "GN-YO", name: "Yomou" },
    ],
  },
  {
    name: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    numeric: "624",
    subdivisions: [
      { code: "GW-BA", name: "Bafatá" },
      { code: "GW-BM", name: "Biombo" },
      { code: "GW-BS", name: "Bissau" },
      { code: "GW-BL", name: "Bolama" },
      { code: "GW-CA", name: "Cacheu" },
      { code: "GW-GA", name: "Gabú" },
      { code: "GW-L", name: "Leste" },
      { code: "GW-N", name: "Norte" },
      { code: "GW-OI", name: "Oio" },
      { code: "GW-QU", name: "Quinara" },
      { code: "GW-S", name: "Sul" },
      { code: "GW-TO", name: "Tombali" },
    ],
  },
  {
    name: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    numeric: "328",
    subdivisions: [
      { code: "GY-BA", name: "Barima-Waini" },
      { code: "GY-CU", name: "Cuyuni-Mazaruni" },
      { code: "GY-DE", name: "Demerara-Mahaica" },
      { code: "GY-EB", name: "East Berbice-Corentyne" },
      { code: "GY-ES", name: "Essequibo Islands-West Demerara" },
      { code: "GY-MA", name: "Mahaica-Berbice" },
      { code: "GY-PM", name: "Pomeroon-Supenaam" },
      { code: "GY-PT", name: "Potaro-Siparuni" },
      { code: "GY-UD", name: "Upper Demerara-Berbice" },
      { code: "GY-UT", name: "Upper Takutu-Upper Essequibo" },
    ],
  },
  {
    name: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    numeric: "332",
    subdivisions: [
      { code: "HT-AR", name: "Artibonite" },
      { code: "HT-CE", name: "Centre" },
      { code: "HT-GA", name: "Grandans" },
      { code: "HT-OU", name: "Lwès" },
      { code: "HT-NI", name: "Nip" },
      { code: "HT-ND", name: "Nord" },
      { code: "HT-NE", name: "Nord-Est" },
      { code: "HT-NO", name: "Nord-Ouest" },
      { code: "HT-SD", name: "Sid" },
      { code: "HT-SE", name: "Sidès" },
    ],
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha2: "HM",
    alpha3: "HMD",
    numeric: "334",
    subdivisions: [],
  },
  {
    name: "Holy See (the)",
    alpha2: "VA",
    alpha3: "VAT",
    numeric: "336",
    subdivisions: [],
  },
  {
    name: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    numeric: "340",
    subdivisions: [
      { code: "HN-AT", name: "Atlántida" },
      { code: "HN-CH", name: "Choluteca" },
      { code: "HN-CL", name: "Colón" },
      { code: "HN-CM", name: "Comayagua" },
      { code: "HN-CP", name: "Copán" },
      { code: "HN-CR", name: "Cortés" },
      { code: "HN-EP", name: "El Paraíso" },
      { code: "HN-FM", name: "Francisco Morazán" },
      { code: "HN-GD", name: "Gracias a Dios" },
      { code: "HN-IN", name: "Intibucá" },
      { code: "HN-IB", name: "Islas de la Bahía" },
      { code: "HN-LP", name: "La Paz" },
      { code: "HN-LE", name: "Lempira" },
      { code: "HN-OC", name: "Ocotepeque" },
      { code: "HN-OL", name: "Olancho" },
      { code: "HN-SB", name: "Santa Bárbara" },
      { code: "HN-VA", name: "Valle" },
      { code: "HN-YO", name: "Yoro" },
    ],
  },
  {
    name: "Hong Kong",
    alpha2: "HK",
    alpha3: "HKG",
    numeric: "344",
    subdivisions: [],
  },
  {
    name: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    numeric: "348",
    subdivisions: [
      { code: "HU-BA", name: "Baranya" },
      { code: "HU-BZ", name: "Borsod-Abaúj-Zemplén" },
      { code: "HU-BU", name: "Budapest" },
      { code: "HU-BK", name: "Bács-Kiskun" },
      { code: "HU-BE", name: "Békés" },
      { code: "HU-BC", name: "Békéscsaba" },
      { code: "HU-CS", name: "Csongrád" },
      { code: "HU-DE", name: "Debrecen" },
      { code: "HU-DU", name: "Dunaújváros" },
      { code: "HU-EG", name: "Eger" },
      { code: "HU-FE", name: "Fejér" },
      { code: "HU-GY", name: "Győr" },
      { code: "HU-GS", name: "Győr-Moson-Sopron" },
      { code: "HU-HB", name: "Hajdú-Bihar" },
      { code: "HU-HE", name: "Heves" },
      { code: "HU-HV", name: "Hódmezővásárhely" },
      { code: "HU-JN", name: "Jász-Nagykun-Szolnok" },
      { code: "HU-KV", name: "Kaposvár" },
      { code: "HU-KM", name: "Kecskemét" },
      { code: "HU-KE", name: "Komárom-Esztergom" },
      { code: "HU-MI", name: "Miskolc" },
      { code: "HU-NK", name: "Nagykanizsa" },
      { code: "HU-NY", name: "Nyíregyháza" },
      { code: "HU-NO", name: "Nógrád" },
      { code: "HU-PE", name: "Pest" },
      { code: "HU-PS", name: "Pécs" },
      { code: "HU-ST", name: "Salgótarján" },
      { code: "HU-SO", name: "Somogy" },
      { code: "HU-SN", name: "Sopron" },
      { code: "HU-SZ", name: "Szabolcs-Szatmár-Bereg" },
      { code: "HU-SD", name: "Szeged" },
      { code: "HU-SS", name: "Szekszárd" },
      { code: "HU-SK", name: "Szolnok" },
      { code: "HU-SH", name: "Szombathely" },
      { code: "HU-SF", name: "Székesfehérvár" },
      { code: "HU-TB", name: "Tatabánya" },
      { code: "HU-TO", name: "Tolna" },
      { code: "HU-VA", name: "Vas" },
      { code: "HU-VE", name: "Veszprém" },
      { code: "HU-VM", name: "Veszprém" },
      { code: "HU-ZA", name: "Zala" },
      { code: "HU-ZE", name: "Zalaegerszeg" },
      { code: "HU-ER", name: "Érd" },
    ],
  },
  {
    name: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    numeric: "352",
    subdivisions: [
      { code: "IS-7", name: "Austurland" },
      { code: "IS-1", name: "Höfuðborgarsvæði" },
      { code: "IS-6", name: "Norðurland eystra" },
      { code: "IS-5", name: "Norðurland vestra" },
      { code: "IS-8", name: "Suðurland" },
      { code: "IS-2", name: "Suðurnes" },
      { code: "IS-4", name: "Vestfirðir" },
      { code: "IS-3", name: "Vesturland" },
    ],
  },
  {
    name: "India",
    alpha2: "IN",
    alpha3: "IND",
    numeric: "356",
    subdivisions: [
      { code: "IN-AN", name: "Andaman and Nicobar Islands" },
      { code: "IN-AP", name: "Andhra Pradesh" },
      { code: "IN-AR", name: "Arunachal Pradesh" },
      { code: "IN-AS", name: "Assam" },
      { code: "IN-BR", name: "Bihar" },
      { code: "IN-CH", name: "Chandigarh" },
      { code: "IN-CT", name: "Chhattisgarh" },
      { code: "IN-DN", name: "Dadra and Nagar Haveli" },
      { code: "IN-DD", name: "Daman and Diu" },
      { code: "IN-DL", name: "Delhi" },
      { code: "IN-GA", name: "Goa" },
      { code: "IN-GJ", name: "Gujarat" },
      { code: "IN-HR", name: "Haryana" },
      { code: "IN-HP", name: "Himachal Pradesh" },
      { code: "IN-JK", name: "Jammu and Kashmir" },
      { code: "IN-JH", name: "Jharkhand" },
      { code: "IN-KA", name: "Karnataka" },
      { code: "IN-KL", name: "Kerala" },
      { code: "IN-LA", name: "Ladakh" },
      { code: "IN-LD", name: "Lakshadweep" },
      { code: "IN-MP", name: "Madhya Pradesh" },
      { code: "IN-MH", name: "Maharashtra" },
      { code: "IN-MN", name: "Manipur" },
      { code: "IN-ML", name: "Meghalaya" },
      { code: "IN-MZ", name: "Mizoram" },
      { code: "IN-NL", name: "Nagaland" },
      { code: "IN-OR", name: "Odisha" },
      { code: "IN-PY", name: "Puducherry" },
      { code: "IN-PB", name: "Punjab" },
      { code: "IN-RJ", name: "Rajasthan" },
      { code: "IN-SK", name: "Sikkim" },
      { code: "IN-TN", name: "Tamil Nadu" },
      { code: "IN-TG", name: "Telangana" },
      { code: "IN-TR", name: "Tripura" },
      { code: "IN-UP", name: "Uttar Pradesh" },
      { code: "IN-UT", name: "Uttarakhand" },
      { code: "IN-WB", name: "West Bengal" },
    ],
  },
  {
    name: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    numeric: "360",
    subdivisions: [
      { code: "ID-AC", name: "Aceh" },
      { code: "ID-BA", name: "Bali" },
      { code: "ID-BT", name: "Banten" },
      { code: "ID-BE", name: "Bengkulu" },
      { code: "ID-GO", name: "Gorontalo" },
      { code: "ID-JK", name: "Jakarta Raya" },
      { code: "ID-JA", name: "Jambi" },
      { code: "ID-JW", name: "Jawa" },
      { code: "ID-JB", name: "Jawa Barat" },
      { code: "ID-JT", name: "Jawa Tengah" },
      { code: "ID-JI", name: "Jawa Timur" },
      { code: "ID-KA", name: "Kalimantan" },
      { code: "ID-KB", name: "Kalimantan Barat" },
      { code: "ID-KS", name: "Kalimantan Selatan" },
      { code: "ID-KT", name: "Kalimantan Tengah" },
      { code: "ID-KI", name: "Kalimantan Timur" },
      { code: "ID-KU", name: "Kalimantan Utara" },
      { code: "ID-BB", name: "Kepulauan Bangka Belitung" },
      { code: "ID-KR", name: "Kepulauan Riau" },
      { code: "ID-LA", name: "Lampung" },
      { code: "ID-ML", name: "Maluku" },
      { code: "ID-MA", name: "Maluku" },
      { code: "ID-MU", name: "Maluku Utara" },
      { code: "ID-NU", name: "Nusa Tenggara" },
      { code: "ID-NB", name: "Nusa Tenggara Barat" },
      { code: "ID-NT", name: "Nusa Tenggara Timur" },
      { code: "ID-PP", name: "Papua" },
      { code: "ID-PA", name: "Papua" },
      { code: "ID-PB", name: "Papua Barat" },
      { code: "ID-RI", name: "Riau" },
      { code: "ID-SL", name: "Sulawesi" },
      { code: "ID-SR", name: "Sulawesi Barat" },
      { code: "ID-SN", name: "Sulawesi Selatan" },
      { code: "ID-ST", name: "Sulawesi Tengah" },
      { code: "ID-SG", name: "Sulawesi Tenggara" },
      { code: "ID-SA", name: "Sulawesi Utara" },
      { code: "ID-SM", name: "Sumatera" },
      { code: "ID-SB", name: "Sumatera Barat" },
      { code: "ID-SS", name: "Sumatera Selatan" },
      { code: "ID-SU", name: "Sumatera Utara" },
      { code: "ID-YO", name: "Yogyakarta" },
    ],
  },
  {
    name: "Iran (Islamic Republic of)",
    alpha2: "IR",
    alpha3: "IRN",
    numeric: "364",
    subdivisions: [
      { code: "IR-32", name: "Alborz" },
      { code: "IR-03", name: "Ardabīl" },
      { code: "IR-06", name: "Būshehr" },
      { code: "IR-08", name: "Chahār Maḩāl va Bakhtīārī" },
      { code: "IR-04", name: "Eşfahān" },
      { code: "IR-14", name: "Fārs" },
      { code: "IR-27", name: "Golestān" },
      { code: "IR-19", name: "Gīlān" },
      { code: "IR-24", name: "Hamadān" },
      { code: "IR-23", name: "Hormozgān" },
      { code: "IR-15", name: "Kermān" },
      { code: "IR-17", name: "Kermānshāh" },
      { code: "IR-29", name: "Khorāsān-e Jonūbī" },
      { code: "IR-30", name: "Khorāsān-e Raẕavī" },
      { code: "IR-31", name: "Khorāsān-e Shomālī" },
      { code: "IR-10", name: "Khūzestān" },
      { code: "IR-18", name: "Kohgīlūyeh va Bowyer Aḩmad" },
      { code: "IR-16", name: "Kordestān" },
      { code: "IR-20", name: "Lorestān" },
      { code: "IR-22", name: "Markazī" },
      { code: "IR-21", name: "Māzandarān" },
      { code: "IR-28", name: "Qazvīn" },
      { code: "IR-26", name: "Qom" },
      { code: "IR-12", name: "Semnān" },
      { code: "IR-13", name: "Sīstān va Balūchestān" },
      { code: "IR-07", name: "Tehrān" },
      { code: "IR-25", name: "Yazd" },
      { code: "IR-11", name: "Zanjān" },
      { code: "IR-02", name: "Āz̄ārbāyjān-e Ghārbī" },
      { code: "IR-01", name: "Āz̄ārbāyjān-e Shārqī" },
      { code: "IR-05", name: "Īlām" },
    ],
  },
  {
    name: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    numeric: "368",
    subdivisions: [
      { code: "IQ-AN", name: "Al Anbār" },
      { code: "IQ-BA", name: "Al Başrah" },
      { code: "IQ-MU", name: "Al Muthanná" },
      { code: "IQ-QA", name: "Al Qādisīyah" },
      { code: "IQ-NA", name: "An Najaf" },
      { code: "IQ-AR", name: "Arbīl" },
      { code: "IQ-SU", name: "As Sulaymānīyah" },
      { code: "IQ-BG", name: "Baghdād" },
      { code: "IQ-BB", name: "Bābil" },
      { code: "IQ-DA", name: "Dahūk" },
      { code: "IQ-DQ", name: "Dhī Qār" },
      { code: "IQ-DI", name: "Diyālá" },
      { code: "IQ-KA", name: "Karbalā’" },
      { code: "IQ-KI", name: "Kirkūk" },
      { code: "IQ-MA", name: "Maysān" },
      { code: "IQ-NI", name: "Nīnawá" },
      { code: "IQ-WA", name: "Wāsiţ" },
      { code: "IQ-SD", name: "Şalāḩ ad Dīn" },
    ],
  },
  {
    name: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    numeric: "372",
    subdivisions: [
      { code: "IE-CN", name: "An Cabhán" },
      { code: "IE-CE", name: "An Clár" },
      { code: "IE-WH", name: "An Iarmhí" },
      { code: "IE-LD", name: "An Longfort" },
      { code: "IE-M", name: "An Mhumhain" },
      { code: "IE-MH", name: "An Mhí" },
      { code: "IE-D", name: "Baile Átha Cliath" },
      { code: "IE-CW", name: "Carlow" },
      { code: "IE-KY", name: "Ciarraí" },
      { code: "IE-KK", name: "Cill Chainnigh" },
      { code: "IE-KE", name: "Cill Dara" },
      { code: "IE-WW", name: "Cill Mhantáin" },
      { code: "IE-C", name: "Connacht" },
      { code: "IE-CO", name: "Corcaigh" },
      { code: "IE-DL", name: "Donegal" },
      { code: "IE-G", name: "Gaillimh" },
      { code: "IE-L", name: "Laighin" },
      { code: "IE-LS", name: "Laois" },
      { code: "IE-LM", name: "Leitrim" },
      { code: "IE-LK", name: "Limerick" },
      { code: "IE-WX", name: "Loch Garman" },
      { code: "IE-LH", name: "Louth" },
      { code: "IE-MO", name: "Maigh Eo" },
      { code: "IE-MN", name: "Monaghan" },
      { code: "IE-OY", name: "Offaly" },
      { code: "IE-WD", name: "Port Láirge" },
      { code: "IE-RN", name: "Ros Comáin" },
      { code: "IE-SO", name: "Sligeach" },
      { code: "IE-TA", name: "Tiobraid Árann" },
      { code: "IE-U", name: "Ulaidh" },
    ],
  },
  {
    name: "Isle of Man",
    alpha2: "IM",
    alpha3: "IMN",
    numeric: "833",
    subdivisions: [],
  },
  {
    name: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    numeric: "376",
    subdivisions: [
      { code: "IL-M", name: "Al Awsaţ" },
      { code: "IL-D", name: "Al Janūbī" },
      { code: "IL-JM", name: "Al Quds" },
      { code: "IL-Z", name: "Ash Shamālī" },
      { code: "IL-HA", name: "H̱efa" },
      { code: "IL-TA", name: "Tall Abīb" },
    ],
  },
  {
    name: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    numeric: "380",
    subdivisions: [
      { code: "IT-65", name: "Abruzzo" },
      { code: "IT-AG", name: "Agrigento" },
      { code: "IT-AL", name: "Alessandria" },
      { code: "IT-AN", name: "Ancona" },
      { code: "IT-AR", name: "Arezzo" },
      { code: "IT-AP", name: "Ascoli Piceno" },
      { code: "IT-AT", name: "Asti" },
      { code: "IT-AV", name: "Avellino" },
      { code: "IT-BA", name: "Bari" },
      { code: "IT-BT", name: "Barletta-Andria-Trani" },
      { code: "IT-77", name: "Basilicata" },
      { code: "IT-BL", name: "Belluno" },
      { code: "IT-BN", name: "Benevento" },
      { code: "IT-BG", name: "Bergamo" },
      { code: "IT-BI", name: "Biella" },
      { code: "IT-BO", name: "Bologna" },
      { code: "IT-BZ", name: "Bolzano" },
      { code: "IT-BS", name: "Brescia" },
      { code: "IT-BR", name: "Brindisi" },
      { code: "IT-CA", name: "Cagliari" },
      { code: "IT-78", name: "Calabria" },
      { code: "IT-CL", name: "Caltanissetta" },
      { code: "IT-72", name: "Campania" },
      { code: "IT-CB", name: "Campobasso" },
      { code: "IT-CE", name: "Caserta" },
      { code: "IT-CT", name: "Catania" },
      { code: "IT-CZ", name: "Catanzaro" },
      { code: "IT-CH", name: "Chieti" },
      { code: "IT-CO", name: "Como" },
      { code: "IT-CS", name: "Cosenza" },
      { code: "IT-CR", name: "Cremona" },
      { code: "IT-KR", name: "Crotone" },
      { code: "IT-CN", name: "Cuneo" },
      { code: "IT-45", name: "Emilia-Romagna" },
      { code: "IT-EN", name: "Enna" },
      { code: "IT-FM", name: "Fermo" },
      { code: "IT-FE", name: "Ferrara" },
      { code: "IT-FI", name: "Firenze" },
      { code: "IT-FG", name: "Foggia" },
      { code: "IT-FC", name: "Forlì-Cesena" },
      { code: "IT-36", name: "Friuli Venezia Giulia" },
      { code: "IT-FR", name: "Frosinone" },
      { code: "IT-GE", name: "Genova" },
      { code: "IT-GR", name: "Grosseto" },
      { code: "IT-IM", name: "Imperia" },
      { code: "IT-IS", name: "Isernia" },
      { code: "IT-AQ", name: "L'Aquila" },
      { code: "IT-SP", name: "La Spezia" },
      { code: "IT-LT", name: "Latina" },
      { code: "IT-62", name: "Lazio" },
      { code: "IT-LE", name: "Lecce" },
      { code: "IT-LC", name: "Lecco" },
      { code: "IT-42", name: "Liguria" },
      { code: "IT-LI", name: "Livorno" },
      { code: "IT-LO", name: "Lodi" },
      { code: "IT-25", name: "Lombardia" },
      { code: "IT-LU", name: "Lucca" },
      { code: "IT-MC", name: "Macerata" },
      { code: "IT-MN", name: "Mantova" },
      { code: "IT-57", name: "Marche" },
      { code: "IT-MS", name: "Massa-Carrara" },
      { code: "IT-MT", name: "Matera" },
      { code: "IT-ME", name: "Messina" },
      { code: "IT-MI", name: "Milano" },
      { code: "IT-MO", name: "Modena" },
      { code: "IT-67", name: "Molise" },
      { code: "IT-MB", name: "Monza e Brianza" },
      { code: "IT-NA", name: "Napoli" },
      { code: "IT-NO", name: "Novara" },
      { code: "IT-NU", name: "Nuoro" },
      { code: "IT-OR", name: "Oristano" },
      { code: "IT-PD", name: "Padova" },
      { code: "IT-PA", name: "Palermo" },
      { code: "IT-PR", name: "Parma" },
      { code: "IT-PV", name: "Pavia" },
      { code: "IT-PG", name: "Perugia" },
      { code: "IT-PU", name: "Pesaro e Urbino" },
      { code: "IT-PE", name: "Pescara" },
      { code: "IT-PC", name: "Piacenza" },
      { code: "IT-21", name: "Piemonte" },
      { code: "IT-PI", name: "Pisa" },
      { code: "IT-PT", name: "Pistoia" },
      { code: "IT-PZ", name: "Potenza" },
      { code: "IT-PO", name: "Prato" },
      { code: "IT-75", name: "Puglia" },
      { code: "IT-RG", name: "Ragusa" },
      { code: "IT-RA", name: "Ravenna" },
      { code: "IT-RC", name: "Reggio Calabria" },
      { code: "IT-RE", name: "Reggio Emilia" },
      { code: "IT-RI", name: "Rieti" },
      { code: "IT-RN", name: "Rimini" },
      { code: "IT-RM", name: "Roma" },
      { code: "IT-RO", name: "Rovigo" },
      { code: "IT-SA", name: "Salerno" },
      { code: "IT-88", name: "Sardegna" },
      { code: "IT-SS", name: "Sassari" },
      { code: "IT-SV", name: "Savona" },
      { code: "IT-82", name: "Sicilia" },
      { code: "IT-SI", name: "Siena" },
      { code: "IT-SR", name: "Siracusa" },
      { code: "IT-SO", name: "Sondrio" },
      { code: "IT-SD", name: "Sud Sardegna" },
      { code: "IT-TA", name: "Taranto" },
      { code: "IT-TE", name: "Teramo" },
      { code: "IT-TR", name: "Terni" },
      { code: "IT-TO", name: "Torino" },
      { code: "IT-52", name: "Toscana" },
      { code: "IT-TP", name: "Trapani" },
      { code: "IT-32", name: "Trentino-Alto Adige" },
      { code: "IT-TN", name: "Trento" },
      { code: "IT-TV", name: "Treviso" },
      { code: "IT-55", name: "Umbria" },
      { code: "IT-23", name: "Val d'Aoste" },
      { code: "IT-VA", name: "Varese" },
      { code: "IT-34", name: "Veneto" },
      { code: "IT-VE", name: "Venezia" },
      { code: "IT-VB", name: "Verbano-Cusio-Ossola" },
      { code: "IT-VC", name: "Vercelli" },
      { code: "IT-VR", name: "Verona" },
      { code: "IT-VV", name: "Vibo Valentia" },
      { code: "IT-VI", name: "Vicenza" },
      { code: "IT-VT", name: "Viterbo" },
    ],
  },
  {
    name: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    numeric: "388",
    subdivisions: [
      { code: "JM-13", name: "Clarendon" },
      { code: "JM-09", name: "Hanover" },
      { code: "JM-01", name: "Kingston" },
      { code: "JM-12", name: "Manchester" },
      { code: "JM-04", name: "Portland" },
      { code: "JM-02", name: "Saint Andrew" },
      { code: "JM-06", name: "Saint Ann" },
      { code: "JM-14", name: "Saint Catherine" },
      { code: "JM-11", name: "Saint Elizabeth" },
      { code: "JM-08", name: "Saint James" },
      { code: "JM-05", name: "Saint Mary" },
      { code: "JM-03", name: "Saint Thomas" },
      { code: "JM-07", name: "Trelawny" },
      { code: "JM-10", name: "Westmoreland" },
    ],
  },
  {
    name: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    numeric: "392",
    subdivisions: [
      { code: "JP-23", name: "Aichi" },
      { code: "JP-05", name: "Akita" },
      { code: "JP-02", name: "Aomori" },
      { code: "JP-12", name: "Chiba" },
      { code: "JP-38", name: "Ehime" },
      { code: "JP-18", name: "Fukui" },
      { code: "JP-40", name: "Fukuoka" },
      { code: "JP-07", name: "Fukushima" },
      { code: "JP-21", name: "Gifu" },
      { code: "JP-10", name: "Gunma" },
      { code: "JP-34", name: "Hiroshima" },
      { code: "JP-01", name: "Hokkaido" },
      { code: "JP-28", name: "Hyogo" },
      { code: "JP-08", name: "Ibaraki" },
      { code: "JP-17", name: "Ishikawa" },
      { code: "JP-03", name: "Iwate" },
      { code: "JP-37", name: "Kagawa" },
      { code: "JP-46", name: "Kagoshima" },
      { code: "JP-14", name: "Kanagawa" },
      { code: "JP-39", name: "Kochi" },
      { code: "JP-43", name: "Kumamoto" },
      { code: "JP-26", name: "Kyoto" },
      { code: "JP-24", name: "Mie" },
      { code: "JP-04", name: "Miyagi" },
      { code: "JP-45", name: "Miyazaki" },
      { code: "JP-20", name: "Nagano" },
      { code: "JP-42", name: "Nagasaki" },
      { code: "JP-29", name: "Nara" },
      { code: "JP-15", name: "Niigata" },
      { code: "JP-44", name: "Oita" },
      { code: "JP-33", name: "Okayama" },
      { code: "JP-47", name: "Okinawa" },
      { code: "JP-27", name: "Osaka" },
      { code: "JP-41", name: "Saga" },
      { code: "JP-11", name: "Saitama" },
      { code: "JP-25", name: "Shiga" },
      { code: "JP-32", name: "Shimane" },
      { code: "JP-22", name: "Shizuoka" },
      { code: "JP-09", name: "Tochigi" },
      { code: "JP-36", name: "Tokushima" },
      { code: "JP-13", name: "Tokyo" },
      { code: "JP-31", name: "Tottori" },
      { code: "JP-16", name: "Toyama" },
      { code: "JP-30", name: "Wakayama" },
      { code: "JP-06", name: "Yamagata" },
      { code: "JP-35", name: "Yamaguchi" },
      { code: "JP-19", name: "Yamanashi" },
    ],
  },
  {
    name: "Jersey",
    alpha2: "JE",
    alpha3: "JEY",
    numeric: "832",
    subdivisions: [],
  },
  {
    name: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    numeric: "400",
    subdivisions: [
      { code: "JO-BA", name: "Al Balqā’" },
      { code: "JO-KA", name: "Al Karak" },
      { code: "JO-MA", name: "Al Mafraq" },
      { code: "JO-AQ", name: "Al ‘Aqabah" },
      { code: "JO-AM", name: "Al ‘A̅şimah" },
      { code: "JO-AZ", name: "Az Zarqā’" },
      { code: "JO-AT", name: "Aţ Ţafīlah" },
      { code: "JO-IR", name: "Irbid" },
      { code: "JO-JA", name: "Jarash" },
      { code: "JO-MN", name: "Ma‘ān" },
      { code: "JO-MD", name: "Mādabā" },
      { code: "JO-AJ", name: "‘Ajlūn" },
    ],
  },
  {
    name: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    numeric: "398",
    subdivisions: [
      { code: "KZ-AKM", name: "Akmolinskaja oblast'" },
      { code: "KZ-AKT", name: "Aktjubinskaja oblast'" },
      { code: "KZ-ALM", name: "Almatinskaja oblast'" },
      { code: "KZ-ALA", name: "Almaty" },
      { code: "KZ-AST", name: "Astana" },
      { code: "KZ-ATY", name: "Atyrauskaja oblast'" },
      { code: "KZ-BAY", name: "Bajkonyr" },
      { code: "KZ-ZAP", name: "Batys Qazaqstan oblysy" },
      { code: "KZ-KAR", name: "Karagandinskaja oblast'" },
      { code: "KZ-KUS", name: "Kostanajskaja oblast'" },
      { code: "KZ-KZY", name: "Kyzylordinskaja oblast'" },
      { code: "KZ-MAN", name: "Mangghystaū oblysy" },
      { code: "KZ-PAV", name: "Pavlodar oblysy" },
      { code: "KZ-SEV", name: "Severo-Kazahstanskaja oblast'" },
      { code: "KZ-VOS", name: "Shyghys Qazaqstan oblysy" },
      { code: "KZ-SHY", name: "Shymkent" },
      { code: "KZ-YUZ", name: "Turkestankaya oblast'" },
      { code: "KZ-ZHA", name: "Zhambyl oblysy" },
    ],
  },
  {
    name: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    numeric: "404",
    subdivisions: [
      { code: "KE-01", name: "Baringo" },
      { code: "KE-02", name: "Bomet" },
      { code: "KE-03", name: "Bungoma" },
      { code: "KE-04", name: "Busia" },
      { code: "KE-05", name: "Elgeyo/Marakwet" },
      { code: "KE-06", name: "Embu" },
      { code: "KE-07", name: "Garissa" },
      { code: "KE-08", name: "Homa Bay" },
      { code: "KE-09", name: "Isiolo" },
      { code: "KE-10", name: "Kajiado" },
      { code: "KE-11", name: "Kakamega" },
      { code: "KE-12", name: "Kericho" },
      { code: "KE-13", name: "Kiambu" },
      { code: "KE-14", name: "Kilifi" },
      { code: "KE-15", name: "Kirinyaga" },
      { code: "KE-16", name: "Kisii" },
      { code: "KE-17", name: "Kisumu" },
      { code: "KE-18", name: "Kitui" },
      { code: "KE-19", name: "Kwale" },
      { code: "KE-20", name: "Laikipia" },
      { code: "KE-21", name: "Lamu" },
      { code: "KE-22", name: "Machakos" },
      { code: "KE-23", name: "Makueni" },
      { code: "KE-24", name: "Mandera" },
      { code: "KE-25", name: "Marsabit" },
      { code: "KE-26", name: "Meru" },
      { code: "KE-27", name: "Migori" },
      { code: "KE-28", name: "Mombasa" },
      { code: "KE-29", name: "Murang'a" },
      { code: "KE-30", name: "Nairobi City" },
      { code: "KE-31", name: "Nakuru" },
      { code: "KE-32", name: "Nandi" },
      { code: "KE-33", name: "Narok" },
      { code: "KE-34", name: "Nyamira" },
      { code: "KE-35", name: "Nyandarua" },
      { code: "KE-36", name: "Nyeri" },
      { code: "KE-37", name: "Samburu" },
      { code: "KE-38", name: "Siaya" },
      { code: "KE-39", name: "Taita/Taveta" },
      { code: "KE-40", name: "Tana River" },
      { code: "KE-41", name: "Tharaka-Nithi" },
      { code: "KE-42", name: "Trans Nzoia" },
      { code: "KE-43", name: "Turkana" },
      { code: "KE-44", name: "Uasin Gishu" },
      { code: "KE-45", name: "Vihiga" },
      { code: "KE-46", name: "Wajir" },
      { code: "KE-47", name: "West Pokot" },
    ],
  },
  {
    name: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    numeric: "296",
    subdivisions: [
      { code: "KI-G", name: "Gilbert Islands" },
      { code: "KI-L", name: "Line Islands" },
      { code: "KI-P", name: "Phoenix Islands" },
    ],
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    alpha2: "KP",
    alpha3: "PRK",
    numeric: "408",
    subdivisions: [
      { code: "KP-04", name: "Chagang-do" },
      { code: "KP-09", name: "Hamgyǒng-bukto" },
      { code: "KP-08", name: "Hamgyǒng-namdo" },
      { code: "KP-06", name: "Hwanghae-bukto" },
      { code: "KP-05", name: "Hwanghae-namdo" },
      { code: "KP-07", name: "Kangweonto" },
      { code: "KP-14", name: "Nampho" },
      { code: "KP-03", name: "P'yǒngan-bukto" },
      { code: "KP-02", name: "P'yǒngan-namdo" },
      { code: "KP-01", name: "P'yǒngyang" },
      { code: "KP-13", name: "Raseon" },
      { code: "KP-10", name: "Ryanggang-do" },
    ],
  },
  {
    name: "Korea (the Republic of)",
    alpha2: "KR",
    alpha3: "KOR",
    numeric: "410",
    subdivisions: [
      { code: "KR-26", name: "Busan-gwangyeoksi" },
      { code: "KR-43", name: "Chungcheongbuk-do" },
      { code: "KR-44", name: "Chungcheongnam-do" },
      { code: "KR-27", name: "Daegu-gwangyeoksi" },
      { code: "KR-30", name: "Daejeon-gwangyeoksi" },
      { code: "KR-42", name: "Gangwon-do" },
      { code: "KR-29", name: "Gwangju-gwangyeoksi" },
      { code: "KR-41", name: "Gyeonggi-do" },
      { code: "KR-47", name: "Gyeongsangbuk-do" },
      { code: "KR-48", name: "Gyeongsangnam-do" },
      { code: "KR-28", name: "Incheon-gwangyeoksi" },
      { code: "KR-49", name: "Jeju-teukbyeoljachido" },
      { code: "KR-45", name: "Jeollabuk-do" },
      { code: "KR-46", name: "Jeollanam-do" },
      { code: "KR-50", name: "Sejong" },
      { code: "KR-11", name: "Seoul-teukbyeolsi" },
      { code: "KR-31", name: "Ulsan-gwangyeoksi" },
    ],
  },
  {
    name: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    numeric: "414",
    subdivisions: [
      { code: "KW-AH", name: "Al Aḩmadī" },
      { code: "KW-FA", name: "Al Farwānīyah" },
      { code: "KW-JA", name: "Al Jahrā’" },
      { code: "KW-KU", name: "Al ‘Āşimah" },
      { code: "KW-MU", name: "Mubārak al Kabīr" },
      { code: "KW-HA", name: "Ḩawallī" },
    ],
  },
  {
    name: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    numeric: "417",
    subdivisions: [
      { code: "KG-B", name: "Batken" },
      { code: "KG-GB", name: "Bishkek" },
      { code: "KG-C", name: "Chuyskaya oblast'" },
      { code: "KG-J", name: "Dzhalal-Abadskaya oblast'" },
      { code: "KG-GO", name: "Gorod Osh" },
      { code: "KG-Y", name: "Issyk-Kul'skaja oblast'" },
      { code: "KG-N", name: "Naryn" },
      { code: "KG-O", name: "Osh" },
      { code: "KG-T", name: "Talas" },
    ],
  },
  {
    name: "Lao People's Democratic Republic (the)",
    alpha2: "LA",
    alpha3: "LAO",
    numeric: "418",
    subdivisions: [
      { code: "LA-AT", name: "Attapu" },
      { code: "LA-BK", name: "Bokèo" },
      { code: "LA-BL", name: "Bolikhamxai" },
      { code: "LA-CH", name: "Champasak" },
      { code: "LA-HO", name: "Houaphan" },
      { code: "LA-KH", name: "Khammouan" },
      { code: "LA-LM", name: "Louang Namtha" },
      { code: "LA-LP", name: "Louangphabang" },
      { code: "LA-OU", name: "Oudômxai" },
      { code: "LA-PH", name: "Phôngsali" },
      { code: "LA-SL", name: "Salavan" },
      { code: "LA-SV", name: "Savannakhét" },
      { code: "LA-VT", name: "Viangchan" },
      { code: "LA-VI", name: "Viangchan" },
      { code: "LA-XA", name: "Xaignabouli" },
      { code: "LA-XS", name: "Xaisômboun" },
      { code: "LA-XI", name: "Xiangkhouang" },
      { code: "LA-XE", name: "Xékong" },
    ],
  },
  {
    name: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    numeric: "428",
    subdivisions: [
      { code: "LV-001", name: "Aglonas novads" },
      { code: "LV-002", name: "Aizkraukles novads" },
      { code: "LV-003", name: "Aizputes novads" },
      { code: "LV-004", name: "Aknīstes novads" },
      { code: "LV-005", name: "Alojas novads" },
      { code: "LV-006", name: "Alsungas novads" },
      { code: "LV-007", name: "Alūksnes novads" },
      { code: "LV-008", name: "Amatas novads" },
      { code: "LV-009", name: "Apes novads" },
      { code: "LV-010", name: "Auces novads" },
      { code: "LV-012", name: "Babītes novads" },
      { code: "LV-013", name: "Baldones novads" },
      { code: "LV-014", name: "Baltinavas novads" },
      { code: "LV-015", name: "Balvu novads" },
      { code: "LV-016", name: "Bauskas novads" },
      { code: "LV-017", name: "Beverīnas novads" },
      { code: "LV-018", name: "Brocēnu novads" },
      { code: "LV-019", name: "Burtnieku novads" },
      { code: "LV-020", name: "Carnikavas novads" },
      { code: "LV-021", name: "Cesvaines novads" },
      { code: "LV-023", name: "Ciblas novads" },
      { code: "LV-022", name: "Cēsu novads" },
      { code: "LV-024", name: "Dagdas novads" },
      { code: "LV-DGV", name: "Daugavpils" },
      { code: "LV-025", name: "Daugavpils novads" },
      { code: "LV-026", name: "Dobeles novads" },
      { code: "LV-027", name: "Dundagas novads" },
      { code: "LV-028", name: "Durbes novads" },
      { code: "LV-029", name: "Engures novads" },
      { code: "LV-031", name: "Garkalnes novads" },
      { code: "LV-032", name: "Grobiņas novads" },
      { code: "LV-033", name: "Gulbenes novads" },
      { code: "LV-034", name: "Iecavas novads" },
      { code: "LV-035", name: "Ikšķiles novads" },
      { code: "LV-036", name: "Ilūkstes novads" },
      { code: "LV-037", name: "Inčukalna novads" },
      { code: "LV-038", name: "Jaunjelgavas novads" },
      { code: "LV-039", name: "Jaunpiebalgas novads" },
      { code: "LV-040", name: "Jaunpils novads" },
      { code: "LV-JEL", name: "Jelgava" },
      { code: "LV-041", name: "Jelgavas novads" },
      { code: "LV-JKB", name: "Jēkabpils" },
      { code: "LV-042", name: "Jēkabpils novads" },
      { code: "LV-JUR", name: "Jūrmala" },
      { code: "LV-043", name: "Kandavas novads" },
      { code: "LV-045", name: "Kocēnu novads" },
      { code: "LV-046", name: "Kokneses novads" },
      { code: "LV-048", name: "Krimuldas novads" },
      { code: "LV-049", name: "Krustpils novads" },
      { code: "LV-047", name: "Krāslavas novads" },
      { code: "LV-050", name: "Kuldīgas novads" },
      { code: "LV-044", name: "Kārsavas novads" },
      { code: "LV-053", name: "Lielvārdes novads" },
      { code: "LV-LPX", name: "Liepāja" },
      { code: "LV-054", name: "Limbažu novads" },
      { code: "LV-057", name: "Lubānas novads" },
      { code: "LV-058", name: "Ludzas novads" },
      { code: "LV-055", name: "Līgatnes novads" },
      { code: "LV-056", name: "Līvānu novads" },
      { code: "LV-059", name: "Madonas novads" },
      { code: "LV-060", name: "Mazsalacas novads" },
      { code: "LV-061", name: "Mālpils novads" },
      { code: "LV-062", name: "Mārupes novads" },
      { code: "LV-063", name: "Mērsraga novads" },
      { code: "LV-064", name: "Naukšēnu novads" },
      { code: "LV-065", name: "Neretas novads" },
      { code: "LV-066", name: "Nīcas novads" },
      { code: "LV-067", name: "Ogres novads" },
      { code: "LV-068", name: "Olaines novads" },
      { code: "LV-069", name: "Ozolnieku novads" },
      { code: "LV-073", name: "Preiļu novads" },
      { code: "LV-074", name: "Priekules novads" },
      { code: "LV-075", name: "Priekuļu novads" },
      { code: "LV-070", name: "Pārgaujas novads" },
      { code: "LV-071", name: "Pāvilostas novads" },
      { code: "LV-072", name: "Pļaviņu novads" },
      { code: "LV-076", name: "Raunas novads" },
      { code: "LV-078", name: "Riebiņu novads" },
      { code: "LV-079", name: "Rojas novads" },
      { code: "LV-080", name: "Ropažu novads" },
      { code: "LV-081", name: "Rucavas novads" },
      { code: "LV-082", name: "Rugāju novads" },
      { code: "LV-083", name: "Rundāles novads" },
      { code: "LV-REZ", name: "Rēzekne" },
      { code: "LV-077", name: "Rēzeknes novads" },
      { code: "LV-RIX", name: "Rīga" },
      { code: "LV-084", name: "Rūjienas novads" },
      { code: "LV-086", name: "Salacgrīvas novads" },
      { code: "LV-085", name: "Salas novads" },
      { code: "LV-087", name: "Salaspils novads" },
      { code: "LV-088", name: "Saldus novads" },
      { code: "LV-089", name: "Saulkrastu novads" },
      { code: "LV-091", name: "Siguldas novads" },
      { code: "LV-093", name: "Skrundas novads" },
      { code: "LV-092", name: "Skrīveru novads" },
      { code: "LV-094", name: "Smiltenes novads" },
      { code: "LV-095", name: "Stopiņu novads" },
      { code: "LV-096", name: "Strenču novads" },
      { code: "LV-090", name: "Sējas novads" },
      { code: "LV-097", name: "Talsu novads" },
      { code: "LV-099", name: "Tukuma novads" },
      { code: "LV-098", name: "Tērvetes novads" },
      { code: "LV-100", name: "Vaiņodes novads" },
      { code: "LV-101", name: "Valkas novads" },
      { code: "LV-VMR", name: "Valmiera" },
      { code: "LV-102", name: "Varakļānu novads" },
      { code: "LV-104", name: "Vecpiebalgas novads" },
      { code: "LV-105", name: "Vecumnieku novads" },
      { code: "LV-VEN", name: "Ventspils" },
      { code: "LV-106", name: "Ventspils novads" },
      { code: "LV-107", name: "Viesītes novads" },
      { code: "LV-108", name: "Viļakas novads" },
      { code: "LV-109", name: "Viļānu novads" },
      { code: "LV-103", name: "Vārkavas novads" },
      { code: "LV-110", name: "Zilupes novads" },
      { code: "LV-011", name: "Ādažu novads" },
      { code: "LV-030", name: "Ērgļu novads" },
      { code: "LV-051", name: "Ķeguma novads" },
      { code: "LV-052", name: "Ķekavas novads" },
    ],
  },
  {
    name: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    numeric: "422",
    subdivisions: [
      { code: "LB-AK", name: "Aakkâr" },
      { code: "LB-BI", name: "Al Biqā‘" },
      { code: "LB-JA", name: "Al Janūb" },
      { code: "LB-NA", name: "An Nabaţīyah" },
      { code: "LB-AS", name: "Ash Shimāl" },
      { code: "LB-BH", name: "Baalbek-Hermel" },
      { code: "LB-BA", name: "Bayrūt" },
      { code: "LB-JL", name: "Jabal Lubnān" },
    ],
  },
  {
    name: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    numeric: "426",
    subdivisions: [
      { code: "LS-D", name: "Berea" },
      { code: "LS-B", name: "Butha-Buthe" },
      { code: "LS-C", name: "Leribe" },
      { code: "LS-E", name: "Mafeteng" },
      { code: "LS-A", name: "Maseru" },
      { code: "LS-F", name: "Mohale's Hoek" },
      { code: "LS-J", name: "Mokhotlong" },
      { code: "LS-H", name: "Qacha's Nek" },
      { code: "LS-G", name: "Quthing" },
      { code: "LS-K", name: "Thaba-Tseka" },
    ],
  },
  {
    name: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    numeric: "430",
    subdivisions: [
      { code: "LR-BM", name: "Bomi" },
      { code: "LR-BG", name: "Bong" },
      { code: "LR-GP", name: "Gbarpolu" },
      { code: "LR-GB", name: "Grand Bassa" },
      { code: "LR-CM", name: "Grand Cape Mount" },
      { code: "LR-GG", name: "Grand Gedeh" },
      { code: "LR-GK", name: "Grand Kru" },
      { code: "LR-LO", name: "Lofa" },
      { code: "LR-MG", name: "Margibi" },
      { code: "LR-MY", name: "Maryland" },
      { code: "LR-MO", name: "Montserrado" },
      { code: "LR-NI", name: "Nimba" },
      { code: "LR-RI", name: "River Cess" },
      { code: "LR-RG", name: "River Gee" },
      { code: "LR-SI", name: "Sinoe" },
    ],
  },
  {
    name: "Libya",
    alpha2: "LY",
    alpha3: "LBY",
    numeric: "434",
    subdivisions: [
      { code: "LY-BU", name: "Al Buţnān" },
      { code: "LY-JA", name: "Al Jabal al Akhḑar" },
      { code: "LY-JG", name: "Al Jabal al Gharbī" },
      { code: "LY-JI", name: "Al Jafārah" },
      { code: "LY-JU", name: "Al Jufrah" },
      { code: "LY-KF", name: "Al Kufrah" },
      { code: "LY-MJ", name: "Al Marj" },
      { code: "LY-MB", name: "Al Marqab" },
      { code: "LY-WA", name: "Al Wāḩāt" },
      { code: "LY-NQ", name: "An Nuqāţ al Khams" },
      { code: "LY-ZA", name: "Az Zāwiyah" },
      { code: "LY-BA", name: "Banghāzī" },
      { code: "LY-DR", name: "Darnah" },
      { code: "LY-GT", name: "Ghāt" },
      { code: "LY-MI", name: "Mişrātah" },
      { code: "LY-MQ", name: "Murzuq" },
      { code: "LY-NL", name: "Nālūt" },
      { code: "LY-SB", name: "Sabhā" },
      { code: "LY-SR", name: "Surt" },
      { code: "LY-WD", name: "Wādī al Ḩayāt" },
      { code: "LY-WS", name: "Wādī ash Shāţi’" },
      { code: "LY-TB", name: "Ţarābulus" },
    ],
  },
  {
    name: "Liechtenstein",
    alpha2: "LI",
    alpha3: "LIE",
    numeric: "438",
    subdivisions: [
      { code: "LI-01", name: "Balzers" },
      { code: "LI-02", name: "Eschen" },
      { code: "LI-03", name: "Gamprin" },
      { code: "LI-04", name: "Mauren" },
      { code: "LI-05", name: "Planken" },
      { code: "LI-06", name: "Ruggell" },
      { code: "LI-07", name: "Schaan" },
      { code: "LI-08", name: "Schellenberg" },
      { code: "LI-09", name: "Triesen" },
      { code: "LI-10", name: "Triesenberg" },
      { code: "LI-11", name: "Vaduz" },
    ],
  },
  {
    name: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    numeric: "440",
    subdivisions: [
      { code: "LT-01", name: "Akmenė" },
      { code: "LT-AL", name: "Alytaus apskritis" },
      { code: "LT-02", name: "Alytaus miestas" },
      { code: "LT-03", name: "Alytus" },
      { code: "LT-04", name: "Anykščiai" },
      { code: "LT-05", name: "Birštono" },
      { code: "LT-06", name: "Biržai" },
      { code: "LT-07", name: "Druskininkai" },
      { code: "LT-08", name: "Elektrėnai" },
      { code: "LT-09", name: "Ignalina" },
      { code: "LT-10", name: "Jonava" },
      { code: "LT-11", name: "Joniškis" },
      { code: "LT-12", name: "Jurbarkas" },
      { code: "LT-13", name: "Kaišiadorys" },
      { code: "LT-14", name: "Kalvarijos" },
      { code: "LT-16", name: "Kaunas" },
      { code: "LT-KU", name: "Kauno apskritis" },
      { code: "LT-15", name: "Kauno miestas" },
      { code: "LT-17", name: "Kazlų Rūdos" },
      { code: "LT-19", name: "Kelmė" },
      { code: "LT-21", name: "Klaipėda" },
      { code: "LT-KL", name: "Klaipėdos apskritis" },
      { code: "LT-20", name: "Klaipėdos miestas" },
      { code: "LT-22", name: "Kretinga" },
      { code: "LT-23", name: "Kupiškis" },
      { code: "LT-18", name: "Kėdainiai" },
      { code: "LT-24", name: "Lazdijai" },
      { code: "LT-25", name: "Marijampolė" },
      { code: "LT-MR", name: "Marijampolės apskritis" },
      { code: "LT-26", name: "Mažeikiai" },
      { code: "LT-27", name: "Molėtai" },
      { code: "LT-28", name: "Neringa" },
      { code: "LT-29", name: "Pagėgiai" },
      { code: "LT-30", name: "Pakruojis" },
      { code: "LT-31", name: "Palangos miestas" },
      { code: "LT-PN", name: "Panevėžio apskritis" },
      { code: "LT-32", name: "Panevėžio miestas" },
      { code: "LT-33", name: "Panevėžys" },
      { code: "LT-34", name: "Pasvalys" },
      { code: "LT-35", name: "Plungė" },
      { code: "LT-36", name: "Prienai" },
      { code: "LT-37", name: "Radviliškis" },
      { code: "LT-38", name: "Raseiniai" },
      { code: "LT-39", name: "Rietavo" },
      { code: "LT-40", name: "Rokiškis" },
      { code: "LT-48", name: "Skuodas" },
      { code: "LT-50", name: "Tauragė" },
      { code: "LT-TA", name: "Tauragės apskritis" },
      { code: "LT-51", name: "Telšiai" },
      { code: "LT-TE", name: "Telšių apskritis" },
      { code: "LT-52", name: "Trakai" },
      { code: "LT-53", name: "Ukmergė" },
      { code: "LT-54", name: "Utena" },
      { code: "LT-UT", name: "Utenos apskritis" },
      { code: "LT-55", name: "Varėna" },
      { code: "LT-56", name: "Vilkaviškis" },
      { code: "LT-VL", name: "Vilniaus apskritis" },
      { code: "LT-57", name: "Vilniaus miestas" },
      { code: "LT-58", name: "Vilnius" },
      { code: "LT-59", name: "Visaginas" },
      { code: "LT-60", name: "Zarasai" },
      { code: "LT-41", name: "Šakiai" },
      { code: "LT-42", name: "Šalčininkai" },
      { code: "LT-44", name: "Šiauliai" },
      { code: "LT-SA", name: "Šiaulių apskritis" },
      { code: "LT-43", name: "Šiaulių miestas" },
      { code: "LT-45", name: "Šilalė" },
      { code: "LT-46", name: "Šilutė" },
      { code: "LT-47", name: "Širvintos" },
      { code: "LT-49", name: "Švenčionys" },
    ],
  },
  {
    name: "Luxembourg",
    alpha2: "LU",
    alpha3: "LUX",
    numeric: "442",
    subdivisions: [
      { code: "LU-CA", name: "Capellen" },
      { code: "LU-CL", name: "Clerf" },
      { code: "LU-DI", name: "Diekirch" },
      { code: "LU-EC", name: "Echternach" },
      { code: "LU-ES", name: "Esch an der Alzette" },
      { code: "LU-GR", name: "Grevenmacher" },
      { code: "LU-LU", name: "Luxembourg" },
      { code: "LU-ME", name: "Mersch" },
      { code: "LU-RD", name: "Redange" },
      { code: "LU-RM", name: "Remich" },
      { code: "LU-VD", name: "Veianen" },
      { code: "LU-WI", name: "Wiltz" },
    ],
  },
  {
    name: "Macao",
    alpha2: "MO",
    alpha3: "MAC",
    numeric: "446",
    subdivisions: [],
  },
  {
    name: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    numeric: "450",
    subdivisions: [
      { code: "MG-T", name: "Antananarivo" },
      { code: "MG-D", name: "Antsiranana" },
      { code: "MG-F", name: "Fianarantsoa" },
      { code: "MG-M", name: "Mahajanga" },
      { code: "MG-A", name: "Toamasina" },
      { code: "MG-U", name: "Toliara" },
    ],
  },
  {
    name: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    numeric: "454",
    subdivisions: [
      { code: "MW-BA", name: "Balaka" },
      { code: "MW-BL", name: "Blantyre" },
      { code: "MW-C", name: "Central Region" },
      { code: "MW-N", name: "Chakumpoto" },
      { code: "MW-S", name: "Chakumwera" },
      { code: "MW-CK", name: "Chikwawa" },
      { code: "MW-CR", name: "Chiradzulu" },
      { code: "MW-CT", name: "Chitipa" },
      { code: "MW-DE", name: "Dedza" },
      { code: "MW-DO", name: "Dowa" },
      { code: "MW-KR", name: "Karonga" },
      { code: "MW-KS", name: "Kasungu" },
      { code: "MW-LK", name: "Likoma" },
      { code: "MW-LI", name: "Lilongwe" },
      { code: "MW-MH", name: "Machinga" },
      { code: "MW-MG", name: "Mangochi" },
      { code: "MW-MC", name: "Mchinji" },
      { code: "MW-MU", name: "Mulanje" },
      { code: "MW-MW", name: "Mwanza" },
      { code: "MW-MZ", name: "Mzimba" },
      { code: "MW-NE", name: "Neno" },
      { code: "MW-NB", name: "Nkhata Bay" },
      { code: "MW-NK", name: "Nkhotakota" },
      { code: "MW-NS", name: "Nsanje" },
      { code: "MW-NU", name: "Ntcheu" },
      { code: "MW-NI", name: "Ntchisi" },
      { code: "MW-PH", name: "Phalombe" },
      { code: "MW-RU", name: "Rumphi" },
      { code: "MW-SA", name: "Salima" },
      { code: "MW-TH", name: "Thyolo" },
      { code: "MW-ZO", name: "Zomba" },
    ],
  },
  {
    name: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    numeric: "458",
    subdivisions: [
      { code: "MY-01", name: "Johor" },
      { code: "MY-02", name: "Kedah" },
      { code: "MY-03", name: "Kelantan" },
      { code: "MY-04", name: "Melaka" },
      { code: "MY-05", name: "Negeri Sembilan" },
      { code: "MY-06", name: "Pahang" },
      { code: "MY-08", name: "Perak" },
      { code: "MY-09", name: "Perlis" },
      { code: "MY-07", name: "Pulau Pinang" },
      { code: "MY-12", name: "Sabah" },
      { code: "MY-13", name: "Sarawak" },
      { code: "MY-10", name: "Selangor" },
      { code: "MY-11", name: "Terengganu" },
      { code: "MY-14", name: "Wilayah Persekutuan Kuala Lumpur" },
      { code: "MY-15", name: "Wilayah Persekutuan Labuan" },
      { code: "MY-16", name: "Wilayah Persekutuan Putrajaya" },
    ],
  },
  {
    name: "Maldives",
    alpha2: "MV",
    alpha3: "MDV",
    numeric: "462",
    subdivisions: [
      { code: "MV-01", name: "Addu" },
      { code: "MV-00", name: "Ariatholhu Dhekunuburi" },
      { code: "MV-02", name: "Ariatholhu Uthuruburi" },
      { code: "MV-03", name: "Faadhippolhu" },
      { code: "MV-04", name: "Felidheatholhu" },
      { code: "MV-29", name: "Fuvammulah" },
      { code: "MV-05", name: "Hahdhunmathi" },
      { code: "MV-28", name: "Huvadhuatholhu Dhekunuburi" },
      { code: "MV-27", name: "Huvadhuatholhu Uthuruburi" },
      { code: "MV-08", name: "Kolhumadulu" },
      { code: "MV-MLE", name: "Maale" },
      { code: "MV-26", name: "Maaleatholhu" },
      { code: "MV-20", name: "Maalhosmadulu Dhekunuburi" },
      { code: "MV-13", name: "Maalhosmadulu Uthuruburi" },
      { code: "MV-25", name: "Miladhunmadulu Dhekunuburi" },
      { code: "MV-24", name: "Miladhunmadulu Uthuruburi" },
      { code: "MV-12", name: "Mulakatholhu" },
      { code: "MV-17", name: "Nilandheatholhu Dhekunuburi" },
      { code: "MV-14", name: "Nilandheatholhu Uthuruburi" },
      { code: "MV-07", name: "North Thiladhunmathi" },
      { code: "MV-23", name: "South Thiladhunmathi" },
    ],
  },
  {
    name: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    numeric: "466",
    subdivisions: [
      { code: "ML-BKO", name: "Bamako" },
      { code: "ML-7", name: "Gao" },
      { code: "ML-1", name: "Kayes" },
      { code: "ML-8", name: "Kidal" },
      { code: "ML-2", name: "Koulikoro" },
      { code: "ML-5", name: "Mopti" },
      { code: "ML-9", name: "Ménaka" },
      { code: "ML-3", name: "Sikasso" },
      { code: "ML-4", name: "Ségou" },
      { code: "ML-10", name: "Taoudénit" },
      { code: "ML-6", name: "Tombouctou" },
    ],
  },
  {
    name: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    numeric: "470",
    subdivisions: [
      { code: "MT-01", name: "Attard" },
      { code: "MT-02", name: "Balzan" },
      { code: "MT-03", name: "Birgu" },
      { code: "MT-04", name: "Birkirkara" },
      { code: "MT-05", name: "Birżebbuġa" },
      { code: "MT-06", name: "Bormla" },
      { code: "MT-07", name: "Dingli" },
      { code: "MT-08", name: "Fgura" },
      { code: "MT-09", name: "Floriana" },
      { code: "MT-10", name: "Fontana" },
      { code: "MT-11", name: "Gudja" },
      { code: "MT-13", name: "Għajnsielem" },
      { code: "MT-14", name: "Għarb" },
      { code: "MT-15", name: "Għargħur" },
      { code: "MT-16", name: "Għasri" },
      { code: "MT-17", name: "Għaxaq" },
      { code: "MT-12", name: "Gżira" },
      { code: "MT-19", name: "Iklin" },
      { code: "MT-20", name: "Isla" },
      { code: "MT-21", name: "Kalkara" },
      { code: "MT-22", name: "Kerċem" },
      { code: "MT-23", name: "Kirkop" },
      { code: "MT-24", name: "Lija" },
      { code: "MT-25", name: "Luqa" },
      { code: "MT-26", name: "Marsa" },
      { code: "MT-27", name: "Marsaskala" },
      { code: "MT-28", name: "Marsaxlokk" },
      { code: "MT-29", name: "Mdina" },
      { code: "MT-30", name: "Mellieħa" },
      { code: "MT-32", name: "Mosta" },
      { code: "MT-33", name: "Mqabba" },
      { code: "MT-34", name: "Msida" },
      { code: "MT-35", name: "Mtarfa" },
      { code: "MT-36", name: "Munxar" },
      { code: "MT-31", name: "Mġarr" },
      { code: "MT-37", name: "Nadur" },
      { code: "MT-38", name: "Naxxar" },
      { code: "MT-39", name: "Paola" },
      { code: "MT-40", name: "Pembroke" },
      { code: "MT-41", name: "Pietà" },
      { code: "MT-42", name: "Qala" },
      { code: "MT-43", name: "Qormi" },
      { code: "MT-44", name: "Qrendi" },
      { code: "MT-45", name: "Rabat Gozo" },
      { code: "MT-46", name: "Rabat Malta" },
      { code: "MT-47", name: "Safi" },
      { code: "MT-49", name: "Saint John" },
      { code: "MT-48", name: "Saint Julian's" },
      { code: "MT-50", name: "Saint Lawrence" },
      { code: "MT-53", name: "Saint Lucia's" },
      { code: "MT-51", name: "Saint Paul's Bay" },
      { code: "MT-52", name: "Sannat" },
      { code: "MT-54", name: "Santa Venera" },
      { code: "MT-55", name: "Siġġiewi" },
      { code: "MT-56", name: "Sliema" },
      { code: "MT-57", name: "Swieqi" },
      { code: "MT-58", name: "Ta' Xbiex" },
      { code: "MT-59", name: "Tarxien" },
      { code: "MT-60", name: "Valletta" },
      { code: "MT-61", name: "Xagħra" },
      { code: "MT-62", name: "Xewkija" },
      { code: "MT-63", name: "Xgħajra" },
      { code: "MT-18", name: "Ħamrun" },
      { code: "MT-64", name: "Żabbar" },
      { code: "MT-65", name: "Żebbuġ Gozo" },
      { code: "MT-66", name: "Żebbuġ Malta" },
      { code: "MT-67", name: "Żejtun" },
      { code: "MT-68", name: "Żurrieq" },
    ],
  },
  {
    name: "Marshall Islands (the)",
    alpha2: "MH",
    alpha3: "MHL",
    numeric: "584",
    subdivisions: [
      { code: "MH-ALK", name: "Aelok" },
      { code: "MH-ALL", name: "Aelōn̄ḷapḷap" },
      { code: "MH-ARN", name: "Arno" },
      { code: "MH-AUR", name: "Aur" },
      { code: "MH-KIL", name: "Bikini & Kili" },
      { code: "MH-EBO", name: "Ebon" },
      { code: "MH-LIB", name: "Ellep" },
      { code: "MH-ENI", name: "Enewetak & Ujelang" },
      { code: "MH-JAB", name: "Jabat" },
      { code: "MH-JAL", name: "Jaluit" },
      { code: "MH-KWA", name: "Kuwajleen" },
      { code: "MH-LAE", name: "Lae" },
      { code: "MH-LIK", name: "Likiep" },
      { code: "MH-MAJ", name: "Majuro" },
      { code: "MH-MAL", name: "Maloelap" },
      { code: "MH-MEJ", name: "Mejit" },
      { code: "MH-MIL", name: "Mile" },
      { code: "MH-NMK", name: "Namdrik" },
      { code: "MH-NMU", name: "Namu" },
      { code: "MH-L", name: "Ralik chain" },
      { code: "MH-T", name: "Ratak chain" },
      { code: "MH-RON", name: "Rongelap" },
      { code: "MH-UJA", name: "Ujae" },
      { code: "MH-UTI", name: "Utrik" },
      { code: "MH-WTH", name: "Wotho" },
      { code: "MH-WTJ", name: "Wotje" },
    ],
  },
  {
    name: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    numeric: "474",
    subdivisions: [],
  },
  {
    name: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    numeric: "478",
    subdivisions: [
      { code: "MR-07", name: "Adrar" },
      { code: "MR-03", name: "Assaba" },
      { code: "MR-05", name: "Brakna" },
      { code: "MR-08", name: "Dakhlet Nouâdhibou" },
      { code: "MR-04", name: "Gorgol" },
      { code: "MR-10", name: "Guidimaka" },
      { code: "MR-01", name: "Hodh ech Chargui" },
      { code: "MR-02", name: "Hodh el Gharbi" },
      { code: "MR-12", name: "Inchiri" },
      { code: "MR-14", name: "Nouakchott Nord" },
      { code: "MR-13", name: "Nouakchott Ouest" },
      { code: "MR-15", name: "Nouakchott Sud" },
      { code: "MR-09", name: "Tagant" },
      { code: "MR-11", name: "Tiris Zemmour" },
      { code: "MR-06", name: "Trarza" },
    ],
  },
  {
    name: "Mauritius",
    alpha2: "MU",
    alpha3: "MUS",
    numeric: "480",
    subdivisions: [
      { code: "MU-AG", name: "Agalega Islands" },
      { code: "MU-BR", name: "Beau Bassin-Rose Hill" },
      { code: "MU-BL", name: "Black River" },
      { code: "MU-CC", name: "Cargados Carajos Shoals" },
      { code: "MU-CU", name: "Curepipe" },
      { code: "MU-FL", name: "Flacq" },
      { code: "MU-GP", name: "Grand Port" },
      { code: "MU-MO", name: "Moka" },
      { code: "MU-PA", name: "Pamplemousses" },
      { code: "MU-PW", name: "Plaines Wilhems" },
      { code: "MU-PU", name: "Port Louis" },
      { code: "MU-PL", name: "Port Louis" },
      { code: "MU-QB", name: "Quatre Bornes" },
      { code: "MU-RR", name: "Rivière du Rempart" },
      { code: "MU-RO", name: "Rodrigues Island" },
      { code: "MU-SA", name: "Savanne" },
      { code: "MU-VP", name: "Vacoas-Phoenix" },
    ],
  },
  {
    name: "Mayotte",
    alpha2: "YT",
    alpha3: "MYT",
    numeric: "175",
    subdivisions: [],
  },
  {
    name: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    numeric: "484",
    subdivisions: [
      { code: "MX-AGU", name: "Aguascalientes" },
      { code: "MX-BCN", name: "Baja California" },
      { code: "MX-BCS", name: "Baja California Sur" },
      { code: "MX-CAM", name: "Campeche" },
      { code: "MX-CHP", name: "Chiapas" },
      { code: "MX-CHH", name: "Chihuahua" },
      { code: "MX-CMX", name: "Ciudad de México" },
      { code: "MX-COA", name: "Coahuila de Zaragoza" },
      { code: "MX-COL", name: "Colima" },
      { code: "MX-DUR", name: "Durango" },
      { code: "MX-GUA", name: "Guanajuato" },
      { code: "MX-GRO", name: "Guerrero" },
      { code: "MX-HID", name: "Hidalgo" },
      { code: "MX-JAL", name: "Jalisco" },
      { code: "MX-MIC", name: "Michoacán de Ocampo" },
      { code: "MX-MOR", name: "Morelos" },
      { code: "MX-MEX", name: "México" },
      { code: "MX-NAY", name: "Nayarit" },
      { code: "MX-NLE", name: "Nuevo León" },
      { code: "MX-OAX", name: "Oaxaca" },
      { code: "MX-PUE", name: "Puebla" },
      { code: "MX-QUE", name: "Querétaro" },
      { code: "MX-ROO", name: "Quintana Roo" },
      { code: "MX-SLP", name: "San Luis Potosí" },
      { code: "MX-SIN", name: "Sinaloa" },
      { code: "MX-SON", name: "Sonora" },
      { code: "MX-TAB", name: "Tabasco" },
      { code: "MX-TAM", name: "Tamaulipas" },
      { code: "MX-TLA", name: "Tlaxcala" },
      { code: "MX-VER", name: "Veracruz de Ignacio de la Llave" },
      { code: "MX-YUC", name: "Yucatán" },
      { code: "MX-ZAC", name: "Zacatecas" },
    ],
  },
  {
    name: "Micronesia (Federated States of)",
    alpha2: "FM",
    alpha3: "FSM",
    numeric: "583",
    subdivisions: [
      { code: "FM-TRK", name: "Chuuk" },
      { code: "FM-KSA", name: "Kosrae" },
      { code: "FM-PNI", name: "Pohnpei" },
      { code: "FM-YAP", name: "Yap" },
    ],
  },
  {
    name: "Moldova (the Republic of)",
    alpha2: "MD",
    alpha3: "MDA",
    numeric: "498",
    subdivisions: [
      { code: "MD-AN", name: "Anenii Noi" },
      { code: "MD-BS", name: "Basarabeasca" },
      { code: "MD-BD", name: "Bender [Tighina]" },
      { code: "MD-BR", name: "Briceni" },
      { code: "MD-BA", name: "Bălți" },
      { code: "MD-CA", name: "Cahul" },
      { code: "MD-CT", name: "Cantemir" },
      { code: "MD-CU", name: "Chișinău" },
      { code: "MD-CM", name: "Cimișlia" },
      { code: "MD-CR", name: "Criuleni" },
      { code: "MD-CL", name: "Călărași" },
      { code: "MD-CS", name: "Căușeni" },
      { code: "MD-DO", name: "Dondușeni" },
      { code: "MD-DR", name: "Drochia" },
      { code: "MD-DU", name: "Dubăsari" },
      { code: "MD-ED", name: "Edineț" },
      { code: "MD-FL", name: "Florești" },
      { code: "MD-FA", name: "Fălești" },
      { code: "MD-GL", name: "Glodeni" },
      { code: "MD-GA", name: "Găgăuzia, Unitatea teritorială autonomă (UTAG)" },
      { code: "MD-HI", name: "Hîncești" },
      { code: "MD-IA", name: "Ialoveni" },
      { code: "MD-LE", name: "Leova" },
      { code: "MD-NI", name: "Nisporeni" },
      { code: "MD-OC", name: "Ocnița" },
      { code: "MD-OR", name: "Orhei" },
      { code: "MD-RE", name: "Rezina" },
      { code: "MD-RI", name: "Rîșcani" },
      { code: "MD-SO", name: "Soroca" },
      { code: "MD-ST", name: "Strășeni" },
      { code: "MD-SN", name: "Stînga Nistrului, unitatea teritorială din" },
      { code: "MD-SI", name: "Sîngerei" },
      { code: "MD-TA", name: "Taraclia" },
      { code: "MD-TE", name: "Telenești" },
      { code: "MD-UN", name: "Ungheni" },
      { code: "MD-SD", name: "Șoldănești" },
      { code: "MD-SV", name: "Ștefan Vodă" },
    ],
  },
  {
    name: "Monaco",
    alpha2: "MC",
    alpha3: "MCO",
    numeric: "492",
    subdivisions: [
      { code: "MC-FO", name: "Fontvieille" },
      { code: "MC-JE", name: "Jardin Exotique" },
      { code: "MC-CL", name: "La Colle" },
      { code: "MC-CO", name: "La Condamine" },
      { code: "MC-GA", name: "La Gare" },
      { code: "MC-SO", name: "La Source" },
      { code: "MC-LA", name: "Larvotto" },
      { code: "MC-MA", name: "Malbousquet" },
      { code: "MC-MO", name: "Monaco-Ville" },
      { code: "MC-MG", name: "Moneghetti" },
      { code: "MC-MC", name: "Monte-Carlo" },
      { code: "MC-MU", name: "Moulins" },
      { code: "MC-PH", name: "Port-Hercule" },
      { code: "MC-SR", name: "Saint-Roman" },
      { code: "MC-SD", name: "Sainte-Dévote" },
      { code: "MC-SP", name: "Spélugues" },
      { code: "MC-VR", name: "Vallon de la Rousse" },
    ],
  },
  {
    name: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    numeric: "496",
    subdivisions: [
      { code: "MN-073", name: "Arhangay" },
      { code: "MN-071", name: "Bayan-Ölgiy" },
      { code: "MN-069", name: "Bayanhongor" },
      { code: "MN-067", name: "Bulgan" },
      { code: "MN-037", name: "Darhan uul" },
      { code: "MN-061", name: "Dornod" },
      { code: "MN-063", name: "Dornogovĭ" },
      { code: "MN-059", name: "Dundgovĭ" },
      { code: "MN-057", name: "Dzavhan" },
      { code: "MN-065", name: "Govĭ-Altay" },
      { code: "MN-064", name: "Govĭ-Sümber" },
      { code: "MN-039", name: "Hentiy" },
      { code: "MN-043", name: "Hovd" },
      { code: "MN-041", name: "Hövsgöl" },
      { code: "MN-035", name: "Orhon" },
      { code: "MN-049", name: "Selenge" },
      { code: "MN-051", name: "Sühbaatar" },
      { code: "MN-047", name: "Töv" },
      { code: "MN-1", name: "Ulaanbaatar" },
      { code: "MN-046", name: "Uvs" },
      { code: "MN-053", name: "Ömnögovĭ" },
      { code: "MN-055", name: "Övörhangay" },
    ],
  },
  {
    name: "Montenegro",
    alpha2: "ME",
    alpha3: "MNE",
    numeric: "499",
    subdivisions: [
      { code: "ME-01", name: "Andrijevica" },
      { code: "ME-02", name: "Bar" },
      { code: "ME-03", name: "Berane" },
      { code: "ME-04", name: "Bijelo Polje" },
      { code: "ME-05", name: "Budva" },
      { code: "ME-06", name: "Cetinje" },
      { code: "ME-07", name: "Danilovgrad" },
      { code: "ME-22", name: "Gusinje" },
      { code: "ME-08", name: "Herceg-Novi" },
      { code: "ME-09", name: "Kolašin" },
      { code: "ME-10", name: "Kotor" },
      { code: "ME-11", name: "Mojkovac" },
      { code: "ME-12", name: "Nikšić" },
      { code: "ME-23", name: "Petnjica" },
      { code: "ME-13", name: "Plav" },
      { code: "ME-14", name: "Pljevlja" },
      { code: "ME-15", name: "Plužine" },
      { code: "ME-16", name: "Podgorica" },
      { code: "ME-17", name: "Rožaje" },
      { code: "ME-19", name: "Tivat" },
      { code: "ME-24", name: "Tuzi" },
      { code: "ME-20", name: "Ulcinj" },
      { code: "ME-18", name: "Šavnik" },
      { code: "ME-21", name: "Žabljak" },
    ],
  },
  {
    name: "Montserrat",
    alpha2: "MS",
    alpha3: "MSR",
    numeric: "500",
    subdivisions: [],
  },
  {
    name: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    numeric: "504",
    subdivisions: [
      { code: "MA-AGD", name: "Agadir-Ida-Ou-Tanane" },
      { code: "MA-HAO", name: "Al Haouz" },
      { code: "MA-HOC", name: "Al Hoceïma" },
      { code: "MA-AOU", name: "Aousserd (EH)" },
      { code: "MA-ASZ", name: "Assa-Zag (EH-partial)" },
      { code: "MA-AZI", name: "Azilal" },
      { code: "MA-BES", name: "Benslimane" },
      { code: "MA-BER", name: "Berkane" },
      { code: "MA-BRR", name: "Berrechid" },
      { code: "MA-BOD", name: "Boujdour (EH)" },
      { code: "MA-BOM", name: "Boulemane" },
      { code: "MA-BEM", name: "Béni Mellal" },
      { code: "MA-05", name: "Béni Mellal-Khénifra" },
      { code: "MA-CAS", name: "Casablanca" },
      { code: "MA-06", name: "Casablanca-Settat" },
      { code: "MA-CHE", name: "Chefchaouen" },
      { code: "MA-CHI", name: "Chichaoua" },
      { code: "MA-CHT", name: "Chtouka-Ait Baha" },
      { code: "MA-12", name: "Dakhla-Oued Ed-Dahab (EH)" },
      { code: "MA-DRI", name: "Driouch" },
      { code: "MA-08", name: "Drâa-Tafilalet" },
      { code: "MA-HAJ", name: "El Hajeb" },
      { code: "MA-JDI", name: "El Jadida" },
      { code: "MA-KES", name: "El Kelâa des Sraghna" },
      { code: "MA-ERR", name: "Errachidia" },
      { code: "MA-ESM", name: "Es-Semara (EH-partial)" },
      { code: "MA-ESI", name: "Essaouira" },
      { code: "MA-FAH", name: "Fahs-Anjra" },
      { code: "MA-FIG", name: "Figuig" },
      { code: "MA-FQH", name: "Fquih Ben Salah" },
      { code: "MA-FES", name: "Fès" },
      { code: "MA-03", name: "Fès-Meknès" },
      { code: "MA-GUE", name: "Guelmim" },
      { code: "MA-10", name: "Guelmim-Oued Noun (EH-partial)" },
      { code: "MA-GUF", name: "Guercif" },
      { code: "MA-IFR", name: "Ifrane" },
      { code: "MA-INE", name: "Inezgane-Ait Melloul" },
      { code: "MA-JRA", name: "Jerada" },
      { code: "MA-KHE", name: "Khemisset" },
      { code: "MA-KHN", name: "Khenifra" },
      { code: "MA-KHO", name: "Khouribga" },
      { code: "MA-KEN", name: "Kénitra" },
      { code: "MA-02", name: "L'Oriental" },
      { code: "MA-LAR", name: "Larache" },
      { code: "MA-LAA", name: "Laâyoune (EH)" },
      { code: "MA-11", name: "Laâyoune-Sakia El Hamra (EH-partial)" },
      { code: "MA-MAR", name: "Marrakech" },
      { code: "MA-07", name: "Marrakech-Safi" },
      { code: "MA-MEK", name: "Meknès" },
      { code: "MA-MID", name: "Midelt" },
      { code: "MA-MOH", name: "Mohammadia" },
      { code: "MA-MOU", name: "Moulay Yacoub" },
      { code: "MA-MED", name: "Médiouna" },
      { code: "MA-MDF", name: "M’diq-Fnideq" },
      { code: "MA-NAD", name: "Nador" },
      { code: "MA-NOU", name: "Nouaceur" },
      { code: "MA-OUA", name: "Ouarzazate" },
      { code: "MA-OUD", name: "Oued Ed-Dahab (EH)" },
      { code: "MA-OUZ", name: "Ouezzane" },
      { code: "MA-OUJ", name: "Oujda-Angad" },
      { code: "MA-RAB", name: "Rabat" },
      { code: "MA-04", name: "Rabat-Salé-Kénitra" },
      { code: "MA-REH", name: "Rehamna" },
      { code: "MA-SAF", name: "Safi" },
      { code: "MA-SAL", name: "Salé" },
      { code: "MA-SEF", name: "Sefrou" },
      { code: "MA-SET", name: "Settat" },
      { code: "MA-SIB", name: "Sidi Bennour" },
      { code: "MA-SIF", name: "Sidi Ifni" },
      { code: "MA-SIK", name: "Sidi Kacem" },
      { code: "MA-SIL", name: "Sidi Slimane" },
      { code: "MA-SKH", name: "Skhirate-Témara" },
      { code: "MA-09", name: "Souss-Massa" },
      { code: "MA-TNT", name: "Tan-Tan (EH-partial)" },
      { code: "MA-TNG", name: "Tanger-Assilah" },
      { code: "MA-01", name: "Tanger-Tétouan-Al Hoceïma" },
      { code: "MA-TAO", name: "Taounate" },
      { code: "MA-TAI", name: "Taourirt" },
      { code: "MA-TAF", name: "Tarfaya (EH-partial)" },
      { code: "MA-TAR", name: "Taroudant" },
      { code: "MA-TAT", name: "Tata" },
      { code: "MA-TAZ", name: "Taza" },
      { code: "MA-TIN", name: "Tinghir" },
      { code: "MA-TIZ", name: "Tiznit" },
      { code: "MA-TET", name: "Tétouan" },
      { code: "MA-YUS", name: "Youssoufia" },
      { code: "MA-ZAG", name: "Zagora" },
    ],
  },
  {
    name: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    numeric: "508",
    subdivisions: [
      { code: "MZ-P", name: "Cabo Delgado" },
      { code: "MZ-G", name: "Gaza" },
      { code: "MZ-I", name: "Inhambane" },
      { code: "MZ-B", name: "Manica" },
      { code: "MZ-MPM", name: "Maputo" },
      { code: "MZ-L", name: "Maputo" },
      { code: "MZ-N", name: "Nampula" },
      { code: "MZ-A", name: "Niassa" },
      { code: "MZ-S", name: "Sofala" },
      { code: "MZ-T", name: "Tete" },
      { code: "MZ-Q", name: "Zambézia" },
    ],
  },
  {
    name: "Myanmar",
    alpha2: "MM",
    alpha3: "MMR",
    numeric: "104",
    subdivisions: [
      { code: "MM-07", name: "Ayeyarwady" },
      { code: "MM-02", name: "Bago" },
      { code: "MM-14", name: "Chin" },
      { code: "MM-11", name: "Kachin" },
      { code: "MM-12", name: "Kayah" },
      { code: "MM-13", name: "Kayin" },
      { code: "MM-03", name: "Magway" },
      { code: "MM-04", name: "Mandalay" },
      { code: "MM-15", name: "Mon" },
      { code: "MM-18", name: "Nay Pyi Taw" },
      { code: "MM-16", name: "Rakhine" },
      { code: "MM-01", name: "Sagaing" },
      { code: "MM-17", name: "Shan" },
      { code: "MM-05", name: "Tanintharyi" },
      { code: "MM-06", name: "Yangon" },
    ],
  },
  {
    name: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    numeric: "516",
    subdivisions: [
      { code: "NA-ER", name: "Erongo" },
      { code: "NA-HA", name: "Hardap" },
      { code: "NA-KA", name: "Karas" },
      { code: "NA-KE", name: "Kavango East" },
      { code: "NA-KW", name: "Kavango West" },
      { code: "NA-KH", name: "Khomas" },
      { code: "NA-KU", name: "Kunene" },
      { code: "NA-OW", name: "Ohangwena" },
      { code: "NA-OH", name: "Omaheke" },
      { code: "NA-OS", name: "Omusati" },
      { code: "NA-ON", name: "Oshana" },
      { code: "NA-OT", name: "Oshikoto" },
      { code: "NA-OD", name: "Otjozondjupa" },
      { code: "NA-CA", name: "Zambezi" },
    ],
  },
  {
    name: "Nauru",
    alpha2: "NR",
    alpha3: "NRU",
    numeric: "520",
    subdivisions: [
      { code: "NR-01", name: "Aiwo" },
      { code: "NR-02", name: "Anabar" },
      { code: "NR-03", name: "Anetan" },
      { code: "NR-04", name: "Anibare" },
      { code: "NR-05", name: "Baitsi" },
      { code: "NR-06", name: "Boe" },
      { code: "NR-07", name: "Buada" },
      { code: "NR-08", name: "Denigomodu" },
      { code: "NR-09", name: "Ewa" },
      { code: "NR-10", name: "Ijuw" },
      { code: "NR-11", name: "Meneng" },
      { code: "NR-12", name: "Nibok" },
      { code: "NR-13", name: "Uaboe" },
      { code: "NR-14", name: "Yaren" },
    ],
  },
  {
    name: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    numeric: "524",
    subdivisions: [
      { code: "NP-BA", name: "Bagmati" },
      { code: "NP-BH", name: "Bheri" },
      { code: "NP-1", name: "Central" },
      { code: "NP-DH", name: "Dhawalagiri" },
      { code: "NP-4", name: "Eastern" },
      { code: "NP-5", name: "Far Western" },
      { code: "NP-GA", name: "Gandaki" },
      { code: "NP-P4", name: "Gandaki" },
      { code: "NP-JA", name: "Janakpur" },
      { code: "NP-KA", name: "Karnali" },
      { code: "NP-P6", name: "Karnali" },
      { code: "NP-KO", name: "Kosi" },
      { code: "NP-LU", name: "Lumbini" },
      { code: "NP-2", name: "Madhya Pashchimanchal" },
      { code: "NP-MA", name: "Mahakali" },
      { code: "NP-ME", name: "Mechi" },
      { code: "NP-NA", name: "Narayani" },
      { code: "NP-3", name: "Pashchimanchal" },
      { code: "NP-P1", name: "Pradesh 1" },
      { code: "NP-P2", name: "Pradesh 2" },
      { code: "NP-P3", name: "Pradesh 3" },
      { code: "NP-P5", name: "Pradesh 5" },
      { code: "NP-P7", name: "Pradesh 7" },
      { code: "NP-RA", name: "Rapti" },
      { code: "NP-SA", name: "Sagarmatha" },
      { code: "NP-SE", name: "Seti" },
    ],
  },
  {
    name: "Netherlands (the)",
    alpha2: "NL",
    alpha3: "NLD",
    numeric: "528",
    subdivisions: [
      {
        code: "NL-AW",
        name: "Aruba (see also separate country code entry under AW)",
      },
      {
        code: "NL-BQ1",
        name: "Bonaire (see also separate country code entry under BQ)",
      },
      {
        code: "NL-CW",
        name: "Curaçao (see also separate country code entry under CW)",
      },
      { code: "NL-DR", name: "Drenthe" },
      { code: "NL-FL", name: "Flevoland" },
      { code: "NL-FR", name: "Fryslân" },
      { code: "NL-GE", name: "Gelderland" },
      { code: "NL-GR", name: "Groningen" },
      { code: "NL-LI", name: "Limburg" },
      { code: "NL-NB", name: "Noord-Brabant" },
      { code: "NL-NH", name: "Noord-Holland" },
      { code: "NL-OV", name: "Overijssel" },
      {
        code: "NL-BQ2",
        name: "Saba (see also separate country code entry under BQ)",
      },
      {
        code: "NL-BQ3",
        name: "Sint Eustatius (see also separate country code entry under BQ)",
      },
      {
        code: "NL-SX",
        name: "Sint Maarten (see also separate country code entry under SX)",
      },
      { code: "NL-UT", name: "Utrecht" },
      { code: "NL-ZE", name: "Zeeland" },
      { code: "NL-ZH", name: "Zuid-Holland" },
    ],
  },
  {
    name: "New Caledonia",
    alpha2: "NC",
    alpha3: "NCL",
    numeric: "540",
    subdivisions: [],
  },
  {
    name: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    numeric: "554",
    subdivisions: [
      { code: "NZ-AUK", name: "Auckland" },
      { code: "NZ-BOP", name: "Bay of Plenty" },
      { code: "NZ-CAN", name: "Canterbury" },
      { code: "NZ-CIT", name: "Chatham Islands Territory" },
      { code: "NZ-GIS", name: "Gisborne" },
      { code: "NZ-HKB", name: "Hawke's Bay" },
      { code: "NZ-MWT", name: "Manawatu Whanganui" },
      { code: "NZ-MBH", name: "Marlborough" },
      { code: "NZ-STL", name: "Murihiku" },
      { code: "NZ-NSN", name: "Nelson" },
      { code: "NZ-NTL", name: "Northland" },
      { code: "NZ-OTA", name: "Otago" },
      { code: "NZ-TKI", name: "Taranaki" },
      { code: "NZ-TAS", name: "Tasman" },
      { code: "NZ-WTC", name: "Te Taihau ā uru" },
      { code: "NZ-WGN", name: "Te Whanga-nui-a-Tara" },
      { code: "NZ-WKO", name: "Waikato" },
    ],
  },
  {
    name: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    numeric: "558",
    subdivisions: [
      { code: "NI-BO", name: "Boaco" },
      { code: "NI-CA", name: "Carazo" },
      { code: "NI-CI", name: "Chinandega" },
      { code: "NI-CO", name: "Chontales" },
      { code: "NI-AN", name: "Costa Caribe Norte" },
      { code: "NI-AS", name: "Costa Caribe Sur" },
      { code: "NI-ES", name: "Estelí" },
      { code: "NI-GR", name: "Granada" },
      { code: "NI-JI", name: "Jinotega" },
      { code: "NI-LE", name: "León" },
      { code: "NI-MD", name: "Madriz" },
      { code: "NI-MN", name: "Managua" },
      { code: "NI-MS", name: "Masaya" },
      { code: "NI-MT", name: "Matagalpa" },
      { code: "NI-NS", name: "Nueva Segovia" },
      { code: "NI-RI", name: "Rivas" },
      { code: "NI-SJ", name: "Río San Juan" },
    ],
  },
  {
    name: "Niger (the)",
    alpha2: "NE",
    alpha3: "NER",
    numeric: "562",
    subdivisions: [
      { code: "NE-1", name: "Agadez" },
      { code: "NE-2", name: "Diffa" },
      { code: "NE-3", name: "Dosso" },
      { code: "NE-4", name: "Maradi" },
      { code: "NE-8", name: "Niamey" },
      { code: "NE-5", name: "Tahoua" },
      { code: "NE-6", name: "Tillabéri" },
      { code: "NE-7", name: "Zinder" },
    ],
  },
  {
    name: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    numeric: "566",
    subdivisions: [
      { code: "NG-AB", name: "Abia" },
      { code: "NG-FC", name: "Abuja Federal Capital Territory" },
      { code: "NG-AD", name: "Adamawa" },
      { code: "NG-AK", name: "Akwa Ibom" },
      { code: "NG-AN", name: "Anambra" },
      { code: "NG-BA", name: "Bauchi" },
      { code: "NG-BY", name: "Bayelsa" },
      { code: "NG-BE", name: "Benue" },
      { code: "NG-BO", name: "Borno" },
      { code: "NG-CR", name: "Cross River" },
      { code: "NG-DE", name: "Delta" },
      { code: "NG-EB", name: "Ebonyi" },
      { code: "NG-ED", name: "Edo" },
      { code: "NG-EK", name: "Ekiti" },
      { code: "NG-EN", name: "Enugu" },
      { code: "NG-GO", name: "Gombe" },
      { code: "NG-IM", name: "Imo" },
      { code: "NG-JI", name: "Jigawa" },
      { code: "NG-KD", name: "Kaduna" },
      { code: "NG-KN", name: "Kano" },
      { code: "NG-KT", name: "Katsina" },
      { code: "NG-KE", name: "Kebbi" },
      { code: "NG-KO", name: "Kogi" },
      { code: "NG-KW", name: "Kwara" },
      { code: "NG-LA", name: "Lagos" },
      { code: "NG-NA", name: "Nasarawa" },
      { code: "NG-NI", name: "Niger" },
      { code: "NG-OG", name: "Ogun" },
      { code: "NG-ON", name: "Ondo" },
      { code: "NG-OS", name: "Osun" },
      { code: "NG-OY", name: "Oyo" },
      { code: "NG-PL", name: "Plateau" },
      { code: "NG-RI", name: "Rivers" },
      { code: "NG-SO", name: "Sokoto" },
      { code: "NG-TA", name: "Taraba" },
      { code: "NG-YO", name: "Yobe" },
      { code: "NG-ZA", name: "Zamfara" },
    ],
  },
  {
    name: "Niue",
    alpha2: "NU",
    alpha3: "NIU",
    numeric: "570",
    subdivisions: [],
  },
  {
    name: "Norfolk Island",
    alpha2: "NF",
    alpha3: "NFK",
    numeric: "574",
    subdivisions: [],
  },
  {
    name: "North Macedonia",
    alpha2: "MK",
    alpha3: "MKD",
    numeric: "807",
    subdivisions: [
      { code: "MK-801", name: "Aerodrom †" },
      { code: "MK-802", name: "Aračinovo" },
      { code: "MK-201", name: "Berovo" },
      { code: "MK-501", name: "Bitola" },
      { code: "MK-401", name: "Bogdanci" },
      { code: "MK-601", name: "Bogovinje" },
      { code: "MK-402", name: "Bosilovo" },
      { code: "MK-602", name: "Brvenica" },
      { code: "MK-803", name: "Butel †" },
      { code: "MK-313", name: "Centar Župa" },
      { code: "MK-814", name: "Centar †" },
      { code: "MK-303", name: "Debar" },
      { code: "MK-304", name: "Debrca" },
      { code: "MK-203", name: "Delčevo" },
      { code: "MK-502", name: "Demir Hisar" },
      { code: "MK-103", name: "Demir Kapija" },
      { code: "MK-406", name: "Dojran" },
      { code: "MK-503", name: "Dolneni" },
      { code: "MK-804", name: "Gazi Baba †" },
      { code: "MK-405", name: "Gevgelija" },
      { code: "MK-805", name: "Gjorče Petrov †" },
      { code: "MK-604", name: "Gostivar" },
      { code: "MK-102", name: "Gradsko" },
      { code: "MK-807", name: "Ilinden" },
      { code: "MK-606", name: "Jegunovce" },
      { code: "MK-205", name: "Karbinci" },
      { code: "MK-808", name: "Karpoš †" },
      { code: "MK-104", name: "Kavadarci" },
      { code: "MK-809", name: "Kisela Voda †" },
      { code: "MK-307", name: "Kičevo" },
      { code: "MK-407", name: "Konče" },
      { code: "MK-206", name: "Kočani" },
      { code: "MK-701", name: "Kratovo" },
      { code: "MK-702", name: "Kriva Palanka" },
      { code: "MK-504", name: "Krivogaštani" },
      { code: "MK-505", name: "Kruševo" },
      { code: "MK-703", name: "Kumanovo" },
      { code: "MK-704", name: "Lipkovo" },
      { code: "MK-105", name: "Lozovo" },
      { code: "MK-207", name: "Makedonska Kamenica" },
      { code: "MK-308", name: "Makedonski Brod" },
      { code: "MK-607", name: "Mavrovo i Rostuše" },
      { code: "MK-506", name: "Mogila" },
      { code: "MK-106", name: "Negotino" },
      { code: "MK-507", name: "Novaci" },
      { code: "MK-408", name: "Novo Selo" },
      { code: "MK-310", name: "Ohrid" },
      { code: "MK-208", name: "Pehčevo" },
      { code: "MK-810", name: "Petrovec" },
      { code: "MK-311", name: "Plasnica" },
      { code: "MK-508", name: "Prilep" },
      { code: "MK-209", name: "Probištip" },
      { code: "MK-409", name: "Radoviš" },
      { code: "MK-705", name: "Rankovce" },
      { code: "MK-509", name: "Resen" },
      { code: "MK-107", name: "Rosoman" },
      { code: "MK-811", name: "Saraj †" },
      { code: "MK-812", name: "Sopište" },
      { code: "MK-706", name: "Staro Nagoričane" },
      { code: "MK-312", name: "Struga" },
      { code: "MK-410", name: "Strumica" },
      { code: "MK-813", name: "Studeničani" },
      { code: "MK-108", name: "Sveti Nikole" },
      { code: "MK-608", name: "Tearce" },
      { code: "MK-609", name: "Tetovo" },
      { code: "MK-403", name: "Valandovo" },
      { code: "MK-404", name: "Vasilevo" },
      { code: "MK-101", name: "Veles" },
      { code: "MK-301", name: "Vevčani" },
      { code: "MK-202", name: "Vinica" },
      { code: "MK-603", name: "Vrapčište" },
      { code: "MK-806", name: "Zelenikovo" },
      { code: "MK-204", name: "Zrnovci" },
      { code: "MK-815", name: "Čair †" },
      { code: "MK-109", name: "Čaška" },
      { code: "MK-210", name: "Češinovo-Obleševo" },
      { code: "MK-816", name: "Čučer-Sandevo" },
      { code: "MK-211", name: "Štip" },
      { code: "MK-817", name: "Šuto Orizari †" },
      { code: "MK-605", name: "Želino" },
    ],
  },
  {
    name: "Northern Mariana Islands (the)",
    alpha2: "MP",
    alpha3: "MNP",
    numeric: "580",
    subdivisions: [],
  },
  {
    name: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    numeric: "578",
    subdivisions: [
      { code: "NO-02", name: "Akershus" },
      { code: "NO-09", name: "Aust-Agder" },
      { code: "NO-06", name: "Buskerud" },
      { code: "NO-20", name: "Finnmark" },
      { code: "NO-04", name: "Hedmark" },
      { code: "NO-12", name: "Hordaland" },
      {
        code: "NO-22",
        name: "Jan Mayen (Arctic Region) (see also separate country code entry under SJ)",
      },
      { code: "NO-15", name: "Møre og Romsdal" },
      { code: "NO-18", name: "Nordland" },
      { code: "NO-05", name: "Oppland" },
      { code: "NO-03", name: "Oslo" },
      { code: "NO-11", name: "Rogaland" },
      { code: "NO-19", name: "Romsa" },
      { code: "NO-14", name: "Sogn og Fjordane" },
      {
        code: "NO-21",
        name: "Svalbard (Arctic Region) (see also separate country code entry under SJ)",
      },
      { code: "NO-08", name: "Telemark" },
      { code: "NO-50", name: "Trøndelag" },
      { code: "NO-10", name: "Vest-Agder" },
      { code: "NO-07", name: "Vestfold" },
      { code: "NO-01", name: "Østfold" },
    ],
  },
  {
    name: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    numeric: "512",
    subdivisions: [
      { code: "OM-DA", name: "Ad Dākhilīyah" },
      { code: "OM-BU", name: "Al Buraymī" },
      { code: "OM-WU", name: "Al Wusţá" },
      { code: "OM-ZA", name: "Az̧ Z̧āhirah" },
      { code: "OM-BJ", name: "Janūb al Bāţinah" },
      { code: "OM-SJ", name: "Janūb ash Sharqīyah" },
      { code: "OM-MA", name: "Masqaţ" },
      { code: "OM-MU", name: "Musandam" },
      { code: "OM-BS", name: "Shamāl al Bāţinah" },
      { code: "OM-SS", name: "Shamāl ash Sharqīyah" },
      { code: "OM-ZU", name: "Z̧ufār" },
    ],
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    numeric: "586",
    subdivisions: [
      { code: "PK-JK", name: "Azad Jammu and Kashmir" },
      { code: "PK-BA", name: "Balochistan" },
      { code: "PK-GB", name: "Gilgit-Baltistan" },
      { code: "PK-IS", name: "Islamabad" },
      { code: "PK-KP", name: "Khaībar Pakhtūnkhwā" },
      { code: "PK-PB", name: "Panjāb" },
      { code: "PK-SD", name: "Sindh" },
    ],
  },
  {
    name: "Palau",
    alpha2: "PW",
    alpha3: "PLW",
    numeric: "585",
    subdivisions: [
      { code: "PW-002", name: "Aimeliik" },
      { code: "PW-004", name: "Airai" },
      { code: "PW-010", name: "Angaur" },
      { code: "PW-050", name: "Hatohobei" },
      { code: "PW-100", name: "Kayangel" },
      { code: "PW-150", name: "Koror" },
      { code: "PW-212", name: "Melekeok" },
      { code: "PW-214", name: "Ngaraard" },
      { code: "PW-218", name: "Ngarchelong" },
      { code: "PW-222", name: "Ngardmau" },
      { code: "PW-224", name: "Ngatpang" },
      { code: "PW-226", name: "Ngchesar" },
      { code: "PW-227", name: "Ngeremlengui" },
      { code: "PW-228", name: "Ngiwal" },
      { code: "PW-350", name: "Peleliu" },
      { code: "PW-370", name: "Sonsorol" },
    ],
  },
  {
    name: "Palestine, State of",
    alpha2: "PS",
    alpha3: "PSE",
    numeric: "275",
    subdivisions: [
      { code: "PS-HBN", name: "Al Khalīl" },
      { code: "PS-JEM", name: "Al Quds" },
      { code: "PS-JRH", name: "Arīḩā wal Aghwār" },
      { code: "PS-BTH", name: "Bayt Laḩm" },
      { code: "PS-DEB", name: "Dayr al Balaḩ" },
      { code: "PS-GZA", name: "Gaza" },
      { code: "PS-JEN", name: "Janīn" },
      { code: "PS-KYS", name: "Khan Yunis" },
      { code: "PS-NBS", name: "Nablus" },
      { code: "PS-NGZ", name: "North Gaza" },
      { code: "PS-QQA", name: "Qalqilya" },
      { code: "PS-RFH", name: "Rafah" },
      { code: "PS-RBH", name: "Ramallah" },
      { code: "PS-SLT", name: "Salfit" },
      { code: "PS-TBS", name: "Tubas" },
      { code: "PS-TKM", name: "Tulkarm" },
    ],
  },
  {
    name: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    numeric: "591",
    subdivisions: [
      { code: "PA-1", name: "Bocas del Toro" },
      { code: "PA-4", name: "Chiriquí" },
      { code: "PA-2", name: "Coclé" },
      { code: "PA-3", name: "Colón" },
      { code: "PA-5", name: "Darién" },
      { code: "PA-EM", name: "Emberá" },
      { code: "PA-KY", name: "Guna Yala" },
      { code: "PA-6", name: "Herrera" },
      { code: "PA-7", name: "Los Santos" },
      { code: "PA-NB", name: "Ngöbe-Buglé" },
      { code: "PA-8", name: "Panamá" },
      { code: "PA-10", name: "Panamá Oeste" },
      { code: "PA-9", name: "Veraguas" },
    ],
  },
  {
    name: "Papua New Guinea",
    alpha2: "PG",
    alpha3: "PNG",
    numeric: "598",
    subdivisions: [
      { code: "PG-NSB", name: "Bougainville" },
      { code: "PG-CPM", name: "Central" },
      { code: "PG-CPK", name: "Chimbu" },
      { code: "PG-EBR", name: "East New Britain" },
      { code: "PG-ESW", name: "East Sepik" },
      { code: "PG-EHG", name: "Eastern Highlands" },
      { code: "PG-EPW", name: "Enga" },
      { code: "PG-GPK", name: "Gulf" },
      { code: "PG-HLA", name: "Hela" },
      { code: "PG-JWK", name: "Jiwaka" },
      { code: "PG-MPM", name: "Madang" },
      { code: "PG-MRL", name: "Manus" },
      { code: "PG-MBA", name: "Milne Bay" },
      { code: "PG-MPL", name: "Morobe" },
      { code: "PG-NCD", name: "National Capital District (Port Moresby)" },
      { code: "PG-NIK", name: "New Ireland" },
      { code: "PG-NPP", name: "Northern" },
      { code: "PG-SHM", name: "Southern Highlands" },
      { code: "PG-WBK", name: "West New Britain" },
      { code: "PG-SAN", name: "West Sepik" },
      { code: "PG-WPD", name: "Western" },
      { code: "PG-WHM", name: "Western Highlands" },
    ],
  },
  {
    name: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    numeric: "600",
    subdivisions: [
      { code: "PY-16", name: "Alto Paraguay" },
      { code: "PY-10", name: "Alto Paraná" },
      { code: "PY-13", name: "Amambay" },
      { code: "PY-ASU", name: "Asunción" },
      { code: "PY-19", name: "Boquerón" },
      { code: "PY-5", name: "Caaguazú" },
      { code: "PY-6", name: "Caazapá" },
      { code: "PY-14", name: "Canindeyú" },
      { code: "PY-11", name: "Central" },
      { code: "PY-1", name: "Concepción" },
      { code: "PY-3", name: "Cordillera" },
      { code: "PY-4", name: "Guairá" },
      { code: "PY-7", name: "Itapúa" },
      { code: "PY-8", name: "Misiones" },
      { code: "PY-9", name: "Paraguarí" },
      { code: "PY-15", name: "Presidente Hayes" },
      { code: "PY-2", name: "San Pedro" },
      { code: "PY-12", name: "Ñeembucú" },
    ],
  },
  {
    name: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    numeric: "604",
    subdivisions: [
      { code: "PE-AMA", name: "Amarumayu" },
      { code: "PE-ANC", name: "Ancash" },
      { code: "PE-APU", name: "Apurimaq" },
      { code: "PE-ARE", name: "Arequipa" },
      { code: "PE-AYA", name: "Ayacucho" },
      { code: "PE-CAJ", name: "Cajamarca" },
      { code: "PE-CUS", name: "Cusco" },
      { code: "PE-CAL", name: "El Callao" },
      { code: "PE-HUV", name: "Huancavelica" },
      { code: "PE-JUN", name: "Hunin" },
      { code: "PE-HUC", name: "Huánuco" },
      { code: "PE-ICA", name: "Ica" },
      { code: "PE-LAL", name: "La Libertad" },
      { code: "PE-LAM", name: "Lambayeque" },
      { code: "PE-LIM", name: "Lima" },
      { code: "PE-LMA", name: "Lima hatun llaqta" },
      { code: "PE-LOR", name: "Loreto" },
      { code: "PE-MDD", name: "Madre de Dios" },
      { code: "PE-MOQ", name: "Moquegua" },
      { code: "PE-PAS", name: "Pasco" },
      { code: "PE-PIU", name: "Piura" },
      { code: "PE-PUN", name: "Puno" },
      { code: "PE-SAM", name: "San Martin" },
      { code: "PE-TAC", name: "Tacna" },
      { code: "PE-TUM", name: "Tumbes" },
      { code: "PE-UCA", name: "Ucayali" },
    ],
  },
  {
    name: "Philippines (the)",
    alpha2: "PH",
    alpha3: "PHL",
    numeric: "608",
    subdivisions: [
      { code: "PH-ABR", name: "Abra" },
      { code: "PH-AGN", name: "Agusan del Norte" },
      { code: "PH-AGS", name: "Agusan del Sur" },
      { code: "PH-AKL", name: "Aklan" },
      { code: "PH-ALB", name: "Albay" },
      { code: "PH-ANT", name: "Antike" },
      { code: "PH-APA", name: "Apayao" },
      { code: "PH-AUR", name: "Aurora" },
      { code: "PH-14", name: "Autonomous Region in Muslim Mindanao (ARMM)" },
      { code: "PH-BAS", name: "Basilan" },
      { code: "PH-BAN", name: "Bataan" },
      { code: "PH-BTN", name: "Batanes" },
      { code: "PH-BTG", name: "Batangas" },
      { code: "PH-BEN", name: "Benget" },
      { code: "PH-05", name: "Bicol (Region V)" },
      { code: "PH-BIL", name: "Biliran" },
      { code: "PH-BOH", name: "Bohol" },
      { code: "PH-BUK", name: "Bukidnon" },
      { code: "PH-BUL", name: "Bulacan" },
      { code: "PH-CAG", name: "Cagayan" },
      { code: "PH-02", name: "Cagayan Valley (Region II)" },
      { code: "PH-40", name: "Calabarzon (Region IV-A)" },
      { code: "PH-CAN", name: "Camarines Norte" },
      { code: "PH-CAS", name: "Camarines Sur" },
      { code: "PH-CAM", name: "Camiguin" },
      { code: "PH-CAP", name: "Capiz" },
      { code: "PH-13", name: "Caraga (Region XIII)" },
      { code: "PH-CAT", name: "Catanduanes" },
      { code: "PH-CAV", name: "Cavite" },
      { code: "PH-CEB", name: "Cebu" },
      { code: "PH-03", name: "Central Luzon (Region III)" },
      { code: "PH-07", name: "Central Visayas (Region VII)" },
      { code: "PH-COM", name: "Compostela Valley" },
      { code: "PH-15", name: "Cordillera Administrative Region (CAR)" },
      { code: "PH-NCO", name: "Cotabato" },
      { code: "PH-11", name: "Davao (Region XI)" },
      { code: "PH-DVO", name: "Davao Occidental" },
      { code: "PH-DAO", name: "Davao Oriental" },
      { code: "PH-DAV", name: "Davao del Norte" },
      { code: "PH-DAS", name: "Davao del Sur" },
      { code: "PH-DIN", name: "Dinagat Islands" },
      { code: "PH-EAS", name: "Eastern Samar" },
      { code: "PH-08", name: "Eastern Visayas (Region VIII)" },
      { code: "PH-GUI", name: "Gimaras" },
      { code: "PH-ILN", name: "Hilagang Iloko" },
      { code: "PH-LAN", name: "Hilagang Lanaw" },
      { code: "PH-NSA", name: "Hilagang Samar" },
      { code: "PH-ZAN", name: "Hilagang Sambuwangga" },
      { code: "PH-SUN", name: "Hilagang Surigaw" },
      { code: "PH-IFU", name: "Ifugao" },
      { code: "PH-01", name: "Ilocos (Region I)" },
      { code: "PH-ILS", name: "Ilocos Sur" },
      { code: "PH-ILI", name: "Iloilo" },
      { code: "PH-ISA", name: "Isabela" },
      { code: "PH-KAL", name: "Kalinga" },
      { code: "PH-MDC", name: "Kanlurang Mindoro" },
      { code: "PH-MSC", name: "Kanlurang Misamis" },
      { code: "PH-NEC", name: "Kanlurang Negros" },
      { code: "PH-SLE", name: "Katimogang Leyte" },
      { code: "PH-QUE", name: "Keson" },
      { code: "PH-QUI", name: "Kirino" },
      { code: "PH-LUN", name: "La Union" },
      { code: "PH-LAG", name: "Laguna" },
      { code: "PH-MOU", name: "Lalawigang Bulubundukin" },
      { code: "PH-LAS", name: "Lanao del Sur" },
      { code: "PH-LEY", name: "Leyte" },
      { code: "PH-MAG", name: "Magindanaw" },
      { code: "PH-MAD", name: "Marinduke" },
      { code: "PH-MAS", name: "Masbate" },
      { code: "PH-41", name: "Mimaropa (Region IV-B)" },
      { code: "PH-MDR", name: "Mindoro Oriental" },
      { code: "PH-MSR", name: "Misamis Oriental" },
      { code: "PH-00", name: "National Capital Region" },
      { code: "PH-NER", name: "Negros Oriental" },
      { code: "PH-10", name: "Northern Mindanao (Region X)" },
      { code: "PH-NUE", name: "Nueva Ecija" },
      { code: "PH-NUV", name: "Nueva Vizcaya" },
      { code: "PH-PLW", name: "Palawan" },
      { code: "PH-PAM", name: "Pampanga" },
      { code: "PH-PAN", name: "Pangasinan" },
      { code: "PH-06", name: "Rehiyon ng Kanlurang Bisaya" },
      { code: "PH-12", name: "Rehiyon ng Soccsksargen" },
      { code: "PH-09", name: "Rehiyon ng Tangway ng Sambuwangga" },
      { code: "PH-RIZ", name: "Risal" },
      { code: "PH-ROM", name: "Romblon" },
      { code: "PH-WSA", name: "Samar" },
      { code: "PH-ZMB", name: "Sambales" },
      { code: "PH-ZSI", name: "Sambuwangga Sibugay" },
      { code: "PH-SAR", name: "Sarangani" },
      { code: "PH-SIG", name: "Sikihor" },
      { code: "PH-SOR", name: "Sorsogon" },
      { code: "PH-SCO", name: "South Cotabato" },
      { code: "PH-SUK", name: "Sultan Kudarat" },
      { code: "PH-SLU", name: "Sulu" },
      { code: "PH-SUR", name: "Surigao del Sur" },
      { code: "PH-TAR", name: "Tarlac" },
      { code: "PH-TAW", name: "Tawi-Tawi" },
      { code: "PH-ZAS", name: "Timog Sambuwangga" },
    ],
  },
  {
    name: "Pitcairn",
    alpha2: "PN",
    alpha3: "PCN",
    numeric: "612",
    subdivisions: [],
  },
  {
    name: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    numeric: "616",
    subdivisions: [
      { code: "PL-02", name: "Dolnośląskie" },
      { code: "PL-04", name: "Kujawsko-pomorskie" },
      { code: "PL-06", name: "Lubelskie" },
      { code: "PL-08", name: "Lubuskie" },
      { code: "PL-14", name: "Mazowieckie" },
      { code: "PL-12", name: "Małopolskie" },
      { code: "PL-16", name: "Opolskie" },
      { code: "PL-18", name: "Podkarpackie" },
      { code: "PL-20", name: "Podlaskie" },
      { code: "PL-22", name: "Pomorskie" },
      { code: "PL-28", name: "Warmińsko-mazurskie" },
      { code: "PL-30", name: "Wielkopolskie" },
      { code: "PL-32", name: "Zachodniopomorskie" },
      { code: "PL-10", name: "Łódzkie" },
      { code: "PL-24", name: "Śląskie" },
      { code: "PL-26", name: "Świętokrzyskie" },
    ],
  },
  {
    name: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    numeric: "620",
    subdivisions: [
      { code: "PT-01", name: "Aveiro" },
      { code: "PT-02", name: "Beja" },
      { code: "PT-03", name: "Braga" },
      { code: "PT-04", name: "Bragança" },
      { code: "PT-05", name: "Castelo Branco" },
      { code: "PT-06", name: "Coimbra" },
      { code: "PT-08", name: "Faro" },
      { code: "PT-09", name: "Guarda" },
      { code: "PT-10", name: "Leiria" },
      { code: "PT-11", name: "Lisboa" },
      { code: "PT-12", name: "Portalegre" },
      { code: "PT-13", name: "Porto" },
      { code: "PT-30", name: "Região Autónoma da Madeira" },
      { code: "PT-20", name: "Região Autónoma dos Açores" },
      { code: "PT-14", name: "Santarém" },
      { code: "PT-15", name: "Setúbal" },
      { code: "PT-16", name: "Viana do Castelo" },
      { code: "PT-17", name: "Vila Real" },
      { code: "PT-18", name: "Viseu" },
      { code: "PT-07", name: "Évora" },
    ],
  },
  {
    name: "Puerto Rico",
    alpha2: "PR",
    alpha3: "PRI",
    numeric: "630",
    subdivisions: [],
  },
  {
    name: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    numeric: "634",
    subdivisions: [
      { code: "QA-DA", name: "Ad Dawḩah" },
      { code: "QA-KH", name: "Al Khawr wa adh Dhakhīrah" },
      { code: "QA-WA", name: "Al Wakrah" },
      { code: "QA-RA", name: "Ar Rayyān" },
      { code: "QA-MS", name: "Ash Shamāl" },
      { code: "QA-SH", name: "Ash Shīḩānīyah" },
      { code: "QA-ZA", name: "Az̧ Z̧a‘āyin" },
      { code: "QA-US", name: "Umm Şalāl" },
    ],
  },
  {
    name: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    numeric: "642",
    subdivisions: [
      { code: "RO-AB", name: "Alba" },
      { code: "RO-AR", name: "Arad" },
      { code: "RO-AG", name: "Argeș" },
      { code: "RO-BC", name: "Bacău" },
      { code: "RO-BH", name: "Bihor" },
      { code: "RO-BN", name: "Bistrița-Năsăud" },
      { code: "RO-BT", name: "Botoșani" },
      { code: "RO-BV", name: "Brașov" },
      { code: "RO-BR", name: "Brăila" },
      { code: "RO-B", name: "București" },
      { code: "RO-BZ", name: "Buzău" },
      { code: "RO-CS", name: "Caraș-Severin" },
      { code: "RO-CJ", name: "Cluj" },
      { code: "RO-CT", name: "Constanța" },
      { code: "RO-CV", name: "Covasna" },
      { code: "RO-CL", name: "Călărași" },
      { code: "RO-DJ", name: "Dolj" },
      { code: "RO-DB", name: "Dâmbovița" },
      { code: "RO-GL", name: "Galați" },
      { code: "RO-GR", name: "Giurgiu" },
      { code: "RO-GJ", name: "Gorj" },
      { code: "RO-HR", name: "Harghita" },
      { code: "RO-HD", name: "Hunedoara" },
      { code: "RO-IL", name: "Ialomița" },
      { code: "RO-IS", name: "Iași" },
      { code: "RO-IF", name: "Ilfov" },
      { code: "RO-MM", name: "Maramureș" },
      { code: "RO-MH", name: "Mehedinți" },
      { code: "RO-MS", name: "Mureș" },
      { code: "RO-NT", name: "Neamț" },
      { code: "RO-OT", name: "Olt" },
      { code: "RO-PH", name: "Prahova" },
      { code: "RO-SM", name: "Satu Mare" },
      { code: "RO-SB", name: "Sibiu" },
      { code: "RO-SV", name: "Suceava" },
      { code: "RO-SJ", name: "Sălaj" },
      { code: "RO-TR", name: "Teleorman" },
      { code: "RO-TM", name: "Timiș" },
      { code: "RO-TL", name: "Tulcea" },
      { code: "RO-VS", name: "Vaslui" },
      { code: "RO-VN", name: "Vrancea" },
      { code: "RO-VL", name: "Vâlcea" },
    ],
  },
  {
    name: "Russian Federation (the)",
    alpha2: "RU",
    alpha3: "RUS",
    numeric: "643",
    subdivisions: [
      { code: "RU-AD", name: "Adygeja, Respublika" },
      { code: "RU-AL", name: "Altaj, Respublika" },
      { code: "RU-ALT", name: "Altajskij kraj" },
      { code: "RU-AMU", name: "Amurskaja oblast'" },
      { code: "RU-ARK", name: "Arhangel'skaja oblast'" },
      { code: "RU-AST", name: "Astrahanskaja oblast'" },
      { code: "RU-BA", name: "Bashkortostan, Respublika" },
      { code: "RU-BEL", name: "Belgorodskaja oblast'" },
      { code: "RU-BRY", name: "Brjanskaja oblast'" },
      { code: "RU-BU", name: "Burjatija, Respublika" },
      { code: "RU-CE", name: "Chechenskaya Respublika" },
      { code: "RU-CHE", name: "Chelyabinskaya oblast'" },
      { code: "RU-CHU", name: "Chukotskiy avtonomnyy okrug" },
      { code: "RU-CU", name: "Chuvashskaya Respublika" },
      { code: "RU-DA", name: "Dagestan, Respublika" },
      { code: "RU-YEV", name: "Evrejskaja avtonomnaja oblast'" },
      { code: "RU-KHA", name: "Habarovskij kraj" },
      { code: "RU-KK", name: "Hakasija, Respublika" },
      { code: "RU-KHM", name: "Hanty-Mansijskij avtonomnyj okrug" },
      { code: "RU-IN", name: "Ingushetiya, Respublika" },
      { code: "RU-IRK", name: "Irkutskaja oblast'" },
      { code: "RU-IVA", name: "Ivanovskaja oblast'" },
      { code: "RU-YAN", name: "Jamalo-Neneckij avtonomnyj okrug" },
      { code: "RU-YAR", name: "Jaroslavskaja oblast'" },
      { code: "RU-KB", name: "Kabardino-Balkarskaja Respublika" },
      { code: "RU-KGD", name: "Kaliningradskaja oblast'" },
      { code: "RU-KL", name: "Kalmykija, Respublika" },
      { code: "RU-KLU", name: "Kaluzhskaya oblast'" },
      { code: "RU-KAM", name: "Kamchatskiy kray" },
      { code: "RU-KC", name: "Karachayevo-Cherkesskaya Respublika" },
      { code: "RU-KR", name: "Karelija, Respublika" },
      { code: "RU-KEM", name: "Kemerovskaja oblast'" },
      { code: "RU-KIR", name: "Kirovskaja oblast'" },
      { code: "RU-KO", name: "Komi, Respublika" },
      { code: "RU-KOS", name: "Kostromskaja oblast'" },
      { code: "RU-KDA", name: "Krasnodarskij kraj" },
      { code: "RU-KYA", name: "Krasnojarskij kraj" },
      { code: "RU-KGN", name: "Kurganskaja oblast'" },
      { code: "RU-KRS", name: "Kurskaja oblast'" },
      { code: "RU-LEN", name: "Leningradskaja oblast'" },
      { code: "RU-LIP", name: "Lipeckaja oblast'" },
      { code: "RU-MAG", name: "Magadanskaja oblast'" },
      { code: "RU-ME", name: "Marij Èl, Respublika" },
      { code: "RU-MO", name: "Mordovija, Respublika" },
      { code: "RU-MOS", name: "Moskovskaja oblast'" },
      { code: "RU-MOW", name: "Moskva" },
      { code: "RU-MUR", name: "Murmanskaja oblast'" },
      { code: "RU-NEN", name: "Neneckij avtonomnyj okrug" },
      { code: "RU-NIZ", name: "Nizhegorodskaya oblast'" },
      { code: "RU-NGR", name: "Novgorodskaja oblast'" },
      { code: "RU-NVS", name: "Novosibirskaja oblast'" },
      { code: "RU-OMS", name: "Omskaja oblast'" },
      { code: "RU-ORE", name: "Orenburgskaja oblast'" },
      { code: "RU-ORL", name: "Orlovskaja oblast'" },
      { code: "RU-PNZ", name: "Penzenskaja oblast'" },
      { code: "RU-PER", name: "Permskij kraj" },
      { code: "RU-PRI", name: "Primorskij kraj" },
      { code: "RU-PSK", name: "Pskovskaja oblast'" },
      { code: "RU-RYA", name: "Rjazanskaja oblast'" },
      { code: "RU-ROS", name: "Rostovskaja oblast'" },
      { code: "RU-SA", name: "Saha, Respublika" },
      { code: "RU-SAK", name: "Sahalinskaja oblast'" },
      { code: "RU-SAM", name: "Samarskaja oblast'" },
      { code: "RU-SPE", name: "Sankt-Peterburg" },
      { code: "RU-SAR", name: "Saratovskaja oblast'" },
      { code: "RU-SE", name: "Severnaja Osetija, Respublika" },
      { code: "RU-SMO", name: "Smolenskaja oblast'" },
      { code: "RU-STA", name: "Stavropol'skij kraj" },
      { code: "RU-SVE", name: "Sverdlovskaja oblast'" },
      { code: "RU-TAM", name: "Tambovskaja oblast'" },
      { code: "RU-TA", name: "Tatarstan, Respublika" },
      { code: "RU-TYU", name: "Tjumenskaja oblast'" },
      { code: "RU-TOM", name: "Tomskaja oblast'" },
      { code: "RU-TUL", name: "Tul'skaja oblast'" },
      { code: "RU-TVE", name: "Tverskaja oblast'" },
      { code: "RU-TY", name: "Tyva, Respublika" },
      { code: "RU-UD", name: "Udmurtskaja Respublika" },
      { code: "RU-ULY", name: "Ul'janovskaja oblast'" },
      { code: "RU-VLA", name: "Vladimirskaja oblast'" },
      { code: "RU-VGG", name: "Volgogradskaja oblast'" },
      { code: "RU-VLG", name: "Vologodskaja oblast'" },
      { code: "RU-VOR", name: "Voronezhskaya oblast'" },
      { code: "RU-ZAB", name: "Zabajkal'skij kraj" },
    ],
  },
  {
    name: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    numeric: "646",
    subdivisions: [
      { code: "RW-03", name: "Amajyaruguru" },
      { code: "RW-05", name: "Amajyepfo" },
      { code: "RW-01", name: "City of Kigali" },
      { code: "RW-02", name: "Eastern" },
      { code: "RW-04", name: "Iburengerazuba" },
    ],
  },
  {
    name: "Réunion",
    alpha2: "RE",
    alpha3: "REU",
    numeric: "638",
    subdivisions: [],
  },
  {
    name: "Saint Barthélemy",
    alpha2: "BL",
    alpha3: "BLM",
    numeric: "652",
    subdivisions: [],
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    alpha3: "SHN",
    numeric: "654",
    subdivisions: [
      { code: "SH-AC", name: "Ascension" },
      { code: "SH-HL", name: "Saint Helena" },
      { code: "SH-TA", name: "Tristan da Cunha" },
    ],
  },
  {
    name: "Saint Kitts and Nevis",
    alpha2: "KN",
    alpha3: "KNA",
    numeric: "659",
    subdivisions: [
      { code: "KN-01", name: "Christ Church Nichola Town" },
      { code: "KN-N", name: "Nevis" },
      { code: "KN-02", name: "Saint Anne Sandy Point" },
      { code: "KN-03", name: "Saint George Basseterre" },
      { code: "KN-04", name: "Saint George Gingerland" },
      { code: "KN-05", name: "Saint James Windward" },
      { code: "KN-06", name: "Saint John Capisterre" },
      { code: "KN-07", name: "Saint John Figtree" },
      { code: "KN-K", name: "Saint Kitts" },
      { code: "KN-08", name: "Saint Mary Cayon" },
      { code: "KN-09", name: "Saint Paul Capisterre" },
      { code: "KN-10", name: "Saint Paul Charlestown" },
      { code: "KN-11", name: "Saint Peter Basseterre" },
      { code: "KN-12", name: "Saint Thomas Lowland" },
      { code: "KN-13", name: "Saint Thomas Middle Island" },
      { code: "KN-15", name: "Trinity Palmetto Point" },
    ],
  },
  {
    name: "Saint Lucia",
    alpha2: "LC",
    alpha3: "LCA",
    numeric: "662",
    subdivisions: [
      { code: "LC-01", name: "Anse la Raye" },
      { code: "LC-12", name: "Canaries" },
      { code: "LC-02", name: "Castries" },
      { code: "LC-03", name: "Choiseul" },
      { code: "LC-05", name: "Dennery" },
      { code: "LC-06", name: "Gros Islet" },
      { code: "LC-07", name: "Laborie" },
      { code: "LC-08", name: "Micoud" },
      { code: "LC-10", name: "Soufrière" },
      { code: "LC-11", name: "Vieux Fort" },
    ],
  },
  {
    name: "Saint Martin (French part)",
    alpha2: "MF",
    alpha3: "MAF",
    numeric: "663",
    subdivisions: [],
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha2: "PM",
    alpha3: "SPM",
    numeric: "666",
    subdivisions: [],
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    alpha3: "VCT",
    numeric: "670",
    subdivisions: [
      { code: "VC-01", name: "Charlotte" },
      { code: "VC-06", name: "Grenadines" },
      { code: "VC-02", name: "Saint Andrew" },
      { code: "VC-03", name: "Saint David" },
      { code: "VC-04", name: "Saint George" },
      { code: "VC-05", name: "Saint Patrick" },
    ],
  },
  {
    name: "Samoa",
    alpha2: "WS",
    alpha3: "WSM",
    numeric: "882",
    subdivisions: [
      { code: "WS-AA", name: "A'ana" },
      { code: "WS-AL", name: "Aiga-i-le-Tai" },
      { code: "WS-AT", name: "Atua" },
      { code: "WS-FA", name: "Fa'asaleleaga" },
      { code: "WS-GE", name: "Gaga'emauga" },
      { code: "WS-GI", name: "Gagaifomauga" },
      { code: "WS-PA", name: "Palauli" },
      { code: "WS-SA", name: "Satupa'itea" },
      { code: "WS-TU", name: "Tuamasaga" },
      { code: "WS-VF", name: "Va'a-o-Fonoti" },
      { code: "WS-VS", name: "Vaisigano" },
    ],
  },
  {
    name: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    numeric: "674",
    subdivisions: [
      { code: "SM-01", name: "Acquaviva" },
      { code: "SM-06", name: "Borgo Maggiore" },
      { code: "SM-02", name: "Chiesanuova" },
      { code: "SM-03", name: "Domagnano" },
      { code: "SM-04", name: "Faetano" },
      { code: "SM-05", name: "Fiorentino" },
      { code: "SM-08", name: "Montegiardino" },
      { code: "SM-07", name: "San Marino" },
      { code: "SM-09", name: "Serravalle" },
    ],
  },
  {
    name: "Sao Tome and Principe",
    alpha2: "ST",
    alpha3: "STP",
    numeric: "678",
    subdivisions: [
      { code: "ST-P", name: "Príncipe" },
      { code: "ST-S", name: "São Tomé" },
    ],
  },
  {
    name: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    numeric: "682",
    subdivisions: [
      { code: "SA-14", name: "'Asīr" },
      { code: "SA-11", name: "Al Bāḩah" },
      { code: "SA-12", name: "Al Jawf" },
      { code: "SA-03", name: "Al Madīnah al Munawwarah" },
      { code: "SA-05", name: "Al Qaşīm" },
      { code: "SA-08", name: "Al Ḩudūd ash Shamālīyah" },
      { code: "SA-01", name: "Ar Riyāḑ" },
      { code: "SA-04", name: "Ash Sharqīyah" },
      { code: "SA-09", name: "Jāzān" },
      { code: "SA-02", name: "Makkah al Mukarramah" },
      { code: "SA-10", name: "Najrān" },
      { code: "SA-07", name: "Tabūk" },
      { code: "SA-06", name: "Ḩā'il" },
    ],
  },
  {
    name: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    numeric: "686",
    subdivisions: [
      { code: "SN-DK", name: "Dakar" },
      { code: "SN-DB", name: "Diourbel" },
      { code: "SN-FK", name: "Fatick" },
      { code: "SN-KA", name: "Kaffrine" },
      { code: "SN-KL", name: "Kaolack" },
      { code: "SN-KD", name: "Kolda" },
      { code: "SN-KE", name: "Kédougou" },
      { code: "SN-LG", name: "Louga" },
      { code: "SN-MT", name: "Matam" },
      { code: "SN-SL", name: "Saint-Louis" },
      { code: "SN-SE", name: "Sédhiou" },
      { code: "SN-TC", name: "Tambacounda" },
      { code: "SN-TH", name: "Thiès" },
      { code: "SN-ZG", name: "Ziguinchor" },
    ],
  },
  {
    name: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    numeric: "688",
    subdivisions: [
      { code: "RS-00", name: "Beograd" },
      { code: "RS-14", name: "Borski okrug" },
      { code: "RS-11", name: "Braničevski okrug" },
      { code: "RS-23", name: "Jablanički okrug" },
      { code: "RS-04", name: "Južnobanatski okrug" },
      { code: "RS-06", name: "Južnobački okrug" },
      { code: "RS-09", name: "Kolubarski okrug" },
      { code: "RS-KM", name: "Kosovo-Metohija" },
      { code: "RS-25", name: "Kosovski okrug" },
      { code: "RS-28", name: "Kosovsko-Mitrovački okrug" },
      { code: "RS-29", name: "Kosovsko-Pomoravski okrug" },
      { code: "RS-08", name: "Mačvanski okrug" },
      { code: "RS-17", name: "Moravički okrug" },
      { code: "RS-20", name: "Nišavski okrug" },
      { code: "RS-26", name: "Pećki okrug" },
      { code: "RS-22", name: "Pirotski okrug" },
      { code: "RS-10", name: "Podunavski okrug" },
      { code: "RS-13", name: "Pomoravski okrug" },
      { code: "RS-27", name: "Prizrenski okrug" },
      { code: "RS-24", name: "Pčinjski okrug" },
      { code: "RS-19", name: "Rasinski okrug" },
      { code: "RS-18", name: "Raški okrug" },
      { code: "RS-03", name: "Severnobanatski okrug" },
      { code: "RS-01", name: "Severnobački okrug" },
      { code: "RS-02", name: "Srednjebanatski okrug" },
      { code: "RS-07", name: "Sremski okrug" },
      { code: "RS-21", name: "Toplički okrug" },
      { code: "RS-VO", name: "Vojvodina" },
      { code: "RS-15", name: "Zaječarski okrug" },
      { code: "RS-05", name: "Zapadnobački okrug" },
      { code: "RS-16", name: "Zlatiborski okrug" },
      { code: "RS-12", name: "Šumadijski okrug" },
    ],
  },
  {
    name: "Seychelles",
    alpha2: "SC",
    alpha3: "SYC",
    numeric: "690",
    subdivisions: [
      { code: "SC-02", name: "Ans Bwalo" },
      { code: "SC-03", name: "Ans Etwal" },
      { code: "SC-05", name: "Ans Royal" },
      { code: "SC-01", name: "Ans o Pen" },
      { code: "SC-04", name: "Au Cap" },
      { code: "SC-06", name: "Baie Lazare" },
      { code: "SC-07", name: "Baie Sainte Anne" },
      { code: "SC-08", name: "Beau Vallon" },
      { code: "SC-09", name: "Bel Air" },
      { code: "SC-10", name: "Bel Ombre" },
      { code: "SC-11", name: "Cascade" },
      { code: "SC-16", name: "English River" },
      { code: "SC-12", name: "Glacis" },
      { code: "SC-13", name: "Grand Ans Mae" },
      { code: "SC-14", name: "Grand Ans Pralen" },
      { code: "SC-15", name: "La Digue" },
      { code: "SC-24", name: "Lemamel" },
      { code: "SC-17", name: "Mon Bikston" },
      { code: "SC-18", name: "Mon Fleri" },
      { code: "SC-19", name: "Plaisance" },
      { code: "SC-20", name: "Pointe La Rue" },
      { code: "SC-21", name: "Porglo" },
      { code: "SC-25", name: "Roche Caiman" },
      { code: "SC-22", name: "Saint Louis" },
      { code: "SC-23", name: "Takamaka" },
    ],
  },
  {
    name: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    numeric: "694",
    subdivisions: [
      { code: "SL-E", name: "Eastern" },
      { code: "SL-NW", name: "North Western" },
      { code: "SL-N", name: "Northern" },
      { code: "SL-S", name: "Southern" },
      { code: "SL-W", name: "Western Area (Freetown)" },
    ],
  },
  {
    name: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    numeric: "702",
    subdivisions: [
      { code: "SG-01", name: "Central Singapore" },
      { code: "SG-02", name: "North East" },
      { code: "SG-03", name: "North West" },
      { code: "SG-04", name: "South East" },
      { code: "SG-05", name: "South West" },
    ],
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha2: "SX",
    alpha3: "SXM",
    numeric: "534",
    subdivisions: [],
  },
  {
    name: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    numeric: "703",
    subdivisions: [
      { code: "SK-BC", name: "Banskobystrický kraj" },
      { code: "SK-BL", name: "Bratislavský kraj" },
      { code: "SK-KI", name: "Košický kraj" },
      { code: "SK-NI", name: "Nitriansky kraj" },
      { code: "SK-PV", name: "Prešovský kraj" },
      { code: "SK-TC", name: "Trenčiansky kraj" },
      { code: "SK-TA", name: "Trnavský kraj" },
      { code: "SK-ZI", name: "Žilinský kraj" },
    ],
  },
  {
    name: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    numeric: "705",
    subdivisions: [
      { code: "SI-001", name: "Ajdovščina" },
      { code: "SI-213", name: "Ankaran" },
      { code: "SI-195", name: "Apače" },
      { code: "SI-002", name: "Beltinci" },
      { code: "SI-148", name: "Benedikt" },
      { code: "SI-149", name: "Bistrica ob Sotli" },
      { code: "SI-003", name: "Bled" },
      { code: "SI-150", name: "Bloke" },
      { code: "SI-004", name: "Bohinj" },
      { code: "SI-005", name: "Borovnica" },
      { code: "SI-006", name: "Bovec" },
      { code: "SI-151", name: "Braslovče" },
      { code: "SI-007", name: "Brda" },
      { code: "SI-008", name: "Brezovica" },
      { code: "SI-009", name: "Brežice" },
      { code: "SI-152", name: "Cankova" },
      { code: "SI-011", name: "Celje" },
      { code: "SI-012", name: "Cerklje na Gorenjskem" },
      { code: "SI-013", name: "Cerknica" },
      { code: "SI-014", name: "Cerkno" },
      { code: "SI-153", name: "Cerkvenjak" },
      { code: "SI-196", name: "Cirkulane" },
      { code: "SI-018", name: "Destrnik" },
      { code: "SI-019", name: "Divača" },
      { code: "SI-154", name: "Dobje" },
      { code: "SI-020", name: "Dobrepolje" },
      { code: "SI-155", name: "Dobrna" },
      { code: "SI-021", name: "Dobrova-Polhov Gradec" },
      { code: "SI-156", name: "Dobrovnik" },
      { code: "SI-022", name: "Dol pri Ljubljani" },
      { code: "SI-157", name: "Dolenjske Toplice" },
      { code: "SI-023", name: "Domžale" },
      { code: "SI-024", name: "Dornava" },
      { code: "SI-025", name: "Dravograd" },
      { code: "SI-026", name: "Duplek" },
      { code: "SI-027", name: "Gorenja vas-Poljane" },
      { code: "SI-028", name: "Gorišnica" },
      { code: "SI-207", name: "Gorje" },
      { code: "SI-029", name: "Gornja Radgona" },
      { code: "SI-030", name: "Gornji Grad" },
      { code: "SI-031", name: "Gornji Petrovci" },
      { code: "SI-158", name: "Grad" },
      { code: "SI-032", name: "Grosuplje" },
      { code: "SI-159", name: "Hajdina" },
      { code: "SI-161", name: "Hodoš" },
      { code: "SI-162", name: "Horjul" },
      { code: "SI-160", name: "Hoče-Slivnica" },
      { code: "SI-034", name: "Hrastnik" },
      { code: "SI-035", name: "Hrpelje-Kozina" },
      { code: "SI-036", name: "Idrija" },
      { code: "SI-037", name: "Ig" },
      { code: "SI-038", name: "Ilirska Bistrica" },
      { code: "SI-039", name: "Ivančna Gorica" },
      { code: "SI-040", name: "Izola" },
      { code: "SI-041", name: "Jesenice" },
      { code: "SI-163", name: "Jezersko" },
      { code: "SI-042", name: "Juršinci" },
      { code: "SI-043", name: "Kamnik" },
      { code: "SI-044", name: "Kanal" },
      { code: "SI-045", name: "Kidričevo" },
      { code: "SI-046", name: "Kobarid" },
      { code: "SI-047", name: "Kobilje" },
      { code: "SI-049", name: "Komen" },
      { code: "SI-164", name: "Komenda" },
      { code: "SI-050", name: "Koper" },
      { code: "SI-197", name: "Kosanjevica na Krki" },
      { code: "SI-165", name: "Kostel" },
      { code: "SI-051", name: "Kozje" },
      { code: "SI-048", name: "Kočevje" },
      { code: "SI-052", name: "Kranj" },
      { code: "SI-053", name: "Kranjska Gora" },
      { code: "SI-166", name: "Križevci" },
      { code: "SI-054", name: "Krško" },
      { code: "SI-055", name: "Kungota" },
      { code: "SI-056", name: "Kuzma" },
      { code: "SI-057", name: "Laško" },
      { code: "SI-058", name: "Lenart" },
      { code: "SI-059", name: "Lendava" },
      { code: "SI-060", name: "Litija" },
      { code: "SI-061", name: "Ljubljana" },
      { code: "SI-062", name: "Ljubno" },
      { code: "SI-063", name: "Ljutomer" },
      { code: "SI-208", name: "Log-Dragomer" },
      { code: "SI-064", name: "Logatec" },
      { code: "SI-167", name: "Lovrenc na Pohorju" },
      { code: "SI-065", name: "Loška Dolina" },
      { code: "SI-066", name: "Loški Potok" },
      { code: "SI-068", name: "Lukovica" },
      { code: "SI-067", name: "Luče" },
      { code: "SI-069", name: "Majšperk" },
      { code: "SI-198", name: "Makole" },
      { code: "SI-070", name: "Maribor" },
      { code: "SI-168", name: "Markovci" },
      { code: "SI-071", name: "Medvode" },
      { code: "SI-072", name: "Mengeš" },
      { code: "SI-073", name: "Metlika" },
      { code: "SI-074", name: "Mežica" },
      { code: "SI-169", name: "Miklavž na Dravskem Polju" },
      { code: "SI-075", name: "Miren-Kostanjevica" },
      { code: "SI-212", name: "Mirna" },
      { code: "SI-170", name: "Mirna Peč" },
      { code: "SI-076", name: "Mislinja" },
      { code: "SI-199", name: "Mokronog-Trebelno" },
      { code: "SI-078", name: "Moravske Toplice" },
      { code: "SI-077", name: "Moravče" },
      { code: "SI-079", name: "Mozirje" },
      { code: "SI-080", name: "Murska Sobota" },
      { code: "SI-081", name: "Muta" },
      { code: "SI-082", name: "Naklo" },
      { code: "SI-083", name: "Nazarje" },
      { code: "SI-084", name: "Nova Gorica" },
      { code: "SI-085", name: "Novo Mesto" },
      { code: "SI-086", name: "Odranci" },
      { code: "SI-171", name: "Oplotnica" },
      { code: "SI-087", name: "Ormož" },
      { code: "SI-088", name: "Osilnica" },
      { code: "SI-089", name: "Pesnica" },
      { code: "SI-090", name: "Piran" },
      { code: "SI-091", name: "Pivka" },
      { code: "SI-172", name: "Podlehnik" },
      { code: "SI-093", name: "Podvelka" },
      { code: "SI-092", name: "Podčetrtek" },
      { code: "SI-200", name: "Poljčane" },
      { code: "SI-173", name: "Polzela" },
      { code: "SI-094", name: "Postojna" },
      { code: "SI-174", name: "Prebold" },
      { code: "SI-095", name: "Preddvor" },
      { code: "SI-175", name: "Prevalje" },
      { code: "SI-096", name: "Ptuj" },
      { code: "SI-097", name: "Puconci" },
      { code: "SI-100", name: "Radenci" },
      { code: "SI-099", name: "Radeče" },
      { code: "SI-101", name: "Radlje ob Dravi" },
      { code: "SI-102", name: "Radovljica" },
      { code: "SI-103", name: "Ravne na Koroškem" },
      { code: "SI-176", name: "Razkrižje" },
      { code: "SI-098", name: "Rače-Fram" },
      { code: "SI-201", name: "Renče-Vogrsko" },
      { code: "SI-209", name: "Rečica ob Savinji" },
      { code: "SI-104", name: "Ribnica" },
      { code: "SI-177", name: "Ribnica na Pohorju" },
      { code: "SI-107", name: "Rogatec" },
      { code: "SI-106", name: "Rogaška Slatina" },
      { code: "SI-105", name: "Rogašovci" },
      { code: "SI-108", name: "Ruše" },
      { code: "SI-178", name: "Selnica ob Dravi" },
      { code: "SI-109", name: "Semič" },
      { code: "SI-110", name: "Sevnica" },
      { code: "SI-111", name: "Sežana" },
      { code: "SI-112", name: "Slovenj Gradec" },
      { code: "SI-113", name: "Slovenska Bistrica" },
      { code: "SI-114", name: "Slovenske Konjice" },
      { code: "SI-179", name: "Sodražica" },
      { code: "SI-180", name: "Solčava" },
      { code: "SI-202", name: "Središče ob Dravi" },
      { code: "SI-115", name: "Starše" },
      { code: "SI-203", name: "Straža" },
      { code: "SI-181", name: "Sveta Ana" },
      { code: "SI-204", name: "Sveta Trojica v Slovenskih Goricah" },
      { code: "SI-182", name: "Sveti Andraž v Slovenskih Goricah" },
      { code: "SI-116", name: "Sveti Jurij" },
      { code: "SI-210", name: "Sveti Jurij v Slovenskih Goricah" },
      { code: "SI-205", name: "Sveti Tomaž" },
      { code: "SI-184", name: "Tabor" },
      { code: "SI-010", name: "Tišina" },
      { code: "SI-128", name: "Tolmin" },
      { code: "SI-129", name: "Trbovlje" },
      { code: "SI-130", name: "Trebnje" },
      { code: "SI-185", name: "Trnovska Vas" },
      { code: "SI-186", name: "Trzin" },
      { code: "SI-131", name: "Tržič" },
      { code: "SI-132", name: "Turnišče" },
      { code: "SI-133", name: "Velenje" },
      { code: "SI-187", name: "Velika Polana" },
      { code: "SI-134", name: "Velike Lašče" },
      { code: "SI-188", name: "Veržej" },
      { code: "SI-135", name: "Videm" },
      { code: "SI-136", name: "Vipava" },
      { code: "SI-137", name: "Vitanje" },
      { code: "SI-138", name: "Vodice" },
      { code: "SI-139", name: "Vojnik" },
      { code: "SI-189", name: "Vransko" },
      { code: "SI-140", name: "Vrhnika" },
      { code: "SI-141", name: "Vuzenica" },
      { code: "SI-142", name: "Zagorje ob Savi" },
      { code: "SI-143", name: "Zavrč" },
      { code: "SI-144", name: "Zreče" },
      { code: "SI-015", name: "Črenšovci" },
      { code: "SI-016", name: "Črna na Koroškem" },
      { code: "SI-017", name: "Črnomelj" },
      { code: "SI-033", name: "Šalovci" },
      { code: "SI-183", name: "Šempeter-Vrtojba" },
      { code: "SI-118", name: "Šentilj" },
      { code: "SI-119", name: "Šentjernej" },
      { code: "SI-120", name: "Šentjur" },
      { code: "SI-211", name: "Šentrupert" },
      { code: "SI-117", name: "Šenčur" },
      { code: "SI-121", name: "Škocjan" },
      { code: "SI-122", name: "Škofja Loka" },
      { code: "SI-123", name: "Škofljica" },
      { code: "SI-124", name: "Šmarje pri Jelšah" },
      { code: "SI-206", name: "Šmarješke Toplice" },
      { code: "SI-125", name: "Šmartno ob Paki" },
      { code: "SI-194", name: "Šmartno pri Litiji" },
      { code: "SI-126", name: "Šoštanj" },
      { code: "SI-127", name: "Štore" },
      { code: "SI-190", name: "Žalec" },
      { code: "SI-146", name: "Železniki" },
      { code: "SI-191", name: "Žetale" },
      { code: "SI-147", name: "Žiri" },
      { code: "SI-192", name: "Žirovnica" },
      { code: "SI-193", name: "Žužemberk" },
    ],
  },
  {
    name: "Solomon Islands",
    alpha2: "SB",
    alpha3: "SLB",
    numeric: "090",
    subdivisions: [
      { code: "SB-CT", name: "Capital Territory (Honiara)" },
      { code: "SB-CE", name: "Central" },
      { code: "SB-CH", name: "Choiseul" },
      { code: "SB-GU", name: "Guadalcanal" },
      { code: "SB-IS", name: "Isabel" },
      { code: "SB-MK", name: "Makira-Ulawa" },
      { code: "SB-ML", name: "Malaita" },
      { code: "SB-RB", name: "Rennell and Bellona" },
      { code: "SB-TE", name: "Temotu" },
      { code: "SB-WE", name: "Western" },
    ],
  },
  {
    name: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    numeric: "706",
    subdivisions: [
      { code: "SO-AW", name: "Awdal" },
      { code: "SO-BK", name: "Bakool" },
      { code: "SO-BN", name: "Banaadir" },
      { code: "SO-BR", name: "Bari" },
      { code: "SO-BY", name: "Bay" },
      { code: "SO-GA", name: "Galguduud" },
      { code: "SO-GE", name: "Gedo" },
      { code: "SO-HI", name: "Hiiraan" },
      { code: "SO-JD", name: "Jubbada Dhexe" },
      { code: "SO-JH", name: "Jubbada Hoose" },
      { code: "SO-MU", name: "Mudug" },
      { code: "SO-NU", name: "Nugaal" },
      { code: "SO-SA", name: "Sanaag" },
      { code: "SO-SD", name: "Shabeellaha Dhexe" },
      { code: "SO-SH", name: "Shabeellaha Hoose" },
      { code: "SO-SO", name: "Sool" },
      { code: "SO-TO", name: "Togdheer" },
      { code: "SO-WO", name: "Woqooyi Galbeed" },
    ],
  },
  {
    name: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    numeric: "710",
    subdivisions: [
      { code: "ZA-NW", name: "Bokone Bophirima" },
      { code: "ZA-EC", name: "Eastern Cape" },
      { code: "ZA-FS", name: "Foreisetata" },
      { code: "ZA-KZN", name: "GaZulu-Natala" },
      { code: "ZA-GP", name: "Gauteng" },
      { code: "ZA-WC", name: "Kapa Bodikela" },
      { code: "ZA-NC", name: "Kapa Bokone" },
      { code: "ZA-LP", name: "Limpopo" },
      { code: "ZA-MP", name: "Mpumalanga" },
    ],
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha2: "GS",
    alpha3: "SGS",
    numeric: "239",
    subdivisions: [],
  },
  {
    name: "South Sudan",
    alpha2: "SS",
    alpha3: "SSD",
    numeric: "728",
    subdivisions: [
      { code: "SS-EC", name: "Central Equatoria" },
      { code: "SS-EE", name: "Eastern Equatoria" },
      { code: "SS-JG", name: "Jonglei" },
      { code: "SS-LK", name: "Lakes" },
      { code: "SS-BN", name: "Northern Bahr el Ghazal" },
      { code: "SS-UY", name: "Unity" },
      { code: "SS-NU", name: "Upper Nile" },
      { code: "SS-WR", name: "Warrap" },
      { code: "SS-BW", name: "Western Bahr el Ghazal" },
      { code: "SS-EW", name: "Western Equatoria" },
    ],
  },
  {
    name: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    numeric: "724",
    subdivisions: [
      { code: "ES-C", name: "A Coruña [La Coruña]" },
      { code: "ES-A", name: "Alacant*" },
      { code: "ES-AB", name: "Albacete" },
      { code: "ES-AL", name: "Almería" },
      { code: "ES-AN", name: "Andalucía" },
      { code: "ES-VI", name: "Araba*" },
      { code: "ES-AR", name: "Aragón" },
      { code: "ES-O", name: "Asturias" },
      { code: "ES-AS", name: "Asturias, Principado de" },
      { code: "ES-BA", name: "Badajoz" },
      { code: "ES-PM", name: "Balears [Baleares]" },
      { code: "ES-B", name: "Barcelona [Barcelona]" },
      { code: "ES-BI", name: "Bizkaia" },
      { code: "ES-BU", name: "Burgos" },
      { code: "ES-CN", name: "Canarias" },
      { code: "ES-CB", name: "Cantabria" },
      { code: "ES-S", name: "Cantabria" },
      { code: "ES-CS", name: "Castelló*" },
      { code: "ES-CL", name: "Castilla y León" },
      { code: "ES-CM", name: "Castilla-La Mancha" },
      { code: "ES-CT", name: "Catalunya [Cataluña]" },
      { code: "ES-CE", name: "Ceuta" },
      { code: "ES-CR", name: "Ciudad Real" },
      { code: "ES-CU", name: "Cuenca" },
      { code: "ES-CC", name: "Cáceres" },
      { code: "ES-CA", name: "Cádiz" },
      { code: "ES-CO", name: "Córdoba" },
      { code: "ES-PV", name: "Euskal Herria" },
      { code: "ES-EX", name: "Extremadura" },
      { code: "ES-GA", name: "Galicia [Galicia]" },
      { code: "ES-SS", name: "Gipuzkoa" },
      { code: "ES-GI", name: "Girona [Gerona]" },
      { code: "ES-GR", name: "Granada" },
      { code: "ES-GU", name: "Guadalajara" },
      { code: "ES-H", name: "Huelva" },
      { code: "ES-HU", name: "Huesca" },
      { code: "ES-IB", name: "Illes Balears [Islas Baleares]" },
      { code: "ES-J", name: "Jaén" },
      { code: "ES-RI", name: "La Rioja" },
      { code: "ES-LO", name: "La Rioja" },
      { code: "ES-GC", name: "Las Palmas" },
      { code: "ES-LE", name: "León" },
      { code: "ES-L", name: "Lleida [Lérida]" },
      { code: "ES-LU", name: "Lugo [Lugo]" },
      { code: "ES-M", name: "Madrid" },
      { code: "ES-MD", name: "Madrid, Comunidad de" },
      { code: "ES-ML", name: "Melilla" },
      { code: "ES-MU", name: "Murcia" },
      { code: "ES-MC", name: "Murcia, Región de" },
      { code: "ES-MA", name: "Málaga" },
      { code: "ES-NA", name: "Nafarroa*" },
      { code: "ES-NC", name: "Nafarroako Foru Komunitatea*" },
      { code: "ES-OR", name: "Ourense [Orense]" },
      { code: "ES-P", name: "Palencia" },
      { code: "ES-PO", name: "Pontevedra [Pontevedra]" },
      { code: "ES-SA", name: "Salamanca" },
      { code: "ES-TF", name: "Santa Cruz de Tenerife" },
      { code: "ES-SG", name: "Segovia" },
      { code: "ES-SE", name: "Sevilla" },
      { code: "ES-SO", name: "Soria" },
      { code: "ES-T", name: "Tarragona [Tarragona]" },
      { code: "ES-TE", name: "Teruel" },
      { code: "ES-TO", name: "Toledo" },
      { code: "ES-V", name: "Valencia" },
      { code: "ES-VC", name: "Valenciana, Comunidad" },
      { code: "ES-VA", name: "Valladolid" },
      { code: "ES-ZA", name: "Zamora" },
      { code: "ES-Z", name: "Zaragoza" },
      { code: "ES-AV", name: "Ávila" },
    ],
  },
  {
    name: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    numeric: "144",
    subdivisions: [
      { code: "LK-52", name: "Ampara" },
      { code: "LK-33", name: "Ampāntōṭṭai" },
      { code: "LK-71", name: "Anuradhapura" },
      { code: "LK-81", name: "Badulla" },
      { code: "LK-1", name: "Basnāhira paḷāta" },
      { code: "LK-51", name: "Batticaloa" },
      { code: "LK-2", name: "Central Province" },
      { code: "LK-9", name: "Chappirakamuva mākāṇam" },
      { code: "LK-11", name: "Colombo" },
      { code: "LK-3", name: "Dakuṇu paḷāta" },
      { code: "LK-5", name: "Eastern Province" },
      { code: "LK-31", name: "Galle" },
      { code: "LK-12", name: "Gampaha" },
      { code: "LK-91", name: "Irattiṉapuri" },
      { code: "LK-41", name: "Jaffna" },
      { code: "LK-13", name: "Kalutara" },
      { code: "LK-21", name: "Kandy" },
      { code: "LK-92", name: "Kegalla" },
      { code: "LK-42", name: "Kilinochchi" },
      { code: "LK-61", name: "Kurunegala" },
      { code: "LK-43", name: "Mannar" },
      { code: "LK-22", name: "Matale" },
      { code: "LK-32", name: "Matara" },
      { code: "LK-82", name: "Monaragala" },
      { code: "LK-45", name: "Mulativ" },
      { code: "LK-7", name: "North Central Province" },
      { code: "LK-6", name: "North Western Province" },
      { code: "LK-4", name: "Northern Province" },
      { code: "LK-23", name: "Nuvara Ĕliya" },
      { code: "LK-72", name: "Polonnaruwa" },
      { code: "LK-62", name: "Puttalam" },
      { code: "LK-53", name: "Tirukŏṇamalai" },
      { code: "LK-8", name: "Uva Province" },
      { code: "LK-44", name: "Vavuniya" },
    ],
  },
  {
    name: "Sudan (the)",
    alpha2: "SD",
    alpha3: "SDN",
    numeric: "729",
    subdivisions: [
      { code: "SD-RS", name: "Al Baḩr al Aḩmar" },
      { code: "SD-GZ", name: "Al Jazīrah" },
      { code: "SD-KH", name: "Al Kharţūm" },
      { code: "SD-GD", name: "Al Qaḑārif" },
      { code: "SD-NW", name: "An Nīl al Abyaḑ" },
      { code: "SD-NB", name: "An Nīl al Azraq" },
      { code: "SD-NO", name: "Ash Shamālīyah" },
      { code: "SD-DC", name: "Central Darfur" },
      { code: "SD-DE", name: "East Darfur" },
      { code: "SD-DW", name: "Gharb Dārfūr" },
      { code: "SD-GK", name: "Gharb Kurdufān" },
      { code: "SD-DS", name: "Janūb Dārfūr" },
      { code: "SD-KS", name: "Janūb Kurdufān" },
      { code: "SD-KA", name: "Kassala" },
      { code: "SD-NR", name: "Nahr an Nīl" },
      { code: "SD-DN", name: "North Darfur" },
      { code: "SD-KN", name: "North Kordofan" },
      { code: "SD-SI", name: "Sennar" },
    ],
  },
  {
    name: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    numeric: "740",
    subdivisions: [
      { code: "SR-BR", name: "Brokopondo" },
      { code: "SR-CM", name: "Commewijne" },
      { code: "SR-CR", name: "Coronie" },
      { code: "SR-MA", name: "Marowijne" },
      { code: "SR-NI", name: "Nickerie" },
      { code: "SR-PR", name: "Para" },
      { code: "SR-PM", name: "Paramaribo" },
      { code: "SR-SA", name: "Saramacca" },
      { code: "SR-SI", name: "Sipaliwini" },
      { code: "SR-WA", name: "Wanica" },
    ],
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha2: "SJ",
    alpha3: "SJM",
    numeric: "744",
    subdivisions: [],
  },
  {
    name: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    numeric: "752",
    subdivisions: [
      { code: "SE-K", name: "Blekinge län [SE-10]" },
      { code: "SE-W", name: "Dalarnas län [SE-20]" },
      { code: "SE-I", name: "Gotlands län [SE-09]" },
      { code: "SE-X", name: "Gävleborgs län [SE-21]" },
      { code: "SE-N", name: "Hallands län [SE-13]" },
      { code: "SE-Z", name: "Jämtlands län [SE-23]" },
      { code: "SE-F", name: "Jönköpings län [SE-06]" },
      { code: "SE-H", name: "Kalmar län [SE-08]" },
      { code: "SE-G", name: "Kronobergs län [SE-07]" },
      { code: "SE-BD", name: "Norrbottens län [SE-25]" },
      { code: "SE-M", name: "Skåne län [SE-12]" },
      { code: "SE-AB", name: "Stockholms län [SE-01]" },
      { code: "SE-D", name: "Södermanlands län [SE-04]" },
      { code: "SE-C", name: "Uppsala län [SE-03]" },
      { code: "SE-S", name: "Värmlands län [SE-17]" },
      { code: "SE-AC", name: "Västerbottens län [SE-24]" },
      { code: "SE-Y", name: "Västernorrlands län [SE-22]" },
      { code: "SE-U", name: "Västmanlands län [SE-19]" },
      { code: "SE-O", name: "Västra Götalands län [SE-14]" },
      { code: "SE-T", name: "Örebro län [SE-18]" },
      { code: "SE-E", name: "Östergötlands län [SE-05]" },
    ],
  },
  {
    name: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    numeric: "756",
    subdivisions: [
      { code: "CH-AG", name: "Aargau" },
      { code: "CH-AR", name: "Appenzell Ausserrhoden" },
      { code: "CH-AI", name: "Appenzell Innerrhoden" },
      { code: "CH-BL", name: "Basel-Landschaft" },
      { code: "CH-BS", name: "Basel-Stadt" },
      { code: "CH-BE", name: "Bern" },
      { code: "CH-FR", name: "Freiburg" },
      { code: "CH-GE", name: "Genève" },
      { code: "CH-GL", name: "Glarus" },
      { code: "CH-GR", name: "Graubünden" },
      { code: "CH-JU", name: "Jura" },
      { code: "CH-LU", name: "Luzern" },
      { code: "CH-NE", name: "Neuchâtel" },
      { code: "CH-NW", name: "Nidwalden" },
      { code: "CH-OW", name: "Obwalden" },
      { code: "CH-SG", name: "Sankt Gallen" },
      { code: "CH-SH", name: "Schaffhausen" },
      { code: "CH-SZ", name: "Schwyz" },
      { code: "CH-SO", name: "Solothurn" },
      { code: "CH-TG", name: "Thurgau" },
      { code: "CH-TI", name: "Ticino" },
      { code: "CH-UR", name: "Uri" },
      { code: "CH-VS", name: "Valais" },
      { code: "CH-VD", name: "Vaud" },
      { code: "CH-ZG", name: "Zug" },
      { code: "CH-ZH", name: "Zürich" },
    ],
  },
  {
    name: "Syrian Arab Republic (the)",
    alpha2: "SY",
    alpha3: "SYR",
    numeric: "760",
    subdivisions: [
      { code: "SY-LA", name: "Al Lādhiqīyah" },
      { code: "SY-QU", name: "Al Qunayţirah" },
      { code: "SY-HA", name: "Al Ḩasakah" },
      { code: "SY-RA", name: "Ar Raqqah" },
      { code: "SY-SU", name: "As Suwaydā'" },
      { code: "SY-DR", name: "Dar'ā" },
      { code: "SY-DY", name: "Dayr az Zawr" },
      { code: "SY-DI", name: "Dimashq" },
      { code: "SY-ID", name: "Idlib" },
      { code: "SY-RD", name: "Rīf Dimashq" },
      { code: "SY-TA", name: "Ţarţūs" },
      { code: "SY-HL", name: "Ḩalab" },
      { code: "SY-HM", name: "Ḩamāh" },
      { code: "SY-HI", name: "Ḩimş" },
    ],
  },
  {
    name: "Taiwan (Province of China)",
    alpha2: "TW",
    alpha3: "TWN",
    numeric: "158",
    subdivisions: [
      { code: "TW-CHA", name: "Changhua" },
      { code: "TW-CYI", name: "Chiayi" },
      { code: "TW-CYQ", name: "Chiayi" },
      { code: "TW-HSZ", name: "Hsinchu" },
      { code: "TW-HSQ", name: "Hsinchu" },
      { code: "TW-HUA", name: "Hualien" },
      { code: "TW-KHH", name: "Kaohsiung" },
      { code: "TW-KEE", name: "Keelung" },
      { code: "TW-KIN", name: "Kinmen" },
      { code: "TW-LIE", name: "Lienchiang" },
      { code: "TW-MIA", name: "Miaoli" },
      { code: "TW-NAN", name: "Nantou" },
      { code: "TW-NWT", name: "New Taipei" },
      { code: "TW-PEN", name: "Penghu" },
      { code: "TW-PIF", name: "Pingtung" },
      { code: "TW-TXG", name: "Taichung" },
      { code: "TW-TNN", name: "Tainan" },
      { code: "TW-TPE", name: "Taipei" },
      { code: "TW-TTT", name: "Taitung" },
      { code: "TW-TAO", name: "Taoyuan" },
      { code: "TW-ILA", name: "Yilan" },
      { code: "TW-YUN", name: "Yunlin" },
    ],
  },
  {
    name: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    numeric: "762",
    subdivisions: [
      { code: "TJ-DU", name: "Dushanbe" },
      { code: "TJ-KT", name: "Khatlon" },
      { code: "TJ-GB", name: "Kŭhistoni Badakhshon" },
      { code: "TJ-SU", name: "Sughd" },
      { code: "TJ-RA", name: "nohiyahoi tobei jumhurí" },
    ],
  },
  {
    name: "Tanzania, the United Republic of",
    alpha2: "TZ",
    alpha3: "TZA",
    numeric: "834",
    subdivisions: [
      { code: "TZ-01", name: "Arusha" },
      { code: "TZ-19", name: "Coast" },
      { code: "TZ-02", name: "Dar es Salaam" },
      { code: "TZ-03", name: "Dodoma" },
      { code: "TZ-27", name: "Geita" },
      { code: "TZ-04", name: "Iringa" },
      { code: "TZ-05", name: "Kagera" },
      { code: "TZ-06", name: "Kaskazini Pemba" },
      { code: "TZ-07", name: "Kaskazini Unguja" },
      { code: "TZ-28", name: "Katavi" },
      { code: "TZ-08", name: "Kigoma" },
      { code: "TZ-09", name: "Kilimanjaro" },
      { code: "TZ-10", name: "Kusini Pemba" },
      { code: "TZ-11", name: "Kusini Unguja" },
      { code: "TZ-12", name: "Lindi" },
      { code: "TZ-26", name: "Manyara" },
      { code: "TZ-13", name: "Mara" },
      { code: "TZ-14", name: "Mbeya" },
      { code: "TZ-15", name: "Mjini Magharibi" },
      { code: "TZ-16", name: "Morogoro" },
      { code: "TZ-17", name: "Mtwara" },
      { code: "TZ-18", name: "Mwanza" },
      { code: "TZ-29", name: "Njombe" },
      { code: "TZ-20", name: "Rukwa" },
      { code: "TZ-21", name: "Ruvuma" },
      { code: "TZ-22", name: "Shinyanga" },
      { code: "TZ-30", name: "Simiyu" },
      { code: "TZ-23", name: "Singida" },
      { code: "TZ-31", name: "Songwe" },
      { code: "TZ-24", name: "Tabora" },
      { code: "TZ-25", name: "Tanga" },
    ],
  },
  {
    name: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    numeric: "764",
    subdivisions: [
      { code: "TH-37", name: "Amnat Charoen" },
      { code: "TH-15", name: "Ang Thong" },
      { code: "TH-38", name: "Bueng Kan" },
      { code: "TH-31", name: "Buri Ram" },
      { code: "TH-24", name: "Chachoengsao" },
      { code: "TH-18", name: "Chai Nat" },
      { code: "TH-36", name: "Chaiyaphum" },
      { code: "TH-22", name: "Chanthaburi" },
      { code: "TH-50", name: "Chiang Mai" },
      { code: "TH-57", name: "Chiang Rai" },
      { code: "TH-20", name: "Chon Buri" },
      { code: "TH-86", name: "Chumphon" },
      { code: "TH-46", name: "Kalasin" },
      { code: "TH-62", name: "Kamphaeng Phet" },
      { code: "TH-71", name: "Kanchanaburi" },
      { code: "TH-40", name: "Khon Kaen" },
      { code: "TH-81", name: "Krabi" },
      { code: "TH-10", name: "Krung Thep Maha Nakhon" },
      { code: "TH-52", name: "Lampang" },
      { code: "TH-51", name: "Lamphun" },
      { code: "TH-42", name: "Loei" },
      { code: "TH-16", name: "Lop Buri" },
      { code: "TH-58", name: "Mae Hong Son" },
      { code: "TH-44", name: "Maha Sarakham" },
      { code: "TH-49", name: "Mukdahan" },
      { code: "TH-26", name: "Nakhon Nayok" },
      { code: "TH-73", name: "Nakhon Pathom" },
      { code: "TH-48", name: "Nakhon Phanom" },
      { code: "TH-30", name: "Nakhon Ratchasima" },
      { code: "TH-60", name: "Nakhon Sawan" },
      { code: "TH-80", name: "Nakhon Si Thammarat" },
      { code: "TH-55", name: "Nan" },
      { code: "TH-96", name: "Narathiwat" },
      { code: "TH-39", name: "Nong Bua Lam Phu" },
      { code: "TH-43", name: "Nong Khai" },
      { code: "TH-12", name: "Nonthaburi" },
      { code: "TH-13", name: "Pathum Thani" },
      { code: "TH-94", name: "Pattani" },
      { code: "TH-82", name: "Phangnga" },
      { code: "TH-93", name: "Phatthalung" },
      { code: "TH-S", name: "Phatthaya" },
      { code: "TH-56", name: "Phayao" },
      { code: "TH-67", name: "Phetchabun" },
      { code: "TH-76", name: "Phetchaburi" },
      { code: "TH-66", name: "Phichit" },
      { code: "TH-65", name: "Phitsanulok" },
      { code: "TH-14", name: "Phra Nakhon Si Ayutthaya" },
      { code: "TH-54", name: "Phrae" },
      { code: "TH-83", name: "Phuket" },
      { code: "TH-25", name: "Prachin Buri" },
      { code: "TH-77", name: "Prachuap Khiri Khan" },
      { code: "TH-85", name: "Ranong" },
      { code: "TH-70", name: "Ratchaburi" },
      { code: "TH-21", name: "Rayong" },
      { code: "TH-45", name: "Roi Et" },
      { code: "TH-27", name: "Sa Kaeo" },
      { code: "TH-47", name: "Sakon Nakhon" },
      { code: "TH-11", name: "Samut Prakan" },
      { code: "TH-74", name: "Samut Sakhon" },
      { code: "TH-75", name: "Samut Songkhram" },
      { code: "TH-19", name: "Saraburi" },
      { code: "TH-91", name: "Satun" },
      { code: "TH-33", name: "Si Sa Ket" },
      { code: "TH-17", name: "Sing Buri" },
      { code: "TH-90", name: "Songkhla" },
      { code: "TH-64", name: "Sukhothai" },
      { code: "TH-72", name: "Suphan Buri" },
      { code: "TH-84", name: "Surat Thani" },
      { code: "TH-32", name: "Surin" },
      { code: "TH-63", name: "Tak" },
      { code: "TH-92", name: "Trang" },
      { code: "TH-23", name: "Trat" },
      { code: "TH-34", name: "Ubon Ratchathani" },
      { code: "TH-41", name: "Udon Thani" },
      { code: "TH-61", name: "Uthai Thani" },
      { code: "TH-53", name: "Uttaradit" },
      { code: "TH-95", name: "Yala" },
      { code: "TH-35", name: "Yasothon" },
    ],
  },
  {
    name: "Timor-Leste",
    alpha2: "TL",
    alpha3: "TLS",
    numeric: "626",
    subdivisions: [
      { code: "TL-AL", name: "Aileu" },
      { code: "TL-AN", name: "Ainaro" },
      { code: "TL-BA", name: "Baucau" },
      { code: "TL-BO", name: "Bobonaro" },
      { code: "TL-CO", name: "Cova Lima" },
      { code: "TL-DI", name: "Díli" },
      { code: "TL-ER", name: "Ermera" },
      { code: "TL-LA", name: "Lautein" },
      { code: "TL-LI", name: "Likisá" },
      { code: "TL-MT", name: "Manatuto" },
      { code: "TL-MF", name: "Manufahi" },
      { code: "TL-OE", name: "Oekusi-Ambenu" },
      { code: "TL-VI", name: "Vikeke" },
    ],
  },
  {
    name: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    numeric: "768",
    subdivisions: [
      { code: "TG-C", name: "Centrale" },
      { code: "TG-K", name: "Kara" },
      { code: "TG-M", name: "Maritime (Région)" },
      { code: "TG-P", name: "Plateaux" },
      { code: "TG-S", name: "Savanes" },
    ],
  },
  {
    name: "Tokelau",
    alpha2: "TK",
    alpha3: "TKL",
    numeric: "772",
    subdivisions: [],
  },
  {
    name: "Tonga",
    alpha2: "TO",
    alpha3: "TON",
    numeric: "776",
    subdivisions: [
      { code: "TO-01", name: "'Eua" },
      { code: "TO-02", name: "Ha'apai" },
      { code: "TO-03", name: "Niuas" },
      { code: "TO-04", name: "Tongatapu" },
      { code: "TO-05", name: "Vava'u" },
    ],
  },
  {
    name: "Trinidad and Tobago",
    alpha2: "TT",
    alpha3: "TTO",
    numeric: "780",
    subdivisions: [
      { code: "TT-ARI", name: "Arima" },
      { code: "TT-CHA", name: "Chaguanas" },
      { code: "TT-CTT", name: "Couva-Tabaquite-Talparo" },
      { code: "TT-DMN", name: "Diego Martin" },
      { code: "TT-MRC", name: "Mayaro-Rio Claro" },
      { code: "TT-PED", name: "Penal-Debe" },
      { code: "TT-PTF", name: "Point Fortin" },
      { code: "TT-POS", name: "Port of Spain" },
      { code: "TT-PRT", name: "Princes Town" },
      { code: "TT-SFO", name: "San Fernando" },
      { code: "TT-SJL", name: "San Juan-Laventille" },
      { code: "TT-SGE", name: "Sangre Grande" },
      { code: "TT-SIP", name: "Siparia" },
      { code: "TT-TOB", name: "Tobago" },
      { code: "TT-TUP", name: "Tunapuna-Piarco" },
    ],
  },
  {
    name: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    numeric: "788",
    subdivisions: [
      { code: "TN-13", name: "Ben Arous" },
      { code: "TN-23", name: "Bizerte" },
      { code: "TN-31", name: "Béja" },
      { code: "TN-81", name: "Gabès" },
      { code: "TN-71", name: "Gafsa" },
      { code: "TN-32", name: "Jendouba" },
      { code: "TN-41", name: "Kairouan" },
      { code: "TN-42", name: "Kasserine" },
      { code: "TN-73", name: "Kébili" },
      { code: "TN-12", name: "L'Ariana" },
      { code: "TN-14", name: "La Manouba" },
      { code: "TN-33", name: "Le Kef" },
      { code: "TN-53", name: "Mahdia" },
      { code: "TN-52", name: "Monastir" },
      { code: "TN-82", name: "Médenine" },
      { code: "TN-21", name: "Nabeul" },
      { code: "TN-61", name: "Sfax" },
      { code: "TN-43", name: "Sidi Bouzid" },
      { code: "TN-34", name: "Siliana" },
      { code: "TN-51", name: "Sousse" },
      { code: "TN-83", name: "Tataouine" },
      { code: "TN-72", name: "Tozeur" },
      { code: "TN-11", name: "Tunis" },
      { code: "TN-22", name: "Zaghouan" },
    ],
  },
  {
    name: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    numeric: "792",
    subdivisions: [
      { code: "TR-01", name: "Adana" },
      { code: "TR-02", name: "Adıyaman" },
      { code: "TR-03", name: "Afyonkarahisar" },
      { code: "TR-68", name: "Aksaray" },
      { code: "TR-05", name: "Amasya" },
      { code: "TR-06", name: "Ankara" },
      { code: "TR-07", name: "Antalya" },
      { code: "TR-75", name: "Ardahan" },
      { code: "TR-08", name: "Artvin" },
      { code: "TR-09", name: "Aydın" },
      { code: "TR-04", name: "Ağrı" },
      { code: "TR-10", name: "Balıkesir" },
      { code: "TR-74", name: "Bartın" },
      { code: "TR-72", name: "Batman" },
      { code: "TR-69", name: "Bayburt" },
      { code: "TR-11", name: "Bilecik" },
      { code: "TR-12", name: "Bingöl" },
      { code: "TR-13", name: "Bitlis" },
      { code: "TR-14", name: "Bolu" },
      { code: "TR-15", name: "Burdur" },
      { code: "TR-16", name: "Bursa" },
      { code: "TR-20", name: "Denizli" },
      { code: "TR-21", name: "Diyarbakır" },
      { code: "TR-81", name: "Düzce" },
      { code: "TR-22", name: "Edirne" },
      { code: "TR-23", name: "Elazığ" },
      { code: "TR-24", name: "Erzincan" },
      { code: "TR-25", name: "Erzurum" },
      { code: "TR-26", name: "Eskişehir" },
      { code: "TR-27", name: "Gaziantep" },
      { code: "TR-28", name: "Giresun" },
      { code: "TR-29", name: "Gümüşhane" },
      { code: "TR-30", name: "Hakkâri" },
      { code: "TR-31", name: "Hatay" },
      { code: "TR-32", name: "Isparta" },
      { code: "TR-76", name: "Iğdır" },
      { code: "TR-46", name: "Kahramanmaraş" },
      { code: "TR-78", name: "Karabük" },
      { code: "TR-70", name: "Karaman" },
      { code: "TR-36", name: "Kars" },
      { code: "TR-37", name: "Kastamonu" },
      { code: "TR-38", name: "Kayseri" },
      { code: "TR-79", name: "Kilis" },
      { code: "TR-41", name: "Kocaeli" },
      { code: "TR-42", name: "Konya" },
      { code: "TR-43", name: "Kütahya" },
      { code: "TR-39", name: "Kırklareli" },
      { code: "TR-71", name: "Kırıkkale" },
      { code: "TR-40", name: "Kırşehir" },
      { code: "TR-44", name: "Malatya" },
      { code: "TR-45", name: "Manisa" },
      { code: "TR-47", name: "Mardin" },
      { code: "TR-33", name: "Mersin" },
      { code: "TR-48", name: "Muğla" },
      { code: "TR-49", name: "Muş" },
      { code: "TR-50", name: "Nevşehir" },
      { code: "TR-51", name: "Niğde" },
      { code: "TR-52", name: "Ordu" },
      { code: "TR-80", name: "Osmaniye" },
      { code: "TR-53", name: "Rize" },
      { code: "TR-54", name: "Sakarya" },
      { code: "TR-55", name: "Samsun" },
      { code: "TR-56", name: "Siirt" },
      { code: "TR-57", name: "Sinop" },
      { code: "TR-58", name: "Sivas" },
      { code: "TR-59", name: "Tekirdağ" },
      { code: "TR-60", name: "Tokat" },
      { code: "TR-61", name: "Trabzon" },
      { code: "TR-62", name: "Tunceli" },
      { code: "TR-64", name: "Uşak" },
      { code: "TR-65", name: "Van" },
      { code: "TR-77", name: "Yalova" },
      { code: "TR-66", name: "Yozgat" },
      { code: "TR-67", name: "Zonguldak" },
      { code: "TR-17", name: "Çanakkale" },
      { code: "TR-18", name: "Çankırı" },
      { code: "TR-19", name: "Çorum" },
      { code: "TR-34", name: "İstanbul" },
      { code: "TR-35", name: "İzmir" },
      { code: "TR-63", name: "Şanlıurfa" },
      { code: "TR-73", name: "Şırnak" },
    ],
  },
  {
    name: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    numeric: "795",
    subdivisions: [
      { code: "TM-A", name: "Ahal" },
      { code: "TM-S", name: "Aşgabat" },
      { code: "TM-B", name: "Balkan" },
      { code: "TM-D", name: "Daşoguz" },
      { code: "TM-L", name: "Lebap" },
      { code: "TM-M", name: "Mary" },
    ],
  },
  {
    name: "Turks and Caicos Islands (the)",
    alpha2: "TC",
    alpha3: "TCA",
    numeric: "796",
    subdivisions: [],
  },
  {
    name: "Tuvalu",
    alpha2: "TV",
    alpha3: "TUV",
    numeric: "798",
    subdivisions: [
      { code: "TV-FUN", name: "Funafuti" },
      { code: "TV-NMG", name: "Nanumaga" },
      { code: "TV-NMA", name: "Nanumea" },
      { code: "TV-NIT", name: "Niutao" },
      { code: "TV-NUI", name: "Nui" },
      { code: "TV-NKF", name: "Nukufetau" },
      { code: "TV-NKL", name: "Nukulaelae" },
      { code: "TV-VAI", name: "Vaitupu" },
    ],
  },
  {
    name: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    numeric: "800",
    subdivisions: [
      { code: "UG-314", name: "Abim" },
      { code: "UG-301", name: "Adjumani" },
      { code: "UG-322", name: "Agago" },
      { code: "UG-323", name: "Alebtong" },
      { code: "UG-315", name: "Amolatar" },
      { code: "UG-324", name: "Amudat" },
      { code: "UG-216", name: "Amuria" },
      { code: "UG-316", name: "Amuru" },
      { code: "UG-302", name: "Apac" },
      { code: "UG-303", name: "Arua" },
      { code: "UG-217", name: "Budaka" },
      { code: "UG-218", name: "Bududa" },
      { code: "UG-201", name: "Bugiri" },
      { code: "UG-235", name: "Bugweri" },
      { code: "UG-420", name: "Buhweju" },
      { code: "UG-117", name: "Buikwe" },
      { code: "UG-219", name: "Bukedea" },
      { code: "UG-118", name: "Bukomansibi" },
      { code: "UG-220", name: "Bukwa" },
      { code: "UG-225", name: "Bulambuli" },
      { code: "UG-416", name: "Buliisa" },
      { code: "UG-401", name: "Bundibugyo" },
      { code: "UG-430", name: "Bunyangabu" },
      { code: "UG-402", name: "Bushenyi" },
      { code: "UG-202", name: "Busia" },
      { code: "UG-221", name: "Butaleja" },
      { code: "UG-119", name: "Butambala" },
      { code: "UG-233", name: "Butebo" },
      { code: "UG-120", name: "Buvuma" },
      { code: "UG-226", name: "Buyende" },
      { code: "UG-C", name: "Central" },
      { code: "UG-317", name: "Dokolo" },
      { code: "UG-E", name: "Eastern" },
      { code: "UG-121", name: "Gomba" },
      { code: "UG-304", name: "Gulu" },
      { code: "UG-403", name: "Hoima" },
      { code: "UG-417", name: "Ibanda" },
      { code: "UG-203", name: "Iganga" },
      { code: "UG-418", name: "Isingiro" },
      { code: "UG-204", name: "Jinja" },
      { code: "UG-318", name: "Kaabong" },
      { code: "UG-404", name: "Kabale" },
      { code: "UG-405", name: "Kabarole" },
      { code: "UG-213", name: "Kaberamaido" },
      { code: "UG-427", name: "Kagadi" },
      { code: "UG-428", name: "Kakumiro" },
      { code: "UG-101", name: "Kalangala" },
      { code: "UG-222", name: "Kaliro" },
      { code: "UG-122", name: "Kalungu" },
      { code: "UG-102", name: "Kampala" },
      { code: "UG-205", name: "Kamuli" },
      { code: "UG-413", name: "Kamwenge" },
      { code: "UG-414", name: "Kanungu" },
      { code: "UG-206", name: "Kapchorwa" },
      { code: "UG-236", name: "Kapelebyong" },
      { code: "UG-126", name: "Kasanda" },
      { code: "UG-406", name: "Kasese" },
      { code: "UG-207", name: "Katakwi" },
      { code: "UG-112", name: "Kayunga" },
      { code: "UG-407", name: "Kibaale" },
      { code: "UG-103", name: "Kiboga" },
      { code: "UG-227", name: "Kibuku" },
      { code: "UG-432", name: "Kikuube" },
      { code: "UG-419", name: "Kiruhura" },
      { code: "UG-421", name: "Kiryandongo" },
      { code: "UG-408", name: "Kisoro" },
      { code: "UG-305", name: "Kitgum" },
      { code: "UG-319", name: "Koboko" },
      { code: "UG-325", name: "Kole" },
      { code: "UG-306", name: "Kotido" },
      { code: "UG-208", name: "Kumi" },
      { code: "UG-333", name: "Kwania" },
      { code: "UG-228", name: "Kween" },
      { code: "UG-123", name: "Kyankwanzi" },
      { code: "UG-422", name: "Kyegegwa" },
      { code: "UG-415", name: "Kyenjojo" },
      { code: "UG-125", name: "Kyotera" },
      { code: "UG-326", name: "Lamwo" },
      { code: "UG-307", name: "Lira" },
      { code: "UG-229", name: "Luuka" },
      { code: "UG-104", name: "Luwero" },
      { code: "UG-124", name: "Lwengo" },
      { code: "UG-114", name: "Lyantonde" },
      { code: "UG-223", name: "Manafwa" },
      { code: "UG-320", name: "Maracha" },
      { code: "UG-105", name: "Masaka" },
      { code: "UG-409", name: "Masindi" },
      { code: "UG-214", name: "Mayuge" },
      { code: "UG-209", name: "Mbale" },
      { code: "UG-410", name: "Mbarara" },
      { code: "UG-423", name: "Mitooma" },
      { code: "UG-115", name: "Mityana" },
      { code: "UG-308", name: "Moroto" },
      { code: "UG-309", name: "Moyo" },
      { code: "UG-106", name: "Mpigi" },
      { code: "UG-107", name: "Mubende" },
      { code: "UG-108", name: "Mukono" },
      { code: "UG-334", name: "Nabilatuk" },
      { code: "UG-311", name: "Nakapiripirit" },
      { code: "UG-116", name: "Nakaseke" },
      { code: "UG-109", name: "Nakasongola" },
      { code: "UG-230", name: "Namayingo" },
      { code: "UG-234", name: "Namisindwa" },
      { code: "UG-224", name: "Namutumba" },
      { code: "UG-327", name: "Napak" },
      { code: "UG-310", name: "Nebbi" },
      { code: "UG-231", name: "Ngora" },
      { code: "UG-N", name: "Northern" },
      { code: "UG-424", name: "Ntoroko" },
      { code: "UG-411", name: "Ntungamo" },
      { code: "UG-328", name: "Nwoya" },
      { code: "UG-331", name: "Omoro" },
      { code: "UG-329", name: "Otuke" },
      { code: "UG-321", name: "Oyam" },
      { code: "UG-312", name: "Pader" },
      { code: "UG-332", name: "Pakwach" },
      { code: "UG-210", name: "Pallisa" },
      { code: "UG-110", name: "Rakai" },
      { code: "UG-429", name: "Rubanda" },
      { code: "UG-425", name: "Rubirizi" },
      { code: "UG-431", name: "Rukiga" },
      { code: "UG-412", name: "Rukungiri" },
      { code: "UG-111", name: "Sembabule" },
      { code: "UG-232", name: "Serere" },
      { code: "UG-426", name: "Sheema" },
      { code: "UG-215", name: "Sironko" },
      { code: "UG-211", name: "Soroti" },
      { code: "UG-212", name: "Tororo" },
      { code: "UG-113", name: "Wakiso" },
      { code: "UG-W", name: "Western" },
      { code: "UG-313", name: "Yumbe" },
      { code: "UG-330", name: "Zombo" },
    ],
  },
  {
    name: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    numeric: "804",
    subdivisions: [
      { code: "UA-43", name: "Avtonomna Respublika Krym" },
      { code: "UA-71", name: "Cherkaska oblast" },
      { code: "UA-74", name: "Chernihivska oblast" },
      { code: "UA-77", name: "Chernivetska oblast" },
      { code: "UA-12", name: "Dnipropetrovska oblast" },
      { code: "UA-14", name: "Donetska oblast" },
      { code: "UA-26", name: "Ivano-Frankivska oblast" },
      { code: "UA-63", name: "Kharkivska oblast" },
      { code: "UA-65", name: "Khersonska oblast" },
      { code: "UA-68", name: "Khmelnytska oblast" },
      { code: "UA-35", name: "Kirovohradska oblast" },
      { code: "UA-30", name: "Kyiv" },
      { code: "UA-32", name: "Kyivska oblast" },
      { code: "UA-09", name: "Luhanska oblast" },
      { code: "UA-46", name: "Lvivska oblast" },
      { code: "UA-48", name: "Mykolaivska oblast" },
      { code: "UA-51", name: "Odeska oblast" },
      { code: "UA-53", name: "Poltavska oblast" },
      { code: "UA-56", name: "Rivnenska oblast" },
      { code: "UA-40", name: "Sevastopol" },
      { code: "UA-59", name: "Sumska oblast" },
      { code: "UA-61", name: "Ternopilska oblast" },
      { code: "UA-05", name: "Vinnytska oblast" },
      { code: "UA-07", name: "Volynska oblast" },
      { code: "UA-21", name: "Zakarpatska oblast" },
      { code: "UA-23", name: "Zaporizka oblast" },
      { code: "UA-18", name: "Zhytomyrska oblast" },
    ],
  },
  {
    name: "United Arab Emirates (the)",
    alpha2: "AE",
    alpha3: "ARE",
    numeric: "784",
    subdivisions: [
      { code: "AE-AZ", name: "Abū Z̧aby" },
      { code: "AE-FU", name: "Al Fujayrah" },
      { code: "AE-SH", name: "Ash Shāriqah" },
      { code: "AE-DU", name: "Dubayy" },
      { code: "AE-RK", name: "Ra’s al Khaymah" },
      { code: "AE-UQ", name: "Umm al Qaywayn" },
      { code: "AE-AJ", name: "‘Ajmān" },
    ],
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    alpha2: "GB",
    alpha3: "GBR",
    numeric: "826",
    subdivisions: [
      { code: "GB-ABE", name: "Aberdeen City" },
      { code: "GB-ABD", name: "Aberdeenshire" },
      { code: "GB-ANS", name: "Angus" },
      { code: "GB-ANN", name: "Antrim and Newtownabbey" },
      { code: "GB-AND", name: "Ards and North Down" },
      { code: "GB-AGB", name: "Argyll and Bute" },
      { code: "GB-ABC", name: "Armagh City, Banbridge and Craigavon" },
      { code: "GB-BDG", name: "Barking and Dagenham" },
      { code: "GB-BNE", name: "Barnet" },
      { code: "GB-BNS", name: "Barnsley" },
      { code: "GB-BAS", name: "Bath and North East Somerset" },
      { code: "GB-BDF", name: "Bedford" },
      { code: "GB-BFS", name: "Belfast" },
      { code: "GB-BEX", name: "Bexley" },
      { code: "GB-BIR", name: "Birmingham" },
      { code: "GB-BBD", name: "Blackburn with Darwen" },
      { code: "GB-BPL", name: "Blackpool" },
      { code: "GB-BGW", name: "Blaenau Gwent" },
      { code: "GB-BOL", name: "Bolton" },
      { code: "GB-BMH", name: "Bournemouth" },
      { code: "GB-BRC", name: "Bracknell Forest" },
      { code: "GB-BRD", name: "Bradford" },
      { code: "GB-BEN", name: "Brent" },
      { code: "GB-BGE", name: "Bridgend [Pen-y-bont ar Ogwr GB-POG]" },
      { code: "GB-BNH", name: "Brighton and Hove" },
      { code: "GB-BST", name: "Bristol, City of" },
      { code: "GB-BRY", name: "Bromley" },
      { code: "GB-BKM", name: "Buckinghamshire" },
      { code: "GB-BUR", name: "Bury" },
      { code: "GB-CAY", name: "Caerphilly [Caerffili GB-CAF]" },
      { code: "GB-CLD", name: "Calderdale" },
      { code: "GB-CAM", name: "Cambridgeshire" },
      { code: "GB-CMD", name: "Camden" },
      { code: "GB-CRF", name: "Cardiff [Caerdydd GB-CRD]" },
      { code: "GB-CMN", name: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]" },
      { code: "GB-CCG", name: "Causeway Coast and Glens" },
      { code: "GB-CBF", name: "Central Bedfordshire" },
      { code: "GB-CGN", name: "Ceredigion [Sir Ceredigion]" },
      { code: "GB-CHE", name: "Cheshire East" },
      { code: "GB-CHW", name: "Cheshire West and Chester" },
      { code: "GB-CLK", name: "Clackmannanshire" },
      { code: "GB-CWY", name: "Conwy" },
      { code: "GB-CON", name: "Cornwall" },
      { code: "GB-COV", name: "Coventry" },
      { code: "GB-CRY", name: "Croydon" },
      { code: "GB-CMA", name: "Cumbria" },
      { code: "GB-DAL", name: "Darlington" },
      { code: "GB-DEN", name: "Denbighshire [Sir Ddinbych GB-DDB]" },
      { code: "GB-DER", name: "Derby" },
      { code: "GB-DBY", name: "Derbyshire" },
      { code: "GB-DRS", name: "Derry City and Strabane" },
      { code: "GB-DEV", name: "Devon" },
      { code: "GB-DNC", name: "Doncaster" },
      { code: "GB-DOR", name: "Dorset" },
      { code: "GB-DUD", name: "Dudley" },
      { code: "GB-DGY", name: "Dumfries and Galloway" },
      { code: "GB-DND", name: "Dundee City" },
      { code: "GB-DUR", name: "Durham County" },
      { code: "GB-EAL", name: "Ealing" },
      { code: "GB-EAY", name: "East Ayrshire" },
      { code: "GB-EDU", name: "East Dunbartonshire" },
      { code: "GB-ELN", name: "East Lothian" },
      { code: "GB-ERW", name: "East Renfrewshire" },
      { code: "GB-ERY", name: "East Riding of Yorkshire" },
      { code: "GB-ESX", name: "East Sussex" },
      { code: "GB-EDH", name: "Edinburgh, City of" },
      { code: "GB-ELS", name: "Eilean Siar" },
      { code: "GB-ENF", name: "Enfield" },
      { code: "GB-ESS", name: "Essex" },
      { code: "GB-FAL", name: "Falkirk" },
      { code: "GB-FMO", name: "Fermanagh and Omagh" },
      { code: "GB-FIF", name: "Fife" },
      { code: "GB-FLN", name: "Flintshire [Sir y Fflint GB-FFL]" },
      { code: "GB-GAT", name: "Gateshead" },
      { code: "GB-GLG", name: "Glasgow City" },
      { code: "GB-GLS", name: "Gloucestershire" },
      { code: "GB-GRE", name: "Greenwich" },
      { code: "GB-GWN", name: "Gwynedd" },
      { code: "GB-HCK", name: "Hackney" },
      { code: "GB-HAL", name: "Halton" },
      { code: "GB-HMF", name: "Hammersmith and Fulham" },
      { code: "GB-HAM", name: "Hampshire" },
      { code: "GB-HRY", name: "Haringey" },
      { code: "GB-HRW", name: "Harrow" },
      { code: "GB-HPL", name: "Hartlepool" },
      { code: "GB-HAV", name: "Havering" },
      { code: "GB-HEF", name: "Herefordshire" },
      { code: "GB-HRT", name: "Hertfordshire" },
      { code: "GB-HLD", name: "Highland" },
      { code: "GB-HIL", name: "Hillingdon" },
      { code: "GB-HNS", name: "Hounslow" },
      { code: "GB-IVC", name: "Inverclyde" },
      { code: "GB-AGY", name: "Isle of Anglesey [Sir Ynys Môn GB-YNM]" },
      { code: "GB-IOW", name: "Isle of Wight" },
      { code: "GB-IOS", name: "Isles of Scilly" },
      { code: "GB-ISL", name: "Islington" },
      { code: "GB-KEC", name: "Kensington and Chelsea" },
      { code: "GB-KEN", name: "Kent" },
      { code: "GB-KHL", name: "Kingston upon Hull" },
      { code: "GB-KTT", name: "Kingston upon Thames" },
      { code: "GB-KIR", name: "Kirklees" },
      { code: "GB-KWL", name: "Knowsley" },
      { code: "GB-LBH", name: "Lambeth" },
      { code: "GB-LAN", name: "Lancashire" },
      { code: "GB-LDS", name: "Leeds" },
      { code: "GB-LCE", name: "Leicester" },
      { code: "GB-LEC", name: "Leicestershire" },
      { code: "GB-LEW", name: "Lewisham" },
      { code: "GB-LIN", name: "Lincolnshire" },
      { code: "GB-LBC", name: "Lisburn and Castlereagh" },
      { code: "GB-LIV", name: "Liverpool" },
      { code: "GB-LND", name: "London, City of" },
      { code: "GB-LUT", name: "Luton" },
      { code: "GB-MAN", name: "Manchester" },
      { code: "GB-MDW", name: "Medway" },
      { code: "GB-MTY", name: "Merthyr Tydfil [Merthyr Tudful GB-MTU]" },
      { code: "GB-MRT", name: "Merton" },
      { code: "GB-MUL", name: "Mid Ulster" },
      { code: "GB-MEA", name: "Mid and East Antrim" },
      { code: "GB-MDB", name: "Middlesbrough" },
      { code: "GB-MLN", name: "Midlothian" },
      { code: "GB-MIK", name: "Milton Keynes" },
      { code: "GB-MON", name: "Monmouthshire [Sir Fynwy GB-FYN]" },
      { code: "GB-MRY", name: "Moray" },
      {
        code: "GB-NTL",
        name: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
      },
      { code: "GB-NET", name: "Newcastle upon Tyne" },
      { code: "GB-NWM", name: "Newham" },
      { code: "GB-NWP", name: "Newport [Casnewydd GB-CNW]" },
      { code: "GB-NMD", name: "Newry, Mourne and Down" },
      { code: "GB-NFK", name: "Norfolk" },
      { code: "GB-NAY", name: "North Ayrshire" },
      { code: "GB-NEL", name: "North East Lincolnshire" },
      { code: "GB-NLK", name: "North Lanarkshire" },
      { code: "GB-NLN", name: "North Lincolnshire" },
      { code: "GB-NSM", name: "North Somerset" },
      { code: "GB-NTY", name: "North Tyneside" },
      { code: "GB-NYK", name: "North Yorkshire" },
      { code: "GB-NTH", name: "Northamptonshire" },
      { code: "GB-NBL", name: "Northumberland" },
      { code: "GB-NGM", name: "Nottingham" },
      { code: "GB-NTT", name: "Nottinghamshire" },
      { code: "GB-OLD", name: "Oldham" },
      { code: "GB-ORK", name: "Orkney Islands" },
      { code: "GB-OXF", name: "Oxfordshire" },
      { code: "GB-PEM", name: "Pembrokeshire [Sir Benfro GB-BNF]" },
      { code: "GB-PKN", name: "Perth and Kinross" },
      { code: "GB-PTE", name: "Peterborough" },
      { code: "GB-PLY", name: "Plymouth" },
      { code: "GB-POL", name: "Poole" },
      { code: "GB-POR", name: "Portsmouth" },
      { code: "GB-POW", name: "Powys" },
      { code: "GB-RDG", name: "Reading" },
      { code: "GB-RDB", name: "Redbridge" },
      { code: "GB-RCC", name: "Redcar and Cleveland" },
      { code: "GB-RFW", name: "Renfrewshire" },
      { code: "GB-RCT", name: "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]" },
      { code: "GB-RIC", name: "Richmond upon Thames" },
      { code: "GB-RCH", name: "Rochdale" },
      { code: "GB-ROT", name: "Rotherham" },
      { code: "GB-RUT", name: "Rutland" },
      { code: "GB-SLF", name: "Salford" },
      { code: "GB-SAW", name: "Sandwell" },
      { code: "GB-SCB", name: "Scottish Borders, The" },
      { code: "GB-SFT", name: "Sefton" },
      { code: "GB-SHF", name: "Sheffield" },
      { code: "GB-ZET", name: "Shetland Islands" },
      { code: "GB-SHR", name: "Shropshire" },
      { code: "GB-SLG", name: "Slough" },
      { code: "GB-SOL", name: "Solihull" },
      { code: "GB-SOM", name: "Somerset" },
      { code: "GB-SAY", name: "South Ayrshire" },
      { code: "GB-SGC", name: "South Gloucestershire" },
      { code: "GB-SLK", name: "South Lanarkshire" },
      { code: "GB-STY", name: "South Tyneside" },
      { code: "GB-STH", name: "Southampton" },
      { code: "GB-SOS", name: "Southend-on-Sea" },
      { code: "GB-SWK", name: "Southwark" },
      { code: "GB-SHN", name: "St. Helens" },
      { code: "GB-STS", name: "Staffordshire" },
      { code: "GB-STG", name: "Stirling" },
      { code: "GB-SKP", name: "Stockport" },
      { code: "GB-STT", name: "Stockton-on-Tees" },
      { code: "GB-STE", name: "Stoke-on-Trent" },
      { code: "GB-SFK", name: "Suffolk" },
      { code: "GB-SND", name: "Sunderland" },
      { code: "GB-SRY", name: "Surrey" },
      { code: "GB-STN", name: "Sutton" },
      { code: "GB-SWA", name: "Swansea [Abertawe GB-ATA]" },
      { code: "GB-SWD", name: "Swindon" },
      { code: "GB-TAM", name: "Tameside" },
      { code: "GB-TFW", name: "Telford and Wrekin" },
      { code: "GB-THR", name: "Thurrock" },
      { code: "GB-TOB", name: "Torbay" },
      { code: "GB-TOF", name: "Torfaen [Tor-faen]" },
      { code: "GB-TWH", name: "Tower Hamlets" },
      { code: "GB-TRF", name: "Trafford" },
      { code: "GB-VGL", name: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]" },
      { code: "GB-WKF", name: "Wakefield" },
      { code: "GB-WLL", name: "Walsall" },
      { code: "GB-WFT", name: "Waltham Forest" },
      { code: "GB-WND", name: "Wandsworth" },
      { code: "GB-WRT", name: "Warrington" },
      { code: "GB-WAR", name: "Warwickshire" },
      { code: "GB-WBK", name: "West Berkshire" },
      { code: "GB-WDU", name: "West Dunbartonshire" },
      { code: "GB-WLN", name: "West Lothian" },
      { code: "GB-WSX", name: "West Sussex" },
      { code: "GB-WSM", name: "Westminster" },
      { code: "GB-WGN", name: "Wigan" },
      { code: "GB-WIL", name: "Wiltshire" },
      { code: "GB-WNM", name: "Windsor and Maidenhead" },
      { code: "GB-WRL", name: "Wirral" },
      { code: "GB-WOK", name: "Wokingham" },
      { code: "GB-WLV", name: "Wolverhampton" },
      { code: "GB-WOR", name: "Worcestershire" },
      { code: "GB-WRX", name: "Wrexham [Wrecsam GB-WRC]" },
      { code: "GB-YOR", name: "York" },
    ],
  },
  {
    name: "United States Minor Outlying Islands (the)",
    alpha2: "UM",
    alpha3: "UMI",
    numeric: "581",
    subdivisions: [
      { code: "UM-81", name: "Baker Island" },
      { code: "UM-84", name: "Howland Island" },
      { code: "UM-86", name: "Jarvis Island" },
      { code: "UM-67", name: "Johnston Atoll" },
      { code: "UM-89", name: "Kingman Reef" },
      { code: "UM-71", name: "Midway Islands" },
      { code: "UM-76", name: "Navassa Island" },
      { code: "UM-95", name: "Palmyra Atoll" },
      { code: "UM-79", name: "Wake Island" },
    ],
  },
  {
    name: "United States of America (the)",
    alpha2: "US",
    alpha3: "USA",
    numeric: "840",
    subdivisions: [
      { code: "US-AL", name: "Alabama" },
      { code: "US-AK", name: "Alaska" },
      {
        code: "US-AS",
        name: "American Samoa (see also separate country code entry under AS)",
      },
      { code: "US-AZ", name: "Arizona" },
      { code: "US-AR", name: "Arkansas" },
      { code: "US-CA", name: "California" },
      { code: "US-CO", name: "Colorado" },
      { code: "US-CT", name: "Connecticut" },
      { code: "US-DE", name: "Delaware" },
      { code: "US-DC", name: "District of Columbia" },
      { code: "US-FL", name: "Florida" },
      { code: "US-GA", name: "Georgia" },
      {
        code: "US-GU",
        name: "Guam (see also separate country code entry under GU)",
      },
      { code: "US-HI", name: "Hawaii" },
      { code: "US-ID", name: "Idaho" },
      { code: "US-IL", name: "Illinois" },
      { code: "US-IN", name: "Indiana" },
      { code: "US-IA", name: "Iowa" },
      { code: "US-KS", name: "Kansas" },
      { code: "US-KY", name: "Kentucky" },
      { code: "US-LA", name: "Louisiana" },
      { code: "US-ME", name: "Maine" },
      { code: "US-MD", name: "Maryland" },
      { code: "US-MA", name: "Massachusetts" },
      { code: "US-MI", name: "Michigan" },
      { code: "US-MN", name: "Minnesota" },
      { code: "US-MS", name: "Mississippi" },
      { code: "US-MO", name: "Missouri" },
      { code: "US-MT", name: "Montana" },
      { code: "US-NE", name: "Nebraska" },
      { code: "US-NV", name: "Nevada" },
      { code: "US-NH", name: "New Hampshire" },
      { code: "US-NJ", name: "New Jersey" },
      { code: "US-NM", name: "New Mexico" },
      { code: "US-NY", name: "New York" },
      { code: "US-NC", name: "North Carolina" },
      { code: "US-ND", name: "North Dakota" },
      {
        code: "US-MP",
        name: "Northern Mariana Islands (see also separate country code entry under MP)",
      },
      { code: "US-OH", name: "Ohio" },
      { code: "US-OK", name: "Oklahoma" },
      { code: "US-OR", name: "Oregon" },
      { code: "US-PA", name: "Pennsylvania" },
      {
        code: "US-PR",
        name: "Puerto Rico (see also separate country code entry under PR)",
      },
      { code: "US-RI", name: "Rhode Island" },
      { code: "US-SC", name: "South Carolina" },
      { code: "US-SD", name: "South Dakota" },
      { code: "US-TN", name: "Tennessee" },
      { code: "US-TX", name: "Texas" },
      {
        code: "US-UM",
        name: "United States Minor Outlying Islands (see also separate country code entry under UM)",
      },
      { code: "US-UT", name: "Utah" },
      { code: "US-VT", name: "Vermont" },
      {
        code: "US-VI",
        name: "Virgin Islands, U.S. (see also separate country code entry under VI)",
      },
      { code: "US-VA", name: "Virginia" },
      { code: "US-WA", name: "Washington" },
      { code: "US-WV", name: "West Virginia" },
      { code: "US-WI", name: "Wisconsin" },
      { code: "US-WY", name: "Wyoming" },
    ],
  },
  {
    name: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    numeric: "858",
    subdivisions: [
      { code: "UY-AR", name: "Artigas" },
      { code: "UY-CA", name: "Canelones" },
      { code: "UY-CL", name: "Cerro Largo" },
      { code: "UY-CO", name: "Colonia" },
      { code: "UY-DU", name: "Durazno" },
      { code: "UY-FS", name: "Flores" },
      { code: "UY-FD", name: "Florida" },
      { code: "UY-LA", name: "Lavalleja" },
      { code: "UY-MA", name: "Maldonado" },
      { code: "UY-MO", name: "Montevideo" },
      { code: "UY-PA", name: "Paysandú" },
      { code: "UY-RV", name: "Rivera" },
      { code: "UY-RO", name: "Rocha" },
      { code: "UY-RN", name: "Río Negro" },
      { code: "UY-SA", name: "Salto" },
      { code: "UY-SJ", name: "San José" },
      { code: "UY-SO", name: "Soriano" },
      { code: "UY-TA", name: "Tacuarembó" },
      { code: "UY-TT", name: "Treinta y Tres" },
    ],
  },
  {
    name: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    numeric: "860",
    subdivisions: [
      { code: "UZ-AN", name: "Andijon" },
      { code: "UZ-BU", name: "Buxoro" },
      { code: "UZ-FA", name: "Farg‘ona" },
      { code: "UZ-JI", name: "Jizzax" },
      { code: "UZ-NG", name: "Namangan" },
      { code: "UZ-NW", name: "Navoiy" },
      { code: "UZ-QA", name: "Qashqadaryo" },
      { code: "UZ-QR", name: "Qoraqalpog‘iston Respublikasi" },
      { code: "UZ-SA", name: "Samarqand" },
      { code: "UZ-SI", name: "Sirdaryo" },
      { code: "UZ-SU", name: "Surxondaryo" },
      { code: "UZ-TO", name: "Toshkent" },
      { code: "UZ-TK", name: "Toshkent" },
      { code: "UZ-XO", name: "Xorazm" },
    ],
  },
  {
    name: "Vanuatu",
    alpha2: "VU",
    alpha3: "VUT",
    numeric: "548",
    subdivisions: [
      { code: "VU-MAP", name: "Malampa" },
      { code: "VU-PAM", name: "Pénama" },
      { code: "VU-SAM", name: "Sanma" },
      { code: "VU-SEE", name: "Shéfa" },
      { code: "VU-TAE", name: "Taféa" },
      { code: "VU-TOB", name: "Torba" },
    ],
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha2: "VE",
    alpha3: "VEN",
    numeric: "862",
    subdivisions: [
      { code: "VE-Z", name: "Amazonas" },
      { code: "VE-B", name: "Anzoátegui" },
      { code: "VE-C", name: "Apure" },
      { code: "VE-D", name: "Aragua" },
      { code: "VE-E", name: "Barinas" },
      { code: "VE-F", name: "Bolívar" },
      { code: "VE-G", name: "Carabobo" },
      { code: "VE-H", name: "Cojedes" },
      { code: "VE-Y", name: "Delta Amacuro" },
      { code: "VE-W", name: "Dependencias Federales" },
      { code: "VE-A", name: "Distrito Capital" },
      { code: "VE-I", name: "Falcón" },
      { code: "VE-J", name: "Guárico" },
      { code: "VE-K", name: "Lara" },
      { code: "VE-M", name: "Miranda" },
      { code: "VE-N", name: "Monagas" },
      { code: "VE-L", name: "Mérida" },
      { code: "VE-O", name: "Nueva Esparta" },
      { code: "VE-P", name: "Portuguesa" },
      { code: "VE-R", name: "Sucre" },
      { code: "VE-T", name: "Trujillo" },
      { code: "VE-S", name: "Táchira" },
      { code: "VE-X", name: "Vargas" },
      { code: "VE-U", name: "Yaracuy" },
      { code: "VE-V", name: "Zulia" },
    ],
  },
  {
    name: "Viet Nam",
    alpha2: "VN",
    alpha3: "VNM",
    numeric: "704",
    subdivisions: [
      { code: "VN-44", name: "An Giang" },
      { code: "VN-43", name: "Bà Rịa - Vũng Tàu" },
      { code: "VN-57", name: "Bình Dương" },
      { code: "VN-58", name: "Bình Phước" },
      { code: "VN-40", name: "Bình Thuận" },
      { code: "VN-31", name: "Bình Định" },
      { code: "VN-55", name: "Bạc Liêu" },
      { code: "VN-54", name: "Bắc Giang" },
      { code: "VN-53", name: "Bắc Kạn" },
      { code: "VN-56", name: "Bắc Ninh" },
      { code: "VN-50", name: "Bến Tre" },
      { code: "VN-CT", name: "Can Tho" },
      { code: "VN-04", name: "Cao Bằng" },
      { code: "VN-59", name: "Cà Mau" },
      { code: "VN-DN", name: "Da Nang" },
      { code: "VN-30", name: "Gia Lai" },
      { code: "VN-HN", name: "Ha Noi" },
      { code: "VN-HP", name: "Hai Phong" },
      { code: "VN-SG", name: "Ho Chi Minh" },
      { code: "VN-03", name: "Hà Giang" },
      { code: "VN-63", name: "Hà Nam" },
      { code: "VN-23", name: "Hà Tĩnh" },
      { code: "VN-14", name: "Hòa Bình" },
      { code: "VN-66", name: "Hưng Yên" },
      { code: "VN-61", name: "Hải Dương" },
      { code: "VN-73", name: "Hậu Giang" },
      { code: "VN-34", name: "Khánh Hòa" },
      { code: "VN-47", name: "Kiến Giang" },
      { code: "VN-28", name: "Kon Tum" },
      { code: "VN-01", name: "Lai Châu" },
      { code: "VN-41", name: "Long An" },
      { code: "VN-02", name: "Lào Cai" },
      { code: "VN-35", name: "Lâm Đồng" },
      { code: "VN-09", name: "Lạng Sơn" },
      { code: "VN-67", name: "Nam Định" },
      { code: "VN-22", name: "Nghệ An" },
      { code: "VN-18", name: "Ninh Bình" },
      { code: "VN-36", name: "Ninh Thuận" },
      { code: "VN-68", name: "Phú Thọ" },
      { code: "VN-32", name: "Phú Yên" },
      { code: "VN-24", name: "Quảng Bình" },
      { code: "VN-27", name: "Quảng Nam" },
      { code: "VN-29", name: "Quảng Ngãi" },
      { code: "VN-13", name: "Quảng Ninh" },
      { code: "VN-25", name: "Quảng Trị" },
      { code: "VN-52", name: "Sóc Trăng" },
      { code: "VN-05", name: "Sơn La" },
      { code: "VN-21", name: "Thanh Hóa" },
      { code: "VN-20", name: "Thái Bình" },
      { code: "VN-69", name: "Thái Nguyên" },
      { code: "VN-26", name: "Thừa Thiên-Huế" },
      { code: "VN-46", name: "Tiền Giang" },
      { code: "VN-51", name: "Trà Vinh" },
      { code: "VN-07", name: "Tuyên Quang" },
      { code: "VN-37", name: "Tây Ninh" },
      { code: "VN-49", name: "Vĩnh Long" },
      { code: "VN-70", name: "Vĩnh Phúc" },
      { code: "VN-06", name: "Yên Bái" },
      { code: "VN-71", name: "Điện Biên" },
      { code: "VN-33", name: "Đắk Lắk" },
      { code: "VN-72", name: "Đắk Nông" },
      { code: "VN-39", name: "Đồng Nai" },
      { code: "VN-45", name: "Đồng Tháp" },
    ],
  },
  {
    name: "Virgin Islands (British)",
    alpha2: "VG",
    alpha3: "VGB",
    numeric: "092",
    subdivisions: [],
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha2: "VI",
    alpha3: "VIR",
    numeric: "850",
    subdivisions: [],
  },
  {
    name: "Wallis and Futuna",
    alpha2: "WF",
    alpha3: "WLF",
    numeric: "876",
    subdivisions: [
      { code: "WF-AL", name: "Alo" },
      { code: "WF-SG", name: "Sigave" },
      { code: "WF-UV", name: "Uvea" },
    ],
  },
  {
    name: "Western Sahara*",
    alpha2: "EH",
    alpha3: "ESH",
    numeric: "732",
    subdivisions: [],
  },
  {
    name: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    numeric: "887",
    subdivisions: [
      { code: "YE-AB", name: "Abyan" },
      { code: "YE-BA", name: "Al Bayḑā’" },
      { code: "YE-JA", name: "Al Jawf" },
      { code: "YE-MR", name: "Al Mahrah" },
      { code: "YE-MW", name: "Al Maḩwīt" },
      { code: "YE-HU", name: "Al Ḩudaydah" },
      { code: "YE-SA", name: "Amānat al ‘Āşimah [city]" },
      { code: "YE-SU", name: "Arkhabīl Suquţrá" },
      { code: "YE-DA", name: "Aḑ Ḑāli‘" },
      { code: "YE-DH", name: "Dhamār" },
      { code: "YE-IB", name: "Ibb" },
      { code: "YE-LA", name: "Laḩij" },
      { code: "YE-MA", name: "Ma’rib" },
      { code: "YE-RA", name: "Raymah" },
      { code: "YE-SH", name: "Shabwah" },
      { code: "YE-TA", name: "Tāʻizz" },
      { code: "YE-SN", name: "Şanʻā’" },
      { code: "YE-SD", name: "Şāʻdah" },
      { code: "YE-HJ", name: "Ḩajjah" },
      { code: "YE-HD", name: "Ḩaḑramawt" },
      { code: "YE-AD", name: "‘Adan" },
      { code: "YE-AM", name: "‘Amrān" },
    ],
  },
  {
    name: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    numeric: "894",
    subdivisions: [
      { code: "ZM-02", name: "Central" },
      { code: "ZM-08", name: "Copperbelt" },
      { code: "ZM-03", name: "Eastern" },
      { code: "ZM-04", name: "Luapula" },
      { code: "ZM-09", name: "Lusaka" },
      { code: "ZM-10", name: "Muchinga" },
      { code: "ZM-06", name: "North-Western" },
      { code: "ZM-05", name: "Northern" },
      { code: "ZM-07", name: "Southern" },
      { code: "ZM-01", name: "Western" },
    ],
  },
  {
    name: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    numeric: "716",
    subdivisions: [
      { code: "ZW-BU", name: "Bulawayo" },
      { code: "ZW-HA", name: "Harare" },
      { code: "ZW-MA", name: "Manicaland" },
      { code: "ZW-MC", name: "Mashonaland Central" },
      { code: "ZW-ME", name: "Mashonaland East" },
      { code: "ZW-MW", name: "Mashonaland West" },
      { code: "ZW-MV", name: "Masvingo" },
      { code: "ZW-MN", name: "Matabeleland North" },
      { code: "ZW-MS", name: "Matabeleland South" },
      { code: "ZW-MI", name: "Midlands" },
    ],
  },
];
