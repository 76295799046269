import { useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { categoryT, genderT } from "../App";
import styles from "./GenderPicker.module.scss";

interface IGenderPicker {
  updateGender: (gender: genderT, category: categoryT) => void;
  gender: genderT;
  category: categoryT;
}

const svgProps = { size: 16, color: "#136FA3" };

const verbaLize = (e: genderT | categoryT) => {
  if (e === "ADULT") {
    return window.verbals["wizard.adult"] || "Adult";
  } else if (e === "CHILD") {
    return window.verbals["wizard.child"] || "Child";
  } else if (e === "INFANT") {
    return window.verbals["wizard.infant"] || "Infant";
  } else if (e === "MALE") {
    return window.verbals["wizard.male"] || "Male";
  } else if (e === "FEMALE") {
    return window.verbals["wizard.female"] || "Female";
  }
};

export default function GenderPicker({
  updateGender,
  gender,
  category,
}: IGenderPicker) {
  const myRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const value = `${window.verbals["wizard.gender"] || "Gender"} (${verbaLize(
    category
  )} ${verbaLize(gender)})`;

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  return (
    <div className={styles.main} ref={myRef}>
      <button
        className={styles.selectHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.headerButton}>{value}</div>
        <ChevronDown
          {...svgProps}
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>

      <div className={`${styles.optionsWrapper} ${isOpen ? styles.open : ""} `}>
        <div className={styles.category}>
          <div className={styles.catHeader}>
            {window.verbals["wizard.adult"] || "Adult"}
          </div>
          <button
            className={styles.option}
            onClick={() => {
              updateGender("MALE", "ADULT");
              setIsOpen(false);
            }}
          >
            {window.verbals["wizard.male"] || "Male"}
          </button>
          <button
            className={styles.option}
            onClick={() => {
              updateGender("FEMALE", "ADULT");
              setIsOpen(false);
            }}
          >
            {window.verbals["wizard.female"] || "Female"}
          </button>
        </div>
        <div className={styles.category}>
          <div className={styles.catHeader}>
            {window.verbals["wizard.child"] || "Child"}
          </div>
          <button
            className={styles.option}
            onClick={() => {
              updateGender("MALE", "CHILD");
              setIsOpen(false);
            }}
          >
            {window.verbals["wizard.male"] || "Male"}
          </button>
          <button
            className={styles.option}
            onClick={() => {
              updateGender("FEMALE", "CHILD");
              setIsOpen(false);
            }}
          >
            {window.verbals["wizard.female"] || "Female"}
          </button>
        </div>
        <div className={styles.category}>
          <div className={styles.catHeader}>
            {window.verbals["wizard.infant"] || "Infant"}
          </div>
          <button
            className={styles.option}
            onClick={() => {
              updateGender("MALE", "INFANT");
              setIsOpen(false);
            }}
          >
            {window.verbals["wizard.male"] || "Male"}
          </button>
          <button
            className={styles.option}
            onClick={() => {
              updateGender("FEMALE", "INFANT");
              setIsOpen(false);
            }}
          >
            {window.verbals["wizard.female"] || "Female"}
          </button>
        </div>
      </div>
    </div>
  );
}
