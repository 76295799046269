import { useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import styles from "./BirthdatePicker.module.scss";

interface IBirthdatePicker {
  update: (value: string | null) => void;
  birthdate: string | null;
  submitAttempted: boolean;
}

const svgProps = { size: 16, color: "#136FA3" };

export default function BirthdatePicker({
  update,
  birthdate,
  submitAttempted,
}: IBirthdatePicker) {
  const myRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const value = !birthdate
    ? `${window.verbals["wizard.dateofBirth"] || "Date of Birth"} *`
    : new Date(birthdate).toLocaleDateString();

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  const preUpdate = (bDate: Date) => {
    const year = bDate.getFullYear();
    const month = bDate.getMonth() + 1;
    const formatedMonth = month > 9 ? month : `0${month}`;
    const date = bDate.getDate();
    const formatedDate = date > 9 ? date : `0${date}`;
    const mydate = new Date(`${year}-${formatedMonth}-${formatedDate}`);
    update(
      isNaN(mydate.getTime())
        ? null
        : `${year}-${formatedMonth}-${formatedDate}`
    );
  };

  return (
    <div className={styles.main} ref={myRef}>
      <button
        className={`${styles.selectHeader} ${
          submitAttempted && birthdate === null ? styles.errored : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
        data-invalid={submitAttempted && birthdate === null}
      >
        <div className={styles.headerButton}>{value}</div>
        <ChevronDown
          {...svgProps}
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>

      {isOpen && (
        <div
          className={`${styles.optionsWrapper} ${isOpen ? styles.open : ""} `}
        >
          <Calendar
            value={birthdate}
            showNeighboringMonth={false}
            maxDate={new Date()}
            onChange={(date) => {
              preUpdate(date as Date);
              setIsOpen(false);
            }}
          />
        </div>
      )}
      {submitAttempted && birthdate === null && (
        <div className={styles.error}>
          {" "}
          {window.verbals["wizard.fieldRequired"] || "This field is required"}
        </div>
      )}
    </div>
  );
}
