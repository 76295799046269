import { People, TicketDetailedFill } from "react-bootstrap-icons";
import { extra } from "../App";
import {
  CalendarSVG,
  ExtrasSVG,
  GlobeSVG,
  LocationSVG,
  MapPinSVG,
  TravelSVG,
} from "../svgs";
import styles from "./Summary.module.scss";

interface ISummary {
  adultsAmount: number;
  childrenAmount: number;
  infantsAmount: number;
  pickupExcursion: string;
  pickupLocation: string;
  pickupPoint: string;
  language: string;
  date: Date | null;
  totalPrice: number;
  selectedExtras: string[];
  availableExtras: extra[];
}

export default function Summary({
  adultsAmount,
  childrenAmount,
  infantsAmount,
  pickupExcursion,
  pickupLocation,
  pickupPoint,
  language,
  date,
  totalPrice,
  selectedExtras,
  availableExtras,
}: ISummary) {
  const lowerize = (e: string | undefined) => {
    if (e) {
      return e;
    } else return "";
  };

  return (
    <div className={styles.main}>
      <div className={styles.summaryHeader}>
        <TicketDetailedFill className="ctrsSvg" />
        <span>{window.verbals["wizard.summary"] || "Summary"}</span>
      </div>
      <div className={styles.section}>
        <People size={25} color="#136FA3" className="ctrsSvg" />
        <div className={styles.peopleSection}>
          <div>
            {window.verbals["wizard.people"] || "People"}:&nbsp;
            {adultsAmount + childrenAmount + infantsAmount}
          </div>
          <div>
            {window.verbals["wizard.adults"] || "Adults(18+)"}:&nbsp;{" "}
            {adultsAmount}
          </div>
          <div>
            {window.verbals["wizard.children"] || "Children (2-17)"}:&nbsp;{" "}
            {childrenAmount}
          </div>
          <div>
            {window.verbals["wizard.Infants"] || "Infants (0-1)"}
            :&nbsp; {infantsAmount}
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <TravelSVG width="24" />
        <div>
          {window.verbals["wizard.pickUpExcursion"] || "Pick up Excursion"}
          :&nbsp;
          {lowerize(pickupExcursion)}
        </div>
      </div>
      <div className={styles.section}>
        <LocationSVG width="17" />
        <div>
          {window.verbals["wizard.pickupLocation"] || "Pick up Location"}:&nbsp;
          {lowerize(pickupLocation)}
        </div>
      </div>
      <div className={styles.section}>
        <MapPinSVG width="19" />
        <div>
          {window.verbals["wizard.pickupHotel"] || "Pick up Hotel"}:&nbsp;
          {lowerize(pickupPoint)}
        </div>
      </div>
      <div className={styles.section}>
        <GlobeSVG width="24" />
        <div>
          {window.verbals["wizard.language"] || "Language"}:&nbsp;{language}
        </div>
      </div>
      <div className={styles.section}>
        <CalendarSVG width="22" />
        <div>
          {window.verbals["wizard.date"] || "Date"}:&nbsp;
          {date?.toLocaleDateString() || "None Selected"}
        </div>
      </div>
      {selectedExtras.length > 0 && (
        <div className={styles.section}>
          <ExtrasSVG width="22" />
          <div>
            {window.verbals["wizard.extras"] || "Extras"}:&nbsp;
            {selectedExtras.map((selEx, index) => (
              <span key={index}>
                {availableExtras.find((ex) => ex.id === selEx)?.title_en}
                {index < selectedExtras.length - 1 ? " , " : ""}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.totalPriceHeader}>
          {window.verbals["wizard.totalPrice"] || "Total Price"}
        </div>
        <div className={styles.small}>
          {window.verbals["wizard.taxesincluded"] || "All taxes included"}
        </div>
        <div className={styles.totalPriceHeader}>{totalPrice}€</div>
      </div>
    </div>
  );
}
