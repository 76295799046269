import React, { useRef } from "react";
import styles from "./BusyIndicator.module.scss";
export interface IBusyProps {
  isVisible: boolean;
  size?: "big" | "small";
}

const BusyIndicator: React.VFC<IBusyProps> = ({
  isVisible,
  size = "small",
}) => {
  const myRef = useRef<HTMLSpanElement>(null);
  return (
    <span
      ref={myRef}
      className={`${styles.busy} ${size === "big" ? styles.big : ""} `}
      style={{ visibility: isVisible ? "visible" : "hidden" }}
    />
  );
};
export default BusyIndicator;
