import { categoryT, genderT, passenger } from "../App";
import BirthdatePicker from "../BirthdatePicker/BirthdatePicker";
import GenderPicker from "../GenderPicker/GenderPicker";
import SearchPicker from "../SearchPicker/SearchPicker";
import { PersonSVG } from "../svgs";
import styles from "./Passenger.module.scss";
interface IPassenger {
  submitAttempted: boolean;
  index: number;
  person: passenger;
  update: (field: keyof passenger, value: string | null, index: number) => void;
  updateGender: (gender: genderT, category: categoryT, index: number) => void;
}

export function Passenger({
  index,
  person,
  update,
  updateGender,
  submitAttempted,
}: IPassenger) {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <PersonSVG width="20" />
        <span>{`Passenger${index + 1}:`}</span>
      </div>
      <div className={styles.inputsWrapper}>
        <div className={styles.inputWrapper}>
          <input
            className={`${styles.inputMain} ${
              submitAttempted && person.firstName === "" ? styles.errored : ""
            }`}
            data-invalid={submitAttempted && person.firstName === ""}
            type="text"
            placeholder={`${
              window.verbals["wizard.firstname"] || "First name"
            } *`}
            value={person.firstName}
            onChange={(e) => update("firstName", e.target.value, index)}
            maxLength={30}
          />
          {submitAttempted && person.firstName === "" && (
            <div className={styles.error}>
              {window.verbals["wizard.fieldRequired"] ||
                "This field is required"}
            </div>
          )}
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={`${styles.inputMain} ${
              submitAttempted && person.lastName === "" ? styles.errored : ""
            }`}
            data-invalid={submitAttempted && person.lastName === ""}
            type="text"
            placeholder={`${
              window.verbals["wizard.lastname"] || "Last name"
            } *`}
            value={person.lastName}
            onChange={(e) => update("lastName", e.target.value, index)}
            maxLength={30}
          />
          {submitAttempted && person.lastName === "" && (
            <div className={styles.error}>
              {window.verbals["wizard.fieldRequired"] ||
                "This field is required"}
            </div>
          )}
        </div>

        <BirthdatePicker
          submitAttempted={submitAttempted}
          birthdate={person.birthdate}
          update={(e) => update("birthdate", e, index)}
        />

        <GenderPicker
          category={person.category}
          gender={person.gender}
          updateGender={(gender: genderT, category: categoryT) =>
            updateGender(gender, category, index)
          }
        />
        <div className={styles.inputWrapper} style={{ width: "unset" }}>
          <input
            className={`${styles.inputMain} ${styles.small} ${
              submitAttempted &&
              person.idCard === "" &&
              person.category === "ADULT"
                ? styles.errored
                : ""
            }`}
            type="text"
            data-invalid={
              submitAttempted &&
              person.idCard === "" &&
              person.category === "ADULT"
            }
            placeholder={`${window.verbals["wizard.idcard"] || "Id Card"} `}
            value={person.idCard}
            onChange={(e) => update("idCard", e.target.value, index)}
            maxLength={20}
          />
          {submitAttempted &&
            person.idCard === "" &&
            person.category === "ADULT" && (
              <div className={styles.error}>
                {window.verbals["wizard.fieldRequired"] ||
                  "This field is required"}
              </div>
            )}
        </div>

        <SearchPicker
          submitAttempted={submitAttempted}
          initValue={person.nationality}
          update={(e) => update("nationality", e, index)}
        />
      </div>
    </div>
  );
}
