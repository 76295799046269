import Lottie from "lottie-web";
import { useEffect, useRef, useState } from "react";
import "./App.scss";
import "./Calendar.css";
import DropDown from "./DropDown/DropDown";
import { Passenger } from "./Passenger/Passenger";
import PersonPicker from "./PersonPicker/PersonPicker";
import PersonPickerAll from "./PersonPickerAll/PersonPickerAll";
import Summary from "./Summary/Summary";
import {
  getDates,
  getExcursions,
  getLanguages,
  getLocations,
  getPlaces,
  postReservation,
} from "./utils/api";

import { ChevronLeft, ChevronRight, Wallet } from "react-bootstrap-icons";
import BusyIndicator from "./BusyIndicator/BusyIndicator";
import ExtrasPicker from "./ExtrasPicker/ExtrasPicker";
import styles from "./Passenger/Passenger.module.scss";
import SearchPicker from "./SearchPicker/SearchPicker";
import styles1 from "./Summary/Summary.module.scss";
import { countriesExtended } from "./utils/countries";

declare global {
  interface Window {
    ctrsApiUrl: string;
    initStatus: any;
    verbals: any;
    assets: string;
    postReservationUrl: string;
    wizardBackgroundUrl: string;
  }
}

export type place = {
  name: string;
  id: string;
  lat: number;
  lng: number;
  availableAccommodations: string;
  pickupTime: string;
  price: number;
};

export type service = {
  id: string;
  description: string;
  price: number;
};

export type language = {
  name: string;
  id: string;
};

export type day = {
  name: string;
  date: number;
  month: number;
};

export type location = {
  id: string;
  name: string;
};
export type extra = {
  id: string;
  price: number;
  title_en: string;
};

export type excursion = {
  id: string;
  name: string;
  code: string;
  pricePerAdult: number;
  pricePerChild: number;
  pricePerInfant: number;
  extras?: extra[];
};

export type prices = {
  Adults: number;
  Children: number;
  Infants: number;
};

export type passenger = {
  firstName: string;
  lastName: string;
  birthdate: string | null;
  gender: genderT;
  category: categoryT;
  idCard: string;
  nationality: string;
};
export type genderT = "MALE" | "FEMALE";
export type categoryT = "ADULT" | "CHILD" | "INFANT";

export const minimumFetchDuration = 100;

const emailValidPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const phoneValidPattern = /[0-9]{10,}/;

const assetsPath = (filename: string) => {
  if (window.assets) {
    return `${window.assets}${filename}`;
  } else {
    return filename;
  }
};

function App() {
  const [step, setStep] = useState(0);
  const [showPaymentFooter, setShowPaymentFooter] = useState(false);
  const [isBusyMain, setIsBusyMain] = useState(true);
  const [isBusyInit, setIsBusyInit] = useState(true);

  const [errored, setErrored] = useState(false);
  const [showDateAlert, setShowDayAlert] = useState(false);

  const [languages, setLanguages] = useState<language[]>([]);
  const [langSelected, setLangSelected] = useState("");
  const [languageDone, setLanguageDone] = useState(false);

  const [days, setDays] = useState<day[]>([]);
  const [daySelected, setDaySelected] = useState(-1);
  const [datesDone, setDatesDone] = useState(false);

  const fromHist = useRef(false);

  const [prices, setPrices] = useState<prices>({
    Adults: -1,
    Children: -1,
    Infants: -1,
  });
  const [totalPrice, setTotalPrice] = useState(-1);
  const [maleAdults, setMaleAdults] = useState(0);
  const [maleChildren, setMaleChildren] = useState(0);
  const [maleInfants, setMaleInfants] = useState(0);

  const [femaleAdults, setFemaleAdults] = useState(0);
  const [femaleChildren, setFemaleChildren] = useState(0);
  const [femaleInfants, setFemaleInfants] = useState(0);
  const [passengers, setPassengers] = useState<passenger[]>([]);
  const [holderEmail, setHolderEmail] = useState("");
  const [holderPhonecode, setHolderPhonecode] = useState("");
  const [holderPhone, setHolderPhone] = useState("");
  const [holderName, setHolderName] = useState("");
  const [holderCountry, setHolderCountry] = useState("");
  const [holderState, setHolderState] = useState("");
  const [holderAddress, setHolderAddress] = useState("");
  const [sellerCode, setSellerCode] = useState("");

  const [disableExPickup, setDisableExPickup] = useState(false);

  const [excursionSelected, setExcursionSelected] = useState("");
  const [excursions, setExcursions] = useState<excursion[]>([]);
  const [availableExtras, setAvailableExtras] = useState<extra[]>([]);
  const [selectedExtras, setSelectedExtras] = useState<string[]>([]);

  const [locationSelected, setLocationSelected] = useState("");
  const [locations, setLocations] = useState<location[]>([]);
  const [places, setPlaces] = useState<place[]>([]);
  const [pointSelected, setPointSelected] = useState("");
  const [apiHost, setApiHost] = useState("");
  const [postApiUrl, setPostApiUrl] = useState("");

  const [noAdultSelected, setNoAdultSelected] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [secondSubmitAttempted, setSecondSubmitAttempted] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [thirdSubmitAttempted, setThirdSubmitAttempted] = useState(false);
  const [initStatus, setInitStatus] = useState<any>(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const lottieResetRef = useRef<HTMLDivElement>(null);
  const lottieRedirectRef = useRef<HTMLDivElement>(null);
  const lottieErrorRef = useRef<HTMLDivElement>(null);

  const currentStepRef = useRef(0);

  useEffect(() => {
    if (window.initStatus) {
      setInitStatus(window.initStatus);
      if (window.initStatus.success) {
        setSuccess(true);
      } else {
        setFail(true);
        setStatusMessage(window.initStatus.statusMessage);
      }
    }
    setIsBusyInit(false);
  }, []);

  useEffect(() => {
    if (initStatus) {
      let depDate = new Date(
        initStatus.reservation.departureDate.split("T")[0]
      );

      let myPassengers: passenger[] = initStatus.reservation.passengers;
      for (let i = 0; i < myPassengers.length; i++) {
        myPassengers[i].birthdate = myPassengers[i].birthdate || null;
      }
      setPassengers(myPassengers);
      updatePassengerNumbers(myPassengers);

      setDaySelected(
        days.findIndex(
          (day) =>
            day.date === depDate.getDate() &&
            day.month === depDate.getMonth() + 1
        )
      );
      setExcursionSelected(initStatus.reservation.excursion);
      setLangSelected(initStatus.reservation.language);
      setHolderName(initStatus.reservation.cardholderName);
      setHolderPhone(initStatus.reservation.customerPhoneOriginal);
      setHolderPhonecode(initStatus.reservation.customerPhoneCode);
      setHolderState(initStatus.reservation.billingStateFull);
      setHolderEmail(initStatus.reservation.customerEmail);
      setHolderCountry(initStatus.reservation.billingCountry);
      setHolderAddress(initStatus.reservation.billingAddress);
      setLocationSelected(initStatus.reservation.location);
      setPointSelected(initStatus.reservation.pickupPoint);
      setTotalPrice(initStatus.totalPrice);
      setAvailableExtras(initStatus.reservation.extras);
      if (initStatus.reservation.reservationExtras) {
        setSelectedExtras(initStatus.reservation.reservationExtras);
      }
    }
  }, [days, initStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!fromHist.current) {
      window.history.pushState(step, "");
    } else {
      fromHist.current = false;
    }
    currentStepRef.current = step;
    if (step === 1) {
      document.documentElement.classList.add("ctrsAdjust");
    } else {
      document.documentElement.classList.remove("ctrsAdjust");
    }
  }, [step]);

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      if (e.state !== null) {
        if (e.state > currentStepRef.current) {
          window.history.back();
        } else if (e.state === currentStepRef.current) {
          fromHist.current = false;
        } else {
          fromHist.current = true;
          setStep(e.state);
        }
      } else {
        window.history.back();
      }
    });
  }, []);

  useEffect(() => {
    window.ctrsApiUrl = window.ctrsApiUrl || "https://www.ctrs.gr/backoffice";
    window.postReservationUrl = window.postReservationUrl || "";
    setApiHost(window.ctrsApiUrl);
    setPostApiUrl(window.postReservationUrl);
  }, []);

  useEffect(() => {
    if (excursionSelected !== "" && apiHost !== "") {
      if (!initStatus) {
        setLocationSelected("");
        setLangSelected("");
        let curExcursion = excursions.find((ex) => ex.id === excursionSelected);
        if (curExcursion) {
          setPrices({
            Adults: curExcursion.pricePerAdult,
            Children: curExcursion.pricePerChild,
            Infants: curExcursion.pricePerInfant,
          });
          if (curExcursion.extras) {
            setAvailableExtras(curExcursion.extras);
          }
          setSelectedExtras([]);
        }
      }
      getLanguages(
        setIsBusyMain,
        setLanguages,
        setErrored,
        excursionSelected,
        apiHost
      );
      getLocations(
        setIsBusyMain,
        setLocations,
        setErrored,
        excursionSelected,
        apiHost
      );
    }
  }, [apiHost, excursionSelected, excursions, initStatus]);

  useEffect(() => {
    let directExString = window.location.search.substring(1).split("=");
    if (directExString.length === 2) {
      if (directExString[0] === "ex") {
        if (excursions.find((ex) => ex.id === directExString[1])) {
          setExcursionSelected(directExString[1]);
          setDisableExPickup(true);
        }
      }
    }
  }, [excursions]);
  useEffect(() => {
    if (apiHost !== "") {
      getExcursions(setIsBusyMain, setExcursions, setErrored, apiHost);
    }
  }, [apiHost]);

  useEffect(() => {
    if (langSelected !== "") {
      setLanguageDone(true);
    }
  }, [langSelected]);

  useEffect(() => {
    if (daySelected !== -1) {
      setDatesDone(true);
    }
  }, [daySelected]);

  useEffect(() => {
    if (apiHost !== "") {
      if (!initStatus) {
        setDaySelected(-1);
      }
      if (langSelected !== "" && excursionSelected !== "") {
        getDates(
          setIsBusyMain,
          setDays,
          setErrored,
          langSelected,
          excursionSelected,
          apiHost
        );
      }
    }
  }, [apiHost, excursionSelected, initStatus, langSelected]);

  useEffect(() => {
    if (languageDone && daySelected === -1 && datesDone) {
      setShowDayAlert(true);
    }
  }, [datesDone, daySelected, languageDone]);

  useEffect(() => {
    if (showDateAlert && lottieResetRef.current) {
      Lottie.loadAnimation({
        container: lottieResetRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: assetsPath("/animations/reset-gif.json"),
      }).addEventListener("complete", function () {
        setShowDayAlert(false);
      });
    }
  }, [showDateAlert]);

  useEffect(() => {
    if (step === 3 && lottieRedirectRef.current) {
      Lottie.loadAnimation({
        container: lottieRedirectRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: assetsPath("/animations/loading.json"),
      });
    }
  }, [showDateAlert, step]);

  useEffect(() => {
    if (fail && lottieErrorRef.current) {
      Lottie.loadAnimation({
        container: lottieErrorRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: assetsPath("/animations/error.json"),
      });
    }
  }, [fail]);

  useEffect(() => {
    if (success && lottieErrorRef.current) {
      Lottie.loadAnimation({
        container: lottieErrorRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: assetsPath("/animations/success.json"),
      });
    }
  }, [success]);

  useEffect(() => {
    if (apiHost !== "") {
      if (!initStatus) {
        setPointSelected("");
      }
      if (locationSelected !== "") {
        getPlaces(
          setIsBusyMain,
          setPlaces,
          setErrored,
          locationSelected,
          apiHost
        );
      }
    }
  }, [apiHost, initStatus, locationSelected]);

  useEffect(() => {
    if (step > 0 || success || fail) {
      document.body.classList.add("removeSections");
    } else {
      document.body.classList.remove("removeSections");
    }
  }, [fail, step, success]);

  function createPassenger(arg1: genderT, arg2: categoryT) {
    let newPassenger: passenger = {
      firstName: "",
      lastName: "",
      birthdate: null,
      gender: arg1,
      category: arg2,
      idCard: "",
      nationality: "",
    };

    return newPassenger;
  }

  function createPassengers(
    maleAdultAmount: number,
    femaleAdultAmount: number,
    maleChildrenAmount: number,
    femaleChildrenAmount: number,
    maleInfantsAmount: number,
    femaleInfantsAmount: number
  ) {
    let tempPassengers = [];
    for (let i = 0; i < maleAdultAmount; i++) {
      tempPassengers.push(createPassenger("MALE", "ADULT"));
    }
    for (let i = 0; i < femaleAdultAmount; i++) {
      tempPassengers.push(createPassenger("FEMALE", "ADULT"));
    }
    for (let i = 0; i < maleChildrenAmount; i++) {
      tempPassengers.push(createPassenger("MALE", "CHILD"));
    }
    for (let i = 0; i < femaleChildrenAmount; i++) {
      tempPassengers.push(createPassenger("FEMALE", "CHILD"));
    }
    for (let i = 0; i < maleInfantsAmount; i++) {
      tempPassengers.push(createPassenger("MALE", "INFANT"));
    }
    for (let i = 0; i < femaleInfantsAmount; i++) {
      tempPassengers.push(createPassenger("FEMALE", "INFANT"));
    }
    setPassengers(tempPassengers);
  }

  function updateAdults(maleAmount: number, femaleAmount: number) {
    setMaleAdults(maleAmount);
    setFemaleAdults(femaleAmount);
    createPassengers(
      maleAmount,
      femaleAmount,
      maleChildren,
      femaleChildren,
      maleInfants,
      femaleInfants
    );
  }
  function updateChildren(maleAmount: number, femaleAmount: number) {
    setMaleChildren(maleAmount);
    setFemaleChildren(femaleAmount);
    createPassengers(
      maleAdults,
      femaleAdults,
      maleAmount,
      femaleAmount,
      maleInfants,
      femaleInfants
    );
  }
  function updateInfants(maleAmount: number, femaleAmount: number) {
    setMaleInfants(maleAmount);
    setFemaleInfants(femaleAmount);
    createPassengers(
      maleAdults,
      femaleAdults,
      maleChildren,
      femaleChildren,
      maleAmount,
      femaleAmount
    );
  }

  function updateAll(
    maleAdultAmount: number,
    femaleAdultAmount: number,
    maleChildrenAmount: number,
    femaleChildrenAmount: number,
    maleInfantsAmount: number,
    femaleInfantsAmount: number
  ) {
    setMaleAdults(maleAdultAmount);
    setFemaleAdults(femaleAdultAmount);
    setMaleChildren(maleChildrenAmount);
    setFemaleChildren(femaleChildrenAmount);
    setMaleInfants(maleInfantsAmount);
    setFemaleInfants(femaleInfantsAmount);
    createPassengers(
      maleAdultAmount,
      femaleAdultAmount,
      maleChildrenAmount,
      femaleChildrenAmount,
      maleInfantsAmount,
      femaleInfantsAmount
    );
  }

  function updatePerson(field: string, value: string | null, index: number) {
    if (index < passengers.length) {
      let myPassengers = passengers.slice();
      myPassengers[index] = { ...myPassengers[index], [field]: value };
      setPassengers(myPassengers);
      setShowPaymentFooter(true);
    }
  }

  function updatePersonGender(
    gender: genderT,
    category: categoryT,
    index: number
  ) {
    if (index < passengers.length) {
      let myPassengers = passengers.slice();
      myPassengers[index].gender = gender;
      myPassengers[index].category = category;
      setPassengers(myPassengers);
      updatePassengerNumbers(myPassengers);
    }
  }

  function updatePassengerNumbers(array: passenger[]) {
    setMaleAdults(
      array.filter(
        (person) => person.category === "ADULT" && person.gender === "MALE"
      ).length
    );
    setFemaleAdults(
      array.filter(
        (person) => person.category === "ADULT" && person.gender === "FEMALE"
      ).length
    );
    setMaleChildren(
      array.filter(
        (person) => person.category === "CHILD" && person.gender === "MALE"
      ).length
    );
    setFemaleChildren(
      array.filter(
        (person) => person.category === "CHILD" && person.gender === "FEMALE"
      ).length
    );
    setMaleInfants(
      array.filter(
        (person) => person.category === "INFANT" && person.gender === "MALE"
      ).length
    );
    setFemaleInfants(
      array.filter(
        (person) => person.category === "INFANT" && person.gender === "FEMALE"
      ).length
    );
  }

  function mainCost() {
    return (
      maleAdults * prices.Adults +
      maleChildren * prices.Children +
      maleInfants * prices.Infants +
      femaleAdults * prices.Adults +
      femaleChildren * prices.Children +
      femaleInfants * prices.Infants
    );
  }

  function pickupCosts() {
    let cost = places.find(({ id }) => id === pointSelected)?.price || 0;

    return (
      maleAdults * cost +
      maleChildren * cost +
      femaleAdults * cost +
      femaleChildren * cost
    );
  }

  function extrasCost() {
    let cost = 0;
    selectedExtras.forEach((selEx) => {
      cost += availableExtras.find((ex) => ex.id === selEx)!.price;
    });
    return (
      maleAdults * cost +
      maleChildren * cost +
      femaleAdults * cost +
      femaleChildren * cost
    );
  }

  function finalCost() {
    const peoplePrice = mainCost();
    return peoplePrice > 0 ? pickupCosts() + mainCost() + extrasCost() : 0;
  }

  function firstsubmit() {
    if (
      excursionSelected === "" ||
      locationSelected === "" ||
      pointSelected === "" ||
      langSelected === "" ||
      daySelected === -1
    ) {
      setSubmitAttempted(true);
      (document.querySelector("[data-invalid='true']") as HTMLElement)?.focus();
    } else {
      if (maleAdults === 0 && femaleAdults === 0) {
        setNoAdultSelected(true);
      } else {
        setStep(1);
      }
    }
  }

  function secondSubmit() {
    if (maleAdults === 0 && femaleAdults === 0) {
      setNoAdultSelected(true);
      return;
    }
    let invalidFlag = false;
    for (let i = 0; i < passengers.length; i++) {
      let person = passengers[i];
      if (
        person.birthdate === null ||
        person.firstName === "" ||
        person.lastName === "" ||
        person.nationality === ""
      ) {
        setSecondSubmitAttempted(true);
        invalidFlag = true;
        break;
      }
    }
    if (
      holderEmail === "" ||
      !emailValidPattern.test(holderEmail) ||
      holderPhone === "" ||
      !phoneValidPattern.test(holderPhone) ||
      holderPhonecode === "" ||
      holderName === "" ||
      holderCountry === "" ||
      invalidState() ||
      holderAddress === ""
    ) {
      setSecondSubmitAttempted(true);
      invalidFlag = true;
    }
    if (
      daySelected === -1 ||
      locationSelected === "" ||
      pointSelected === "" ||
      langSelected === ""
    ) {
      setSubmitAttempted(true);
      setSecondSubmitAttempted(true);
      invalidFlag = true;
    }
    if (!invalidFlag) {
      setStep(2);
    } else {
      (document.querySelector("[data-invalid='true']") as HTMLElement)?.focus();
    }
  }

  function thirdSubmit() {
    if (paymentMethod === "" || !acceptedTerms) {
      setThirdSubmitAttempted(true);
    } else {
      submitData();
    }
  }

  useEffect(() => {
    if (submitAttempted || secondSubmitAttempted) {
      (document.querySelector("[data-invalid='true']") as HTMLElement)?.focus();
    }
  }, [secondSubmitAttempted, submitAttempted]);

  const departureTime = () => {
    const year = new Date().getFullYear();
    const month = days[daySelected]?.month;
    const formatedMonth = month > 9 ? month : `0${month}`;
    const date = days[daySelected]?.date;
    const formatedDate = date > 9 ? date : `0${date}`;
    const time = places.find(({ id }) => id === pointSelected)?.pickupTime;
    const mydate = new Date(`${year}-${formatedMonth}-${formatedDate}T${time}`);
    return isNaN(mydate.getTime())
      ? null
      : `${year}-${formatedMonth}-${formatedDate}T${time?.substring(
          0,
          time.lastIndexOf(":")
        )}`;
  };

  const selectedDate = () => {
    const year = new Date().getFullYear();
    const month = days[daySelected]?.month;
    const formatedMonth = month > 9 ? month : `0${month}`;
    const date = days[daySelected]?.date;
    const formatedDate = date > 9 ? date : `0${date}`;

    const mydate = new Date(`${year}-${formatedMonth}-${formatedDate}`);
    return isNaN(mydate.getDate()) ? null : mydate;
  };

  const totalPersons = () => {
    return (
      maleAdults +
      femaleAdults +
      maleChildren +
      femaleChildren +
      maleInfants +
      femaleInfants
    );
  };

  const submitData = () => {
    const object = {
      excursion: excursionSelected,
      language: langSelected,
      location: locationSelected,
      pickupPoint: pointSelected,
      pickupPointPrice:
        places.find(({ id }) => id === pointSelected)?.price || -1,
      departureDate: departureTime(),
      customerName: holderName,
      cardholderName: holderName,
      customerPhone: holderPhonecode.split("+")[1] + "-" + holderPhone,
      customerPhoneOriginal: holderPhone,
      customerPhoneCode: holderPhonecode,
      customerEmail: holderEmail,
      customerAccommodation: "Hotel",
      customerRoomNumber: "Room",
      billingCountry: holderCountry,
      billingState: holderState.split("-")[1],
      billingStateFull: holderState,
      billingAddress: holderAddress,
      billingCity: "Heraklion",
      billingZipCode: "12345",
      adults: maleAdults + femaleAdults,
      children: maleChildren + femaleChildren,
      infants: maleInfants + femaleInfants,
      sellerCode: sellerCode,
      paymentMethod: "ONLINE_PAYMENT",
      excursionPriceDetails: {
        adultsPrice: prices.Adults,
        childrenPrice: prices.Children,
        infantsPrice: prices.Infants,
      },
      excursionPrice: mainCost(),
      passengers: passengers,
      totalPrice: finalCost(),
      paymentFee: 0,
      notes: "",
      extras: availableExtras,
      reservationExtras: selectedExtras,
    };
    postReservation(setIsBusyMain, object, setErrored, postApiUrl, setStep);
  };

  function invalidState() {
    const filteredCountry = countriesExtended.find(
      (e) => e.alpha2 === holderCountry
    );
    if (
      filteredCountry &&
      filteredCountry?.subdivisions.length > 0 &&
      holderState === ""
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!initStatus) {
      setHolderState("");
    }
  }, [holderCountry, initStatus]);

  useEffect(() => {
    if (isBusyMain) {
      document.body.classList.add("waiting");
    } else {
      document.body.classList.remove("waiting");
    }
  }, [isBusyMain]);

  return (
    <div
      className={`ctrs ${step === 0 ? "stepOne" : ""}`}
      style={{
        backgroundImage: `url("${
          !(fail || success)
            ? step === 0
              ? window.wizardBackgroundUrl
                ? window.wizardBackgroundUrl
                : ""
              : assetsPath("/img/back.svg")
            : assetsPath("/img/backConf.svg")
        }")`,
      }}
    >
      {isBusyInit ? (
        <div className="busyWrapper">
          <BusyIndicator size="big" isVisible={true} />
        </div>
      ) : !errored ? (
        !success && !fail ? (
          step === 0 ? (
            <div className="ctrsContainer">
              <h1 className="mainTitle">
                {window.verbals["wizard.mainTitle"] || "Book the tour"}
              </h1>

              <h2 className="secondTitle">
                {window.verbals["wizard.secondaryTitle"] ||
                  "Discover the breathtaking beauty of Santorini with ease and convenience"}
              </h2>

              <div className="mainContainer">
                {showDateAlert && (
                  <div className="dateNotification">
                    <div className="animationWrapper">
                      <div ref={lottieResetRef}></div>
                    </div>
                    {window.verbals["wizard.resetDate"] ||
                      "Date option was reset due to changing language, please choose your date again."}
                  </div>
                )}
                {noAdultSelected && (
                  <div className="dateNotification">
                    <div className="description">
                      {window.verbals["wizard.adultPrompt"] ||
                        "You need at least one adult selected. You will proceed with one adult selected."}
                    </div>
                    <div className="centered">
                      <button
                        className="proceed"
                        onClick={() => {
                          updateAdults(1, 0);
                          setNoAdultSelected(false);
                          setStep(1);
                        }}
                      >
                        {window.verbals["wizard.proceedPrompt"] ||
                          "Ok, proceed"}
                      </button>
                    </div>
                  </div>
                )}
                <div className="menuContainer">
                  <DropDown
                    disableExPickup={disableExPickup}
                    options={excursions}
                    type="excursion"
                    setCallback={setExcursionSelected}
                    errored={excursionSelected === "" && submitAttempted}
                    initExcursion={excursionSelected}
                  />
                  <DropDown
                    options={locations}
                    type="location"
                    setCallback={setLocationSelected}
                    errored={locationSelected === "" && submitAttempted}
                    initLocation={locationSelected}
                  />
                  <DropDown
                    options={places}
                    type="point"
                    setCallback={setPointSelected}
                    errored={pointSelected === "" && submitAttempted}
                    initPoint={pointSelected}
                  />
                  <DropDown
                    options={languages}
                    type="lang"
                    setCallback={setLangSelected}
                    errored={langSelected === "" && submitAttempted}
                    initLang={langSelected}
                  />
                  <DropDown
                    options={days}
                    type="date"
                    setCallback={setLocationSelected}
                    setDaySelected={setDaySelected}
                    errored={daySelected === -1 && submitAttempted}
                    initDate={selectedDate()}
                  />
                  {availableExtras.length > 0 && (
                    <ExtrasPicker
                      options={availableExtras}
                      selectedExtras={selectedExtras}
                      setSelectedExtras={setSelectedExtras}
                    />
                  )}
                </div>
                <div className="secondMenuContainer">
                  <div className="personPickerWrapper">
                    <PersonPicker
                      disabled={excursionSelected === ""}
                      title={window.verbals["wizard.adults"] || "Adults(18+)"}
                      cost={prices.Adults}
                      maleAmount={maleAdults}
                      femaleAmount={femaleAdults}
                      updateFunction={updateAdults}
                    />
                    <PersonPicker
                      disabled={excursionSelected === ""}
                      title={
                        window.verbals["wizard.children"] || "Children (2-17)"
                      }
                      cost={prices.Children}
                      maleAmount={maleChildren}
                      femaleAmount={femaleChildren}
                      updateFunction={updateChildren}
                    />
                    <PersonPicker
                      disabled={excursionSelected === ""}
                      title={
                        window.verbals["wizard.Infants"] || "Infants (0-2)"
                      }
                      cost={prices.Infants}
                      maleAmount={maleInfants}
                      femaleAmount={femaleInfants}
                      updateFunction={updateInfants}
                    />
                    <div className="costIndicatorMobile">
                      <div>{window.verbals["wizard.price"] || "Price"}</div>
                      <span>{finalCost() || ""}&nbsp;€</span>
                    </div>
                  </div>
                  <div className="costIndicator">
                    {finalCost() || ""}&nbsp;€
                  </div>
                  <div className="proceedWrapper">
                    <button className="proceed" onClick={() => firstsubmit()}>
                      {window.verbals["wizard.selectTour"] || "Select Tour"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 1 ? (
            <>
              {showDateAlert && (
                <div className="dateNotification">
                  <div className="animationWrapper">
                    <div ref={lottieResetRef}></div>
                  </div>
                  {window.verbals["wizard.resetDate"] ||
                    "Date option was reset due to changing language, please choose your date again."}
                </div>
              )}

              <div className="menuContainerTop">
                <span className="buttonLike">
                  {window.verbals["wizard.editTour"] || "Edit your tour"}
                </span>
                <div className="mobileHelper">
                  <PersonPickerAll
                    title={`People: ${totalPersons()}`}
                    adultCost={prices.Adults}
                    childrenCost={prices.Children}
                    infantsCost={prices.Infants}
                    maleAdultAmount={maleAdults}
                    femaleAdultAmount={femaleAdults}
                    maleChildrenAmount={maleChildren}
                    femaleChildrenAmount={femaleChildren}
                    maleInfantsAmount={maleInfants}
                    femaleInfantsAmount={femaleInfants}
                    updateFunction={updateAll}
                  />
                  <DropDown
                    disableExPickup={disableExPickup}
                    options={excursions}
                    type="excursion"
                    setCallback={setExcursionSelected}
                    errored={excursionSelected === "" && submitAttempted}
                    initExcursion={excursionSelected}
                    variation="top"
                  />
                  <DropDown
                    options={locations}
                    type="location"
                    setCallback={setLocationSelected}
                    errored={locationSelected === "" && submitAttempted}
                    initLocation={locationSelected}
                    variation="top"
                  />
                  <DropDown
                    options={places}
                    type="point"
                    setCallback={setPointSelected}
                    errored={pointSelected === "" && submitAttempted}
                    initPoint={pointSelected}
                    variation="top"
                  />
                  {availableExtras.length > 0 && (
                    <ExtrasPicker
                      options={availableExtras}
                      selectedExtras={selectedExtras}
                      setSelectedExtras={setSelectedExtras}
                      variation="top"
                    />
                  )}
                  <DropDown
                    options={languages}
                    type="lang"
                    setCallback={setLangSelected}
                    errored={langSelected === "" && submitAttempted}
                    initLang={langSelected}
                    variation="top"
                  />

                  <DropDown
                    options={days}
                    type="date"
                    setCallback={setLocationSelected}
                    setDaySelected={setDaySelected}
                    errored={daySelected === -1 && submitAttempted}
                    initDate={selectedDate()}
                    variation="top"
                  />
                </div>
                {showPaymentFooter && (
                  <div className="mobilePayfooter">
                    <span>{finalCost() || ""}&nbsp;€</span>
                    <button onClick={() => secondSubmit()}>
                      <span>
                        {window.verbals["wizard.paymentProceed"] ||
                          "Proceed to Payment"}
                      </span>
                      <span>
                        <ChevronRight color="#004E7A" />
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="detailsView">
                <div className="passengersWrapper">
                  <div className="passengersMain ">
                    {noAdultSelected && (
                      <div className="dateNotification fixed">
                        <div className="description">
                          {window.verbals["wizard.adultPrompt"] ||
                            "You need at least one adult selected. You will proceed with one adult selected."}
                        </div>
                        <div className="centered">
                          <button
                            className="proceed"
                            onClick={() => {
                              updateAdults(1, 0);
                              setNoAdultSelected(false);
                            }}
                          >
                            {window.verbals["wizard.proceedPrompt"] ||
                              "Ok, proceed"}
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="passengerDetails">
                      <div className="formHeader">
                        {passengers.length === 0
                          ? window.verbals["wizard.atLeastOnePassenger"] ||
                            "Select at least one passenger"
                          : window.verbals["wizard.passengerInformation"] ||
                            "Please enter passengers' information"}
                      </div>
                      {passengers.map((person, index) => (
                        <Passenger
                          key={`Passenger${index}`}
                          index={index}
                          person={person}
                          update={updatePerson}
                          updateGender={updatePersonGender}
                          submitAttempted={secondSubmitAttempted}
                        />
                      ))}
                      {passengers.length > 0 && (
                        <div className="subNote">
                          {window.verbals["wizard.requiredNote"] ||
                            "* Fields marked with an asterisk (*) are required"}
                        </div>
                      )}
                    </div>

                    <div className="passengerDetails bigPadded">
                      <div className="formHeader">
                        {window.verbals["wizard.holderDetails"] ||
                          "Please enter the booking holder contact details"}
                      </div>

                      <div className={styles.inputsWrapperHolder}>
                        <div className={`${styles.inputWrapper} ${styles.big}`}>
                          <input
                            className={`${styles.inputMain}  ${
                              secondSubmitAttempted &&
                              (holderEmail === "" ||
                                !emailValidPattern.test(holderEmail))
                                ? styles.errored
                                : ""
                            }`}
                            type="email"
                            placeholder={`${
                              window.verbals["wizard.emailAddress"] ||
                              "Email address"
                            } *`}
                            data-invalid={
                              secondSubmitAttempted &&
                              (holderEmail === "" ||
                                !emailValidPattern.test(holderEmail))
                            }
                            value={holderEmail}
                            onChange={(e) => setHolderEmail(e.target.value)}
                          />
                          {secondSubmitAttempted && holderEmail === "" ? (
                            <div className={styles.error}>
                              {window.verbals["wizard.fieldRequired"] ||
                                "This field is required"}
                            </div>
                          ) : secondSubmitAttempted &&
                            !emailValidPattern.test(holderEmail) ? (
                            <div className={styles.error}>
                              {window.verbals["wizard.validEmail"] ||
                                "Please enter a valid email address"}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={`${styles.inputsWrapperSmall} noConstraint`}
                        >
                          <SearchPicker
                            variation="phoneCode"
                            initValue={holderPhonecode}
                            update={(e) => setHolderPhonecode(e)}
                            submitAttempted={secondSubmitAttempted}
                          />

                          <div
                            className={`${styles.inputWrapper} ${styles.medium}`}
                          >
                            <input
                              className={`${styles.inputMain}  ${
                                secondSubmitAttempted &&
                                (holderPhone === "" ||
                                  !phoneValidPattern.test(holderPhone))
                                  ? styles.errored
                                  : ""
                              }`}
                              data-invalid={
                                secondSubmitAttempted &&
                                (holderPhone === "" ||
                                  !phoneValidPattern.test(holderPhone))
                              }
                              type="text"
                              pattern="[0-9]{10,}"
                              placeholder={`${
                                window.verbals["wizard.phoneNumber"] ||
                                "Phone number"
                              } *`}
                              value={holderPhone}
                              onChange={(e) => setHolderPhone(e.target.value)}
                            />
                            {secondSubmitAttempted && holderPhone === "" ? (
                              <div className={styles.error}>
                                {window.verbals["wizard.fieldRequired"] ||
                                  "This field is required"}
                              </div>
                            ) : secondSubmitAttempted &&
                              !phoneValidPattern.test(holderPhone) ? (
                              <div className={styles.error}>
                                {window.verbals["wizard.validPhone"] ||
                                  " Please enter a valid phone number"}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className={styles.inputsWrapperHolder}>
                        <div className={`${styles.inputWrapper} ${styles.big}`}>
                          <input
                            className={`${styles.inputMain}  ${
                              secondSubmitAttempted && holderName === ""
                                ? styles.errored
                                : ""
                            }`}
                            type="text"
                            placeholder={`${
                              window.verbals["wizard.holderName"] || "Full Name"
                            } *`}
                            data-invalid={
                              secondSubmitAttempted && holderEmail === ""
                            }
                            value={holderName}
                            onChange={(e) => setHolderName(e.target.value)}
                          />
                          {secondSubmitAttempted && holderName === "" ? (
                            <div className={styles.error}>
                              {window.verbals["wizard.fieldRequired"] ||
                                "This field is required"}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={`${styles.inputWrapper} ${styles.big} holderDropdownWrapper`}
                        >
                          <SearchPicker
                            variation="country"
                            update={(e) => setHolderCountry(e)}
                            initValue={holderCountry}
                            submitAttempted={secondSubmitAttempted}
                          />
                        </div>
                        <div
                          className={`${styles.inputWrapper} ${styles.big} holderDropdownWrapper`}
                        >
                          <SearchPicker
                            variation="state"
                            update={(e) => setHolderState(e)}
                            initValue={holderState}
                            submitAttempted={secondSubmitAttempted}
                            initCountry={holderCountry}
                          />
                        </div>
                        <div className={`${styles.inputWrapper} ${styles.big}`}>
                          <input
                            className={`${styles.inputMain}  ${
                              secondSubmitAttempted && holderAddress === ""
                                ? styles.errored
                                : ""
                            }`}
                            type="text"
                            placeholder={`${
                              window.verbals["wizard.holderAddress"] ||
                              "Address"
                            } *`}
                            data-invalid={
                              secondSubmitAttempted && holderAddress === ""
                            }
                            value={holderAddress}
                            onChange={(e) => setHolderAddress(e.target.value)}
                          />
                          {secondSubmitAttempted && holderAddress === "" ? (
                            <div className={styles.error}>
                              {window.verbals["wizard.fieldRequired"] ||
                                "This field is required"}
                            </div>
                          ) : null}
                        </div>
                        <div className={`${styles.inputWrapper} ${styles.big}`}>
                          <input
                            className={styles.inputMain}
                            type="text"
                            placeholder={`${
                              window.verbals["wizard.sellerCode"] ||
                              "Seller Code"
                            }`}
                            value={sellerCode}
                            onChange={(e) => setSellerCode(e.target.value)}
                          />
                        </div>
                        <div
                          className={`${styles.inputWrapper} ${styles.big}`}
                        ></div>
                      </div>
                      <div className="subNote marginedTop">
                        {window.verbals["wizard.requiredNote"] ||
                          "* Fields marked with an asterisk (*) are required"}
                      </div>
                    </div>
                    <div className="paymentButton">
                      <button onClick={() => secondSubmit()}>
                        <span></span>
                        <span>
                          {window.verbals["wizard.paymentProceed"] ||
                            "Proceed to Payment"}
                        </span>

                        <span className="paymentIcon">
                          <ChevronRight />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="summaryWrapper">
                  <Summary
                    adultsAmount={maleAdults + femaleAdults}
                    childrenAmount={maleChildren + femaleChildren}
                    infantsAmount={maleInfants + femaleInfants}
                    pickupExcursion={
                      excursions.find((ex) => ex.id === excursionSelected)
                        ?.name || "None selected"
                    }
                    pickupLocation={
                      locations.find((loc) => loc.id === locationSelected)
                        ?.name || "None selected"
                    }
                    pickupPoint={
                      places.find((place) => place.id === pointSelected)
                        ?.name || "None selected"
                    }
                    language={langSelected || "None selected"}
                    selectedExtras={selectedExtras}
                    availableExtras={availableExtras}
                    date={selectedDate()}
                    totalPrice={finalCost()}
                  />
                </div>
              </div>
            </>
          ) : step === 2 ? (
            <div className="detailsView">
              <div className="passengersWrapper rightBigPadded">
                <div className="paymentWrapper">
                  <div className={`${styles1.summaryHeader} paymentHeader`}>
                    <Wallet className="ctrsSvg" size={35} />
                    <span>
                      {window.verbals["wizard.paymentMethod"] ||
                        "Please choose your payment method"}
                    </span>
                  </div>
                  <div className="paymentBody">
                    <input
                      type="radio"
                      id="credit"
                      value="credit"
                      checked={paymentMethod === "credit"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="radioLabel" htmlFor="credit">
                      {window.verbals["wizard.creditCard"] ||
                        "Credit/Debit Card"}{" "}
                      <img alt="cards" src={assetsPath("/img/cards.png")}></img>
                    </label>
                    <div className="consentWrapper">
                      <input
                        type="checkbox"
                        id="termsConsent"
                        checked={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                      />
                      <label className="checkLabel" htmlFor="termsConsent">
                        {window.verbals["wizard.accepted"] ||
                          "I have read and accepted"}
                        &nbsp;
                        <a href="/terms">
                          {window.verbals["wizard.termsOfService"] ||
                            "terms and conditions"}
                        </a>
                      </label>
                    </div>
                    <div className="paymentNote">
                      {window.verbals["wizard.redirectionNote"] ||
                        "Ιn case of choosing the Credit/Debit Card Payment Method, you will be redirected to an external and secure payment page."}
                    </div>

                    {thirdSubmitAttempted && paymentMethod === "" && (
                      <div className="paymentError">
                        {window.verbals["wizard.paymentMethod"] ||
                          "Please choose your payment method"}
                      </div>
                    )}
                    {thirdSubmitAttempted && !acceptedTerms && (
                      <div className="paymentError">
                        {window.verbals["wizard.acceptPrompt"] ||
                          "Please accept the terms of use."}
                      </div>
                    )}
                    <div className="paymentButton blocked">
                      <button onClick={() => thirdSubmit()}>
                        <span></span>
                        <span>
                          {window.verbals["wizard.payNow"] || "Pay now"}
                        </span>

                        <span className="paymentIcon">
                          <ChevronRight className="ctrsSvg" />
                        </span>
                      </button>
                      <button className="backButton" onClick={() => setStep(1)}>
                        <span className="paymentIcon">
                          <ChevronLeft className="ctrsSvg" />
                        </span>

                        <span>
                          {window.verbals["wizard.backtoBooking"] ||
                            "Back to Booking Information"}
                        </span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summaryWrapper">
                <Summary
                  adultsAmount={maleAdults + femaleAdults}
                  childrenAmount={maleChildren + femaleChildren}
                  infantsAmount={maleInfants + femaleInfants}
                  pickupExcursion={
                    excursions.find((ex) => ex.id === excursionSelected)
                      ?.name || "None selected"
                  }
                  pickupLocation={
                    locations.find((loc) => loc.id === locationSelected)
                      ?.name || "None selected"
                  }
                  pickupPoint={
                    places.find((place) => place.id === pointSelected)?.name ||
                    "None selected"
                  }
                  language={langSelected || "None selected"}
                  selectedExtras={selectedExtras}
                  availableExtras={availableExtras}
                  date={selectedDate()}
                  totalPrice={finalCost()}
                />
              </div>
            </div>
          ) : step === 3 ? (
            <div className="redirection">
              <img alt="ctrs" src={assetsPath("/img/CTRS.png")} />
              <div className="wrapper">
                <div className="redirectHeader">
                  {window.verbals["wizard.redirecting"] || "Redirecting"}
                </div>
                <div className="redirectBody">
                  {window.verbals["wizard.waitRedirecting"] ||
                    "Please wait a while. You are being redirected to a safe and secure enviroment"}
                </div>
              </div>
              <div ref={lottieRedirectRef}></div>
            </div>
          ) : (
            <div></div>
          )
        ) : success ? (
          <div className="successWrapper">
            <div className="successDetails">
              <div className="successContainer">
                <div ref={lottieErrorRef} className="errorAnimation"></div>
                <div className="successHeader">
                  {window.verbals["wizard.bookingConfirmed"] ||
                    "Your Booking is confirmed"}
                </div>
                <div className="confirm">
                  {window.verbals["wizard.bookingNumber"] ||
                    "Your Booking Number is"}{" "}
                  &nbsp;
                  {initStatus.reservationReference}
                </div>
                <div className="mailPrompt">
                  {window.verbals["wizard.checkEmail"] ||
                    "Please check your mail for your tour's summary"}
                </div>
                <div className="confirm limited">
                  {window.verbals["wizard.thankyou"] ||
                    "Thank you for using C.TR.S for your adventure"}
                </div>
              </div>
            </div>
            <div className="successSummary">
              <Summary
                adultsAmount={maleAdults + femaleAdults}
                childrenAmount={maleChildren + femaleChildren}
                infantsAmount={maleInfants + femaleInfants}
                pickupExcursion={
                  excursions.find((ex) => ex.id === excursionSelected)?.name ||
                  "None selected"
                }
                pickupLocation={
                  locations.find((loc) => loc.id === locationSelected)?.name ||
                  "None selected"
                }
                pickupPoint={
                  places.find((place) => place.id === pointSelected)?.name ||
                  "None selected"
                }
                language={langSelected || "None selected"}
                selectedExtras={selectedExtras}
                availableExtras={availableExtras}
                date={selectedDate()}
                totalPrice={totalPrice}
              />
            </div>
          </div>
        ) : (
          <div className="failWrapper">
            <div ref={lottieErrorRef} className="errorAnimation"></div>
            <div className="successHeader">
              {window.verbals["wizard.bookingDeclined"] ||
                "Your Booking is not Confirmed"}
            </div>
            <div className="reason">
              {window.verbals["wizard.errorReason"] ||
                "The reason we couldn't process your booking was:"}
            </div>
            <div className="failError">{statusMessage}</div>
            <button
              className="failButton"
              onClick={() => {
                setFail(false);
                setStep(1);
              }}
            >
              {window.verbals["wizard.tryAgain"] || "Try again"}
            </button>
          </div>
        )
      ) : (
        <div className="error">
          {window.verbals["wizard.serverError"] ||
            "Internal Server Error, please try reloading the webpage and try again."}
        </div>
      )}
    </div>
  );
}

export default App;
