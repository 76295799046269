import {
  day,
  excursion,
  language,
  location,
  minimumFetchDuration,
  place,
  prices,
  service,
} from "../App";

type boolState = (arg0: boolean) => void;

type langState = (arg0: language[]) => void;

type dayState = (arg0: day[]) => void;

type serviceState = (arg0: service[]) => void;
type locationState = (arg0: location[]) => void;
type excursionsState = (arg0: excursion[]) => void;

type placeState = (arg0: place[]) => void;

type pricesState = (arg0: prices) => void;

export async function getExcursion(
  setIsBusyMain: boolState,
  setPrices: pricesState,
  setErrored: boolState,
  host: string
) {
  try {
    setIsBusyMain(true);

    let response = await fetch(host + "/rest/api/getExcursion");

    if (response.ok) {
      let json = await response.json();
      const prices = {
        Adults: json.pricePerAdult,
        Children: json.pricePerChild,
        Infants: json.pricePerInfant,
      };
      setPrices(prices);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    console.log(err);
    setIsBusyMain(false);
    setErrored(true);
  }
}

export async function getExcursions(
  setIsBusyMain: boolState,
  setExcursions: excursionsState,
  setErrored: boolState,
  host: string
) {
  try {
    setIsBusyMain(true);

    let response = await fetch(host + "/rest/api/getExcursions");

    if (response.ok) {
      let json = await response.json();
      setExcursions(json);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    console.log(err);
    setIsBusyMain(false);
    setErrored(true);
  }
}

export async function getLanguages(
  setIsBusyMain: boolState,
  setLanguages: langState,
  setErrored: boolState,
  excursionParam: string,
  host: string
) {
  try {
    setIsBusyMain(true);
    let response = await fetch(
      host + "/rest/api/getLanguages?ex=" + excursionParam
    );

    if (response.ok) {
      let json = await response.json();
      setLanguages(json);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    console.log(err);
    setIsBusyMain(false);
    setErrored(true);
  }
}

export async function getDates(
  setIsBusyMain: boolState,
  setDates: dayState,
  setErrored: boolState,
  langParam: string,
  exParam: string,
  host: string
) {
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  try {
    setIsBusyMain(true);
    let response = await fetch(
      host +
        "/rest/api/getDates?lang=" +
        langParam +
        "&month=" +
        (month + 1) +
        "&year=" +
        year +
        "&ex=" +
        exParam
    );

    if (response.ok) {
      let json = await response.json();
      setDates(json);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    console.log(err);
    setIsBusyMain(false);
    setErrored(true);
  }
}

export async function getServices(
  setIsBusyMain: boolState,
  setServices: serviceState,
  setServicesDone: boolState,
  setErrored: boolState,
  host: string
) {
  try {
    setIsBusyMain(true);
    let response = await fetch(host + "/rest/api/getReservationExtras");

    if (response.ok) {
      let json = await response.json();
      setServices(json);
      setServicesDone(true);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    console.log(err);
    setIsBusyMain(false);
    setErrored(true);
  }
}

export async function getLocations(
  setIsBusyMain: boolState,
  setLocations: locationState,
  setErrored: boolState,
  excursionParam: string,
  host: string
) {
  try {
    setIsBusyMain(true);
    let response = await fetch(
      host + "/rest/api/getLocations?ex=" + excursionParam
    );

    if (response.ok) {
      let json = await response.json();
      setLocations(json);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    console.log(err);
    setErrored(true);
    setIsBusyMain(false);
  }
}

export async function getPlaces(
  setIsBusyMain: boolState,
  setPlaces: placeState,
  setErrored: boolState,
  locationParam: string,
  host: string
) {
  try {
    setIsBusyMain(true);
    let response = await fetch(
      host + "/rest/api/getPickUpPoints?location=" + locationParam
    );

    if (response.ok) {
      let json = await response.json();
      setPlaces(json);
    } else {
      setErrored(true);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    setErrored(true);
    setIsBusyMain(false);
  }
}

/*async function authenticate(host: string) {
  try {
    let response = await fetch(
      host + "/oauth/token?grant_type=password&username=admin&password=admin",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${window.btoa("client:secret")}`,
        },
        method: "POST",
      }
    );

    if (response.ok) {
      let json = await response.json();
      return json.access_token;
    } else {
      console.log("error in response");
      console.log(response);
      return undefined;
    }
  } catch (err) {
    console.log("error in network");
    console.log(err);
    return undefined;
  }
}

export async function postReservation(
  setIsBusyMain: boolState,
  data: any,
  setErrored: boolState,
  host: string,
  proceed: (arg: number) => void
) {
  try {
    setIsBusyMain(true);
    const token = await authenticate(host);
    if (token) {
      let response = await fetch(host + "/rest/api/postReservation", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        console.log("ok");
        proceed(3);
      } else {
        console.log("Error in server response");
        console.log(response);
        setErrored(true);
        setIsBusyMain(false);
      }
      setTimeout(() => {
        setIsBusyMain(false);
      }, minimumFetchDuration);
    } else {
      setErrored(true);
      setIsBusyMain(false);

      console.log("Access token was undefined");
    }
  } catch (err) {
    setErrored(true);
    setIsBusyMain(false);
    console.log(err);
  }
}*/
export async function postReservation(
  setIsBusyMain: boolState,
  data: any,
  setErrored: boolState,
  host: string,
  proceed: (arg: number) => void
) {
  try {
    setIsBusyMain(true);

    let response = await fetch(host, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      proceed(3);
      const data = await response.text();
      setTimeout(function () {
        document.open();
        document.write(data);
        document.close();
      }, 1000);
    } else {
      console.log("Error in server response");
      console.log(response);
      setErrored(true);
      setIsBusyMain(false);
    }
    setTimeout(() => {
      setIsBusyMain(false);
    }, minimumFetchDuration);
  } catch (err) {
    setErrored(true);
    setIsBusyMain(false);
    console.log(err);
  }
}
