import { useEffect, useRef, useState } from "react";
import { ChevronDown, X } from "react-bootstrap-icons";
import { extra } from "../App";
import styles from "../DropDown/DropDown.module.scss";
import { ExtrasSVG } from "../svgs";

interface IExtrasPicker {
  options: extra[];
  selectedExtras: string[];
  setSelectedExtras: (arg0: string[]) => void;
  variation?: "top" | "bottom" | "normal";
}

export default function ExtrasPicker({
  options,
  variation = "normal",
  selectedExtras,
  setSelectedExtras,
}: IExtrasPicker) {
  const [isOpen, setIsOpen] = useState(false);

  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  function selectExtra(id: string) {
    let tempExtras: string[] = [];
    selectedExtras.forEach((ex) => tempExtras.push(ex));
    if (tempExtras.includes(id)) {
      tempExtras.splice(tempExtras.indexOf(id), 1);
    } else {
      tempExtras.push(id);
    }
    setSelectedExtras(tempExtras);
  }

  return (
    <div
      className={`${styles.main}  ${
        variation === "top" ? styles.top : ""
      } dropdownHelper`}
      ref={myRef}
    >
      <button
        className={`${styles.selectHeader}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.headerButton}>
          {<ExtrasSVG />}
          <span>{window.verbals["wizard.extras"] || "Extras"}</span>
        </div>
        <ChevronDown
          color={variation === "normal" ? "#136FA3" : "363738"}
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>

      {isOpen && (
        <div
          className={`${styles.optionsWrapper} ${
            isOpen ? styles.open : ""
          } right`}
        >
          <div className={styles.headerClose}>
            <button onClick={() => setIsOpen(false)}>
              <X size={36} color="#000" className="ctrsSvg" />
            </button>
          </div>

          {options.map((option) => (
            <div className={`${styles.option} extraDiv`} key={option.id}>
              <label className="extrasLabel">
                <input
                  checked={selectedExtras.includes(option.id)}
                  type="checkbox"
                  onChange={() => selectExtra(option.id)}
                ></input>
                {option.title_en}<br/>
                (<i>{window.verbals["wizard.costPerPerson"] || "cost per adult/child"}</i>)
              </label>
              <span>{option.price}€</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
