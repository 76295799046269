import { useEffect, useRef, useState } from "react";
import { ChevronDown, DashCircle, PlusCircle } from "react-bootstrap-icons";
import { PersonSVG } from "../svgs";
import styles from "./PersonPicker.module.scss";

interface IPersonPicker {
  title: string;
  cost: number;
  maleAmount: number;
  femaleAmount: number;
  disabled?: boolean;

  updateFunction: (maleAmoun: number, femaleAmount: number) => void;
}

const svgProps = { size: 24, color: "#136FA3" };

export default function PersonPicker({
  title,
  cost,
  maleAmount,
  femaleAmount,
  disabled,
  updateFunction,
}: IPersonPicker) {
  const myRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [maleState, setMaleState] = useState(maleAmount);
  const [femaleState, setFemaleState] = useState(femaleAmount);

  function confirm() {
    updateFunction(maleState, femaleState);
    setIsOpen(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      setMaleState(maleAmount);
      setFemaleState(femaleAmount);
    }
  }, [femaleAmount, isOpen, maleAmount]);

  return (
    <div className={styles.main} ref={myRef}>
      <button
        className={styles.selectHeader}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled || false}
      >
        <div className={styles.headerButton}>
          <PersonSVG fill="#fff" width="20" />
          <span>{title}</span>
        </div>
        <ChevronDown
          color="#fff"
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>

      <div className={`${styles.optionsWrapper} ${isOpen ? styles.open : ""} `}>
        <div className={styles.personHeader}>
          {window.verbals["wizard.costPer"] || "Cost per"} {title}:&nbsp;{cost}{" "}
          €
        </div>
        <div className={styles.personSelector}>
          <span>{window.verbals["wizard.male"] || "Male"}</span>
          <div className={styles.amountWrapper}>
            <button
              onClick={() => setMaleState(maleState > 1 ? maleState - 1 : 0)}
            >
              <DashCircle {...svgProps} className="ctrsSvg" />
            </button>
            <span className={styles.amount}>{maleState}</span>
            <button onClick={() => setMaleState(maleState + 1)}>
              <PlusCircle {...svgProps} className="ctrsSvg" />
            </button>
          </div>
        </div>
        <div className={styles.personSelector}>
          <span>{window.verbals["wizard.female"] || "Female"}</span>
          <div className={styles.amountWrapper}>
            <button
              onClick={() =>
                setFemaleState(femaleState > 1 ? femaleState - 1 : 0)
              }
            >
              <DashCircle {...svgProps} className="ctrsSvg" />
            </button>
            <span className={styles.amount}>{femaleState}</span>
            <button onClick={() => setFemaleState(femaleState + 1)}>
              <PlusCircle {...svgProps} className="ctrsSvg" />
            </button>
          </div>
        </div>
        <div className={styles.footer}>
          <button
            disabled={femaleState === femaleAmount && maleAmount === maleState}
            onClick={() => confirm()}
          >
            {window.verbals["wizard.confirm"] || "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
}
