import { useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { countries, countriesExtended } from "../utils/countries";
import styles from "./SearchPicker.module.scss";

interface INationalityPicker {
  update: (value: string) => void;
  initValue: string;
  variation?: "nation" | "phoneCode" | "country" | "state";
  submitAttempted: boolean;
  initCountry?: string;
}

const svgProps = { size: 16, color: "#136FA3" };
type simpleCountries = { name: string; dial_code: string; code: string }[];

type complexCountries = {
  name: string;
  alpha2: string;
  alpha3: string;
  numeric: string;
  subdivisions: subdivisions;
}[];

type subdivisions = { code: string; name: string }[];

export default function SearchPicker({
  update,
  initValue,
  variation = "nation",
  submitAttempted,
  initCountry,
}: INationalityPicker) {
  const myRef = useRef<HTMLDivElement>(null);
  const myRef2 = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState("");

  const value =
    initValue === ""
      ? variation === "nation"
        ? `${window.verbals["wizard.nationality"] || "Nationality"} *`
        : variation === "country"
        ? `${window.verbals["wizard.country"] || "Country"} *`
        : variation === "state"
        ? `${window.verbals["wizard.state"] || "State"} *`
        : "+XX"
      : variation === "nation" || variation === "phoneCode"
      ? initValue
      : variation === "country"
      ? countriesExtended.find((e) => e.alpha2 === initValue)?.name
      : countriesExtended
          .find((e) => e.alpha2 === initCountry)
          ?.subdivisions.find((e) => e.code === initValue)?.name;

  const nations =
    variation === "nation" || variation === "phoneCode"
      ? countries.filter((e) =>
          e.name.toUpperCase().startsWith(filter.toUpperCase())
        )
      : variation === "country"
      ? countriesExtended.filter((e) =>
          e.name.toUpperCase().startsWith(filter.toUpperCase())
        )
      : countriesExtended
          .find((e) => e.alpha2 === initCountry)
          ?.subdivisions.filter((e) =>
            e.name.toUpperCase().startsWith(filter.toUpperCase())
          );

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (
        e.target !== myRef.current &&
        !myRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    setFilter("");
    if (myRef2.current) {
      myRef2.current.scrollTop = 0;
    }
  }, [isOpen]);

  function isDisabled() {
    if (variation !== "state") {
      return false;
    } else {
      if (!initCountry) {
        return true;
      } else {
        if (
          countriesExtended.find((e) => e.alpha2 === initCountry)?.subdivisions
            .length === 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function invalidState() {
    if (variation !== "state") {
      return submitAttempted && initValue === "";
    } else {
      if (!initCountry) {
        return submitAttempted && initValue === "";
      } else {
        if (
          countriesExtended.find((e) => e.alpha2 === initCountry)?.subdivisions
            .length === 0
        ) {
          return false;
        } else {
          return submitAttempted && initValue === "";
        }
      }
    }
  }

  return (
    <div
      className={`${styles.main} ${
        variation === "phoneCode" ? styles.small : ""
      } holderDropdown`}
      ref={myRef}
    >
      <button
        disabled={isDisabled()}
        className={`${styles.selectHeader} ${
          invalidState() ? styles.errored : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
        data-invalid={invalidState()}
      >
        <div className={styles.headerButton}>{value}</div>
        <ChevronDown
          {...svgProps}
          className={`${styles.chevron} ${
            isOpen ? styles.chevronDown : ""
          } ctrsSvg`}
        />
      </button>

      {isOpen && (
        <div
          ref={myRef2}
          className={`${styles.optionsWrapper} ${isOpen ? styles.open : ""} `}
        >
          <div className={styles.searchWrapper}>
            <input
              type="text"
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Search"
              value={filter}
            />
          </div>
          {(variation === "nation" || variation === "phoneCode") &&
            (nations as simpleCountries).map((value, index) => (
              <button
                key={`Nationality${index}`}
                className={styles.option}
                onClick={() => {
                  update(variation === "nation" ? value.name : value.dial_code);
                  setIsOpen(false);
                }}
              >
                {variation === "nation"
                  ? value.name
                  : `${value.name} (${value.dial_code})`}
              </button>
            ))}
          {variation === "country" &&
            (nations as complexCountries).map((value, index) => (
              <button
                key={`Country${index}`}
                className={styles.option}
                onClick={() => {
                  update(value.alpha2);
                  setIsOpen(false);
                }}
              >
                {value.name}
              </button>
            ))}
          {variation === "state" &&
            (nations as subdivisions).map((value, index) => (
              <button
                key={`Subdivision${index}`}
                className={styles.option}
                onClick={() => {
                  update(value.code);
                  setIsOpen(false);
                }}
              >
                {value.name}
              </button>
            ))}
        </div>
      )}
      {invalidState() && (
        <div className={styles.error}>This field is required</div>
      )}
    </div>
  );
}
